/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jw-watch-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./jw-watch-video.component";
import * as i4 from "../../core/services/api.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../core/services/player.service";
import * as i8 from "../../core/services/header.service";
var styles_JwWatchVideoComponent = [i0.styles];
var RenderType_JwWatchVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JwWatchVideoComponent, data: {} });
export { RenderType_JwWatchVideoComponent as RenderType_JwWatchVideoComponent };
function View_JwWatchVideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.video == null) ? null : _co.video.video_category_name); _ck(_v, 1, 0, currVal_0); }); }
export function View_JwWatchVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "embed-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "jw-player-id"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "video-cs-blk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "back_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, ":svg:svg", [[":xml:space", "preserve"], ["height", "33px"], ["id", "Layer_1"], ["version", "1.1"], ["viewBox", "0 0 512 512"], ["width", "33px"], ["x", "0px"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:path", [["d", "M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "], ["fill", "#FFFFFF"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "movie-title title-grd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JwWatchVideoComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.video && _co.video.video_category_name); _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.video == null) ? null : _co.video.title); _ck(_v, 10, 0, currVal_0); }); }
export function View_JwWatchVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jw-watch-video", [], null, null, null, View_JwWatchVideoComponent_0, RenderType_JwWatchVideoComponent)), i1.ɵdid(1, 245760, null, 0, i3.JwWatchVideoComponent, [i4.ApiService, i5.ActivatedRoute, i5.Router, i1.Renderer2, i6.Title, i7.PlayerService, i8.HeaderService, i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JwWatchVideoComponentNgFactory = i1.ɵccf("app-jw-watch-video", i3.JwWatchVideoComponent, View_JwWatchVideoComponent_Host_0, {}, {}, []);
export { JwWatchVideoComponentNgFactory as JwWatchVideoComponentNgFactory };
