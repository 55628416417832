/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trailer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./trailer.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../core/services/api.service";
import * as i5 from "ng-connection-service";
var styles_TrailerComponent = [i0.styles];
var RenderType_TrailerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrailerComponent, data: {} });
export { RenderType_TrailerComponent as RenderType_TrailerComponent };
export function View_TrailerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "video", [["id", "videos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "video-cs-blk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "back_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.PageBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, ":svg:svg", [[":xml:space", "preserve"], ["height", "33px"], ["id", "Layer_1"], ["version", "1.1"], ["viewBox", "0 0 512 512"], ["width", "33px"], ["x", "0px"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "], ["fill", "#FFFFFF"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "movie-title title-grd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "online-toast"], ["id", "online-toast"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are not connected to the internet"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.trailerVideo == null) ? null : _co.trailerVideo.title); _ck(_v, 9, 0, currVal_0); var currVal_1 = ((_co.status == "OFFLINE") ? "block" : "none"); _ck(_v, 11, 0, currVal_1); }); }
export function View_TrailerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trailer", [], null, null, null, View_TrailerComponent_0, RenderType_TrailerComponent)), i1.ɵdid(1, 245760, null, 0, i2.TrailerComponent, [i3.Router, i3.ActivatedRoute, i4.ApiService, i5.ConnectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrailerComponentNgFactory = i1.ɵccf("app-trailer", i2.TrailerComponent, View_TrailerComponent_Host_0, {}, {}, []);
export { TrailerComponentNgFactory as TrailerComponentNgFactory };
