/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contus-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contus-slider.component";
import * as i4 from "../../core/services/video-share.service";
var styles_ContusSliderComponent = [i0.styles];
var RenderType_ContusSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContusSliderComponent, data: {} });
export { RenderType_ContusSliderComponent as RenderType_ContusSliderComponent };
function View_ContusSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "owl-prev"], ["role", "presentation"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-label", "Previous"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2039"]))], null, null); }
function View_ContusSliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "owl-next"], ["role", "presentation"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-label", "Next"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u203A"]))], null, null); }
export function View_ContusSliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "contus-slider-outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "contus-slider"]], [[8, "id", 0]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "owl-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContusSliderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContusSliderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.previousBtn; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.nextBtn; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 1, 0, currVal_0); }); }
export function View_ContusSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contus-slider", [], null, [[null, "touchmove"]], function (_v, en, $event) { var ad = true; if (("touchmove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).touch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ContusSliderComponent_0, RenderType_ContusSliderComponent)), i1.ɵdid(1, 12697600, null, 0, i3.ContusSliderComponent, [i4.VideoShareService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContusSliderComponentNgFactory = i1.ɵccf("app-contus-slider", i3.ContusSliderComponent, View_ContusSliderComponent_Host_0, { options: "options", id: "id", total: "total", length: "length", url: "url", isContinueWatching: "isContinueWatching", currentMovedItems: "currentMovedItems" }, { nextPageCall: "nextPageCall", previousPages: "previousPages" }, ["*"]);
export { ContusSliderComponentNgFactory as ContusSliderComponentNgFactory };
