import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import { utils } from 'protractor';

export const apiNotifier = new Subject();

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  error: any = {};
  authorization: any;
  apiHeaders: any;
  categories: any;
  deviceId: string;
  operatingSystem: string;
  browserName: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthService,
    private userService: UserService
  ) {

    this.authService
      .getUniqueBrowserValue()
      .then((data: { deviceId: string; platform: string }) => {
        this.deviceId = data.deviceId;
        this.operatingSystem = data.platform;
      });

    this.browserName = this.authService.getBrowserName();
  }

  headers() {
    this.authorization = this.authService.decryption();
    this.apiHeaders = new HttpHeaders();
    this.apiHeaders.headers['Accept'] = 'application/json';
    this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';

    this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
    this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
      ? this.operatingSystem
      : '';
    this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';

    if (localStorage.getItem('currentLanguage')) {
      this.apiHeaders.headers['X-LANGUAGE-CODE'] = localStorage.getItem(
        'currentLanguage'
      );
    } else {
      this.apiHeaders.headers['X-LANGUAGE-CODE'] = 'en';
    }
    if (this.authorization.hasOwnProperty('response')) {
      this.apiHeaders.headers['Authorization'] =
        'Bearer ' + this.authorization.response.access_token;
    }
    return this.apiHeaders;
  }

  callGetAPI(url: string, baseServiceUrl?: string) {
    const serviceUrl = baseServiceUrl
      ? baseServiceUrl + url
      : environment.apiUrl + url;
    return this.http.get<any>(serviceUrl, this.headers()).pipe(
      map((data) => {
        return data;
      })
    );
  }
  callExternalAPI(url: string, baseServiceUrl?: string) {
    const serviceUrl = url;
    return this.http.get<any>(serviceUrl).pipe(
      map((data) => {
        return data;
      })
    );
  }
  callPostAPI(url: string, parameters: any, baseServiceUrl?: string) {
    const serviceUrl = baseServiceUrl
      ? baseServiceUrl + url
      : environment.apiUrl + url;
    return this.http.post<any>(serviceUrl, parameters, this.headers()).pipe(
      map((data) => {
        return data;
      })
    );
  }
  callPutAPI(url: string, parameters: any, baseServiceUrl?: string) {
    const serviceUrl = baseServiceUrl
      ? baseServiceUrl + url
      : environment.apiUrl + url;
    return this.http.put<any>(serviceUrl, parameters, this.headers()).pipe(
      map((data) => {
        return data;
      })
    );
  }
  callDeleteAPI(url: string, baseServiceUrl?: string) {
    const serviceUrl = baseServiceUrl
      ? baseServiceUrl + url
      : environment.apiUrl + url;
    return this.http.delete<any>(serviceUrl, this.headers()).pipe(
      map((data) => {
        return data;
      })
    );
  }

  callGetJsonFile(url: string) {
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
  successResponse(data, url = null) {
    if (data.statusCode === 200 && data.error === false) {
      this.toasterService.success('', data.message);
      if (url) {
        this.router.navigate([url.redirect]);
      }
    }
  }

  failureResponse(data, url = null) {
    this.error = {};
    if (data.error.statusCode === 422 || data.error.statusCode === 500) {
      if (
        typeof data.error.message === 'string' ||
        data.error.message instanceof String
      ) {
        this.error.commonError = data.error.message;
      } else {
        this.error = data.error.message;
      }
    } else if (data.error.statusCode === 401) {
      this.authService.logout(false);
      this.userService.displayPopup('sign-in');
    } else if (data.error.statusCode === 403) {
      this.authService.logout(false);
      if (data.error.message && data.error.message !== '') {
        this.toasterService.error('', data.error.message);
      }
    } else if (data.error.statusCode === 404) {
      this.router.navigate(['404']);
      return false;
    } else {
      if (data.error.message && data.error.message !== '') {
        this.toasterService.error('', data.error.message);
      }
    }
    if (url) {
      console.log(url)
      // window.history.back();
    }
    return this.error;
  }
}
