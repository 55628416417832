import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
    // let pathDecode = url;
    // pathDecode = (((url.replace('%3F',"?")).replace("%2F", '/')).replace("%3D","=")).replace("?", "/?");

    // return dus.parse(pathDecode);
    // return dus.parse((decodeURIComponent(url)).replace("25", ''));
    // return url
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    const path = dus.serialize(tree);
    return path.replace(/\?fbclid=(.*)/g, '');
    // let pathDecode = path.replace(/\?fbclid=(.*)/g, '');
    // if(path.includes("/home/")){
    //   pathDecode = ((pathDecode.replace('%3F',"?")).replace("%2F", '/')).replace("%3D","=");
    // }
    // // return path.replace(/\?fbclid=(.*)/g, '');
    // // console.log(pathDecode)
    // return pathDecode
  }
}
