import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-resent-link',
  templateUrl: './resent-link.component.html',
  styleUrls: ['./resent-link.component.scss']
})
export class ResentLinkComponent implements OnInit {
  buttonText;
  user: any = {};
  submitted = false;
  emailMinLength: any = 3;
  emailMaxLength: any = 254;
  LoggedIn = localStorage.getItem('currentUser');
  error: any = {};
  constructor(private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private translate: TranslateService) {

    // this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
    //   this.buttonText = res;
    // });
    this.buttonText = 'Submit';

  }

  ngOnInit() {
  }
  onClose() {
    if (this.route.snapshot['_routerState'].url === '/forget-password') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

  forgotPassword() {
    this.submitted = true;
    this.translate.get('FORGET_PASSWORD.SENDING').subscribe(res => {
      this.buttonText = res;
    });
    // this.url.redirect = 'sign-in';
    this.apiService.callPostAPI('v2/auth/resent-link', { email: this.user.email }, environment.userApiUrl)
      .subscribe(
        data => {
          this.submitted = false;
          this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
            this.buttonText = res;
          });
          this.userService.displayPopup('sign-in');
          if (data['message'] === 'We have sent an activation link to your registered email. Please open it up to activate your account' || data['message'] === 'Kami telah mengirimkan link aktivasi ke alamat email Kamu. Mohon periksa email Kamu') {

            this.onClicks('active');
            return;
          }
          this.apiService.successResponse(data);

        },
        error => {

          this.submitted = false;
          this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
            this.buttonText = res;
          });
          this.error = this.apiService.failureResponse(error);
        });
  }
  onChange() {
    this.error = {};
  }

  onClick() {
    this.userService.displayPopup('sign-in');
  }
  onClicks(value) {
   if (value === 'active') {
      this.userService.displayPopup('active');
    }
  }


}
