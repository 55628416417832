<section class="home-banner">
  <owl-carousel
    [options]="{nav : true, loop: true, autoplay: true, autoplayTimeout:7000, stopOnHover: true, dots: true, responsive: { '0': { items: 1}, '600': { items: 1,nav : false}, '1000': { items: 1} } }"
    [items]="bannerData" [carouselClasses]="['owl-carousel', 'banner-slider']">
    <div class="item" *ngFor="let banner of bannerData" (click)="onBannerClick(banner)">
      <div class="banner-item" lazy-load-images [ngStyle]="{'background-image': !isMobile ? 'url('+ banner.banner_url +')' : 'url('+ banner.app_banner_url +')'}">
        <a class="play-icon">
        <div class="banner-content">
          <div class="container">
            <div class="banner-tittle">
              <h2>{{ (banner.video_title.length > 100)? (banner.video_title | slice:0:100) + '..' : banner.video_title }}</h2>
              <p> {{banner.program_title}}<i *ngIf="banner.video_category_name && banner.genre_name">,</i>
                {{banner.video_category_name}}
                <span class="live-now-tag" *ngIf="banner.is_live === 1"> {{ 'BANNER.LIVE_NOW' | translate }}</span>
                <span class="premium-tag" *ngIf="banner.is_premium === 1">{{ 'BANNER.PREMIUM' | translate }}</span> </p>
            </div>
            <div class="ctaBlock" [ngClass]="{'no-play-button': banner.is_external_link == 1 }">
              <a class="play-icon">
                <svg *ngIf="banner.is_external_link == 0" height="15" viewBox="0 0 13 15" width="13" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <style> .a { fill: #fff; } </style>
                  </defs>
                  <path class="a" d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z" transform="translate(13) rotate(90)">                    
                  </path>
                </svg>
                
                <span *ngIf="banner.is_external_link == 0">{{ 'VIDEO_DETAIL.PLAY' | translate }}</span>
                <span *ngIf="banner.is_external_link == 1">{{ 'VIDEO_DETAIL.HERO_BANNER_LINK' | translate }}</span>
              </a>
            </div>
          </div>

        </div>
        </a>
      </div>
    </div>
  </owl-carousel>
</section>