<div class="loader-ring" *ngIf="!videos">
  <div class="loader-ring-light"></div>
  <div class="loader-ring-track"></div>
</div>

<!-- <app-player *ngIf="videos && isMainPlayer" class="video-player-center title-grd"
  (livchatStatus)="toggleLiveChat($event)" [ngClass]="{ 'player-live-chat': isMobile || liveChatStatus }">
</app-player> -->

<app-player *ngIf="videos && isYoutube" class="video-player-center title-grd"
  (livchatStatus)="toggleLiveChat($event)" [ngClass]="{ 'player-live-chat': isMobile || liveChatStatus }">
</app-player>

<app-dailymotion-player *ngIf="videos && !isYoutube"></app-dailymotion-player>

<app-trailer *ngIf="videos && !isMainPlayer"></app-trailer>

<div class="video-cs-blk" *ngIf="videoTitle">
  <div class="back_btn" (click)="PageBack()" style="z-index: 10">
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" width="33px"
      height="33px">
      <g>
        <g>
          <path
            d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "
            fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  </div>
  <div class="movie-title title-grd" style="z-index: 10">
    <h2>{{ videoTitle }}</h2>
  </div>

  <div class="revoke-popup-overlay">
    <div class="revoke-popup">
      <h2 class="revoke-title">
        {{ 'VIDEO_DETAIL.STREAMING_ERROR' | translate }}
        <svg (click)="PageBack()" width="512" height="512" x="0" y="0" viewBox="0 0 357 357"
          style="enable-background: new 0 0 512 512">
          <g>
            <g xmlns="http://www.w3.org/2000/svg">
              <g id="close">
                <polygon
                  points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3     214.2,178.5   "
                  fill="#3b3a36" data-original="#000000" />
              </g>
            </g>
          </g>
        </svg>
      </h2>
      <div class="revoke-content">
        <p>
          {{ 'VIDEO_DETAIL.STREAMING_ERROR_DESC' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>