<div class="slider-item" [ngClass]="{
    'slider-item-selector': !slidItemClass,
    zindex: addZindex,
    cardOverlay: currentlyPlaying
  }" [@videoCardAnimation] (click)="videoCardFullClick(video)" [class.mouse-hovered]="mouseOvered"
  (mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false">

  <div class="items-wrapper">

    <div class="slider-img" [attr.vidoe-slug]="video.slug"
      [ngStyle]="{ 'background-image': 'url(' + video.poster_image + ')' }" lazy-load-images
      (click)="videoClick(video, undefined)">
      <img alt="video.title" src='assets/images/blank_new.png' [attr.data-src]="video.poster_image"
        onerror="this.src='assets/images/blank_new.png';" />
      <span *ngIf="this.type !== 'webseries'">
        <span class="play-video" *ngIf="this.type !== 'upcoming'"></span>
      </span>
      <span class="premium-tag" *ngIf="video?.is_premium === 1">
        {{ 'VIDEO_CARD.PREMIUM' | translate }}</span>
      <span *ngIf="type === 'favorite'" class="like-box" (click)="favourite(video, $event)"><i
          [ngClass]="'liked'"></i></span>
    </div>
    <div class="slider-content">
      <ng-container *ngIf="type !== 'web-series' && type !== 'live'; else webSeries">
        <h3 *ngIf="!video.season_name" [ngClass]="{ center: type === 'webseries' }" (click)="videoClick(video, undefined)">
          {{ video?.video_category_name }}
        </h3>
        
        <!-- <h3 *ngIf="video.season_name && video.program_detail"[ngClass]="{ center: type === 'webseries' }" (click)="videoClick(video, undefined)">
          {{ video?.program_detail?.title }}  
        </h3> -->
        <h3 *ngIf="video.season_name"[ngClass]="{ center: type === 'webseries' }" (click)="videoClick(video, undefined)">
          {{ video?.video_category_name }}
        </h3>
        <span class="category-text" (click)="videoClick(video, undefined)"
          *ngIf="type !== 'search' && type !== 'upcoming' && type !== 'webseries'">
          <!-- <ng-container *ngIf="category !== 'genre' && type !== 'upcoming' && type !== 'live'">
            {{ video?.genre_name
          }}<i *ngIf="
              video?.genre_name &&
              category !== 'genre' &&
              category !== 'category' &&
              video?.video_category_name
            ">,</i>
          </ng-container> -->

         
          <ng-container *ngIf="video?.video_category_name && category !== 'category' && video.season_name">
           {{ (video.season_name) ? "S" + (video.season_name.substring(video.season_name.indexOf(' ') + 1)) : "" }}{{ (video.episode_order) ? ":E" + video.episode_order + " - " : "" }} {{ video?.title }} 
          </ng-container>

          <ng-container *ngIf="video?.video_category_name && category !== 'category' && !video.season_name">
            <!-- {{ video?.video_category_name }}  -->
           </ng-container>
        </span>
        <span class="category-text" *ngIf="type === 'search'" (click)="videoClick(video, undefined)">
          {{ video?.genre_name }}<i *ngIf="video?.genre_name">,</i>{{ video?.title }}
        </span>
      </ng-container>
      <ng-template #webSeries>
        <h3 (click)="videoClick(video, undefined)">
          <ng-container class="category-text" (click)="videoClick(video, undefined)"
            *ngIf="!live && type !== 'search' && category !== 'category'">
            <ng-container *ngIf="video?.title">
              {{ video?.title }}
            </ng-container>
          </ng-container>
        </h3>
      </ng-template>
      <span class="time" *ngIf="type === 'upcoming'" (click)="videoClick(video, undefined)">
        {{ video?.scheduledStartTime | date: 'MMM dd yyyy' }}
        {{ video?.scheduledStartTime | date: 'hh:mm'
      }}{{ video?.scheduledStartTime | date: 'a' | lowercase }}
      </span>
      <div class="video-options" id="video{{ video.slug }}"
        *ngIf="type !== 'live' && type !== 'upcoming' && type !== 'webseries'" (click)="showOptions($event)">
        <svg xmlns="http://www.w3.org/2000/svg" id="video1{{ video.slug }}" xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 384 384" style="enable-background: new 0 0 384 384"
          xml:space="preserve" width="20px" height="20px">
          <g>
            <g>
              <circle cx="192" cy="42.667" r="46.667" fill="#696969" />
            </g>
          </g>
          <g>
            <g>
              <circle cx="192" cy="192" r="46.667" fill="#696969" />
            </g>
          </g>
          <g>
            <g>
              <circle cx="192" cy="341.333" r="46.667" fill="#696969" />
            </g>
          </g>
        </svg>
        <div class="video-option-list" [ngClass]="{ show: showOption && this.type !== 'webseries' }">
          <ul>
            <li *ngIf="!history" (click)="openShareVideo()">
              <a><i class="share-icon"></i>{{ 'VIDEO_CARD.SHARE' | translate }}</a>
            </li>
            <!-- <li *ngIf="!history && !playlist_id && !video.is_live" (click)="openPlalistPopup()">
              <a><i class="playlist-icon"></i>{{ 'VIDEO_CARD.ADD_TO_PLAYLIST' | translate }}</a>
            </li> -->
            <!-- <li *ngIf="!history && playlist_id" (click)="removeFromPlaylist(video, $event)">
              <a><i class="playlist-icon"></i>{{ 'VIDEO_CARD.REMOVE_FROM_PLAYLIST' | translate }}</a>
            </li> -->
            <li *ngIf="history" (click)="removeHistory(video, $event)">
              <a>{{ 'VIDEO_CARD.REMOVE_FROM_HISTORY' | translate }}</a>
            </li>
          </ul>
          <div class="overlay"></div>
        </div>
      </div>
      <span class="live-icon" *ngIf="type === 'live'">
        <svg xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
          xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="30px"
          height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">
          <path fill="#0dcea6"
            d="M26.387,23.657l-1.261-1.263c4.122-4.123,4.121-10.83-0.002-14.953l1.263-1.261  C31.205,10.998,31.205,18.84,26.387,23.657z M22.942,20.212l1.261,1.266c3.614-3.617,3.614-9.5,0-13.115l-1.261,1.261  C25.86,12.542,25.86,17.295,22.942,20.212z M21.952,19.224c2.374-2.373,2.374-6.238-0.001-8.611l-1.261,1.262  c1.68,1.68,1.68,4.411,0,6.086L21.952,19.224z M5.353,7.441L4.09,6.18c-4.818,4.817-4.818,12.66,0,17.477l1.261-1.263  C1.229,18.273,1.23,11.565,5.353,7.441z M6.271,21.478l1.262-1.266c-2.918-2.917-2.918-7.667,0-10.588L6.271,8.361  C2.656,11.976,2.656,17.86,6.271,21.478z M8.522,19.224l1.263-1.263c-1.678-1.679-1.678-4.409,0.001-6.086l-1.263-1.262  C6.149,12.989,6.149,16.852,8.522,19.224z">
          </path>
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#0dcea6"
            d="M15.366,12.178c1.646,0,2.98,1.335,2.98,2.981  s-1.334,2.98-2.98,2.98s-2.981-1.334-2.981-2.98S13.72,12.178,15.366,12.178z">
          </path>
        </svg>
      </span>
    </div>
  </div>
</div>