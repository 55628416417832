
// import * as DM from '../../interfaces/dailymotion';

/// <reference path="./interfaces/dailymotion.ts" />

import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, AfterViewInit, Inject, Renderer2, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DailymotionApiService } from '../../../core/services/dailymotion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { HeaderService } from 'src/app/core/services/header.service';
import { MobileExclusiveService } from '../../../core/services/mobile-exclusive.service';

declare var $: any;
@Component({
  selector: 'app-dailymotion-player',
  templateUrl: './dailymotion-player.component.html',
  styleUrls: ['./dailymotion-player.component.scss']
})
export class DailymotionPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  dailymotionError: Boolean = false;
  player: DM.Player = null;
  private isPlayerReady = new BehaviorSubject<boolean>(false);
  DEVICE_RESTRICTION_TYPE: string;
  params: any;
  video: any;
  showXrayList = false;
  showXrayViewAll = false;
  showXrayCastDetail = false;
  showXrayScenestab = false;
  xrayCastList = [];
  xrayCastViewAllList = [];
  xrayScenesList = [];
  xrayCastDetail: any;
  showXray = false;
  showEpisode = false;
  mouseOverXray = false;
  mouseOverEpisode = false;
  livestreamStarted = false;
  geoStatus = true;
  videoProgressPercentage: number;
  viewCountStatus: string;
  videoPaymentInfo: any;
  next_video: any = false;
  sprite_image: any;
  heartBeatTimerId: any = null;
  // intervalId: any;
  playControlBarSetInterval: any;
  isWatchFrombeginningSet: boolean;
  WatchFrombeginningService: any;
  related_videos: any;
  recommendLogo: any;
  sendProgressEvent = false;
  @ViewChild('player') element!: ElementRef<HTMLDivElement>;

  @HostListener('window:beforeunload', ['$event'])
  callPlayerApi($event) {
    this.handleContinueWatching('callPlayerApi');
  }

  constructor(
    private dailymotionApiService: DailymotionApiService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private renderer: Renderer2,
    private mobileExclusiveService: MobileExclusiveService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer.addClass(this.document.body, 'player-page');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.params = params;
    });
    this.initialActions();
  }

  initialActions() {
    this.dailymotionApiService.videoPaymentInfo.subscribe((data) => {
      this.videoPaymentInfo = data;
    });

    this.dailymotionApiService.thisVideo.subscribe(data => {
      if (data) {
        this.video = data;
        if (data.is_mobile_exclusive) {
          this.router.navigate([`/video/${this.video.slug}`]);
          this.mobileExclusiveService.changeMobileExclusive(true);
          return;
        }
        this.playVideo(this.getDailymotionId(data['dailymotion_url']));

        if (data !== '' &&
          // this.setVideo &&
          (data.hls_playlist_url || data.dailymotion_url)) {
          this.DEVICE_RESTRICTION_TYPE = data.device_restriction_type;
          // this.setVideo = true;
          this.video = data;
          this.next_video =
            this.video.next_episode_slug !== '' &&
            this.video.next_episode_slug !== null;
          this.sprite_image =
            this.video.next_episode_slug !== '' &&
              this.video.next_episode_slug !== null
              ? null
              : data.sprite_image;
          if (this.video) {
            // Get video id based on vide slug for live chat configuration
            //   this.getVideoId();
          }
          if (!this.video.is_restricted) {
            this.geoStatus = false;
          }
          if (!this.geoStatus) {
            this.apiService
              .callGetAPI(`v2/video/cast/${this.video.slug}`)
              .subscribe((data) => {
                this.xrayCastList = data['response'].cast_info;
                if (this.xrayCastList.length > 0) {
                  this.showXray = true;
                }
              });
          }
        }
      }
    });
  }

  showControls() {
    $('.back_btn').css('display', 'block');
    $('.movie-title').css('display', 'block');
    this.showXray = true;
    this.showEpisode = true;
  }

  hideControls() {
    $('.back_btn').css('display', 'none');
    $('.movie-title').css('display', 'none');
    this.showXray = this.mouseOverXray ? true : false;
    this.showEpisode = this.mouseOverEpisode ? true : false;
  }

  ngOnDestroy() {
    this.handleContinueWatching();
    if (this.heartBeatTimerId) {
      clearInterval(this.heartBeatTimerId);
    }
    this.stopHeartBeat();
    if (this.playControlBarSetInterval) {
      clearInterval(this.playControlBarSetInterval);
    }
    // clearInterval(this.intervalId);
    this.dailymotionApiService.reset();
    this.renderer.removeClass(this.document.body, 'player-page');
  }

  ngAfterViewInit() {
    let initdone = false;
    this.dailymotionApiService.dailymotionApiReady.subscribe((ready) => {
      if (ready && !this.player) {
        this.player = DM.player(this.element.nativeElement, {
          width: '100%',
          height: '100%',
          params: {
            'autoplay': false,
            'queue-autoplay-next': false,
            'queue-enable': false,
            'mute': false,
            'loop': false,
            'sharing-enable': false,
            'ui-logo': false,
            'ui-start-screen-info': false,
            'syndication': '1028924',
            'customConfig': {
              'customParams': 'kgender,pvid,kar'
            }

          },

          events: {
            apiready: () => {
              document.getElementsByTagName('iframe')[0].title = 'NETVERSE';
              if (localStorage.getItem('currentUser')) {
                this.headerService.setViewRestriction(false);
              }
              this.isPlayerReady.next(true);
            },
            play: () => {
              this._onPlaying();
            },
            pause: () => { },
            end: () => {
              this._onEnded();
            },
            error: (error) => { },
            controlschange: (event) => { },
            loadedmetadata: (event) => {
              if (!localStorage.getItem('currentUser')) {
                const guvl = (localStorage.getItem('GUVL')) ? Number(this.base64Decode(localStorage.getItem('GUVL'))) : 0;
                localStorage.setItem('GUVL', this.base64Encode(String(guvl + 1)));
              }
              this.handleGuestUserViewLimitation();
              if (!initdone) {
                this.WatchFrombeginningService = this.dailymotionApiService.fetchWatchFromBeginningFlag.subscribe((data) => {
                  this.isWatchFrombeginningSet = data;
                });
                if (this.isWatchFrombeginningSet) {
                  this.player.seek(0);
                  this.video.recently_watched = [];
                  this.storeVideoDuration(true, 0);
                  this.dailymotionApiService.setWatchFromBeginning(false);
                } else {
                  this.player.seek(this.video.seconds ? this.video.seconds : 0);
                }
                initdone = true;
              }
            },
            ad_start: () => {
              this.next_video = false;
            },
            ad_end: () => {
              this.next_video =
                this.video.next_episode_slug !== '' &&
                  this.video.next_episode_slug !== null
                  ? true
                  : false;
            },
            timeupdate: (event) => {
              if (this.player.currentTime === this.player.duration) {
                if (
                  this.video.next_episode_slug !== '' &&
                  this.video.next_episode_slug !== null
                ) {
                  this._onEnded();
                  this.getCompleteVideoDetail();
                }
                if (this.video.is_live === 0 && this.video.is_webseries === 0) {
                  this.getRelatedVideos();
                  $('.grid_box').show();
                  $('.subscribe_box').show();
                }
              } else {
                if (this.video.is_live === 0 && this.video.is_webseries === 0) {
                  $('.grid_box').hide();
                  $('.subscribe_box').hide();
                }
              }
              this.video.currentTime = this.player.currentTime;
              //   localStorage.setItem('videos', JSON.stringify(this.video));

              this.videoProgressPercentage = Math.ceil(
                (this.player.currentTime / this.player.duration) * 100
              );
              if (this.viewCountStatus === 'Expired') {
                if (this.videoProgressPercentage > 99.9) {
                  this.player.seek(this.player.duration);
                  this.router.navigate([`/video/${this.video.slug}`]);
                }
              } else {
                if (
                  this.videoProgressPercentage >= 10 &&
                  this.videoProgressPercentage <= 30
                ) {
                  if (!this.sendProgressEvent) {
                    this.sendProgressEvent = true;
                    this.callTvodViewCount(50);
                  }
                } else if (
                  this.videoProgressPercentage >= 60 &&
                  this.videoProgressPercentage <= 80
                ) {
                  if (!this.sendProgressEvent) {
                    this.sendProgressEvent = true;
                    this.callTvodViewCount(100);
                  }
                } else {
                  this.sendProgressEvent = false;
                }
              }
            }
          }
        });
      }

    });
    this.dailymotionApiService.loadApi();
    if (this.DEVICE_RESTRICTION_TYPE === 'player') {
      this.heartBeat();
      this.trackIsUserWatchingVideo();
    }
  }

  base64Encode(content: string): string {
    return window.btoa(content);
  }

  base64Decode(content: string): string {
    return window.atob(content);
  }

  handleGuestUserViewLimitation() {
    if (!localStorage.getItem('currentUser') && localStorage.getItem('GUVL')) {
      if (Number(this.base64Decode(localStorage.getItem('GUVL'))) > 1) {
        this.gotoBack();
        this.headerService.setViewRestriction(true);
      }
    }
  }

  _onPlaying() {
  }

  _onEnded() {
    // this.isPlaying = false;
    this.video.currentTime = 0;
    this.storeVideoDuration('yes', 0);
    // this.gotoBack();
  }

  gotoBack() {
    this.router.navigate(['video/' + this.params.video_id]);
  }

  nextVideo() {
    this.handleContinueWatching();
    this.getCompleteVideoDetail();
  }

  getCompleteVideoDetail() {
    this.apiService
      .callPostAPI('v2/stop-heart-beat', environment.analyticsApiUrl)
      .subscribe(
        () => {
          this.router.navigate([`watch/${this.video.next_episode_slug.slug}`]);
        },
        (error) => {
          this.router.navigate([`watch/${this.video.next_episode_slug.slug}`]);
          this.apiService.failureResponse(error);
        }
      );
  }

  trackIsUserWatchingVideo() {
    if (this.heartBeatTimerId) {
      clearInterval(this.heartBeatTimerId);
    }
    this.heartBeatTimerId = setInterval(() => {
      this.heartBeat();
    }, 20000); // Heart beat timer 2 min
  }

  heartBeat() {
    this.apiService
      .callPostAPI('v2/heart-beat', environment.analyticsApiUrl)
      .subscribe();
  }

  stopHeartBeat() {
    this.apiService
      .callPostAPI('v2/stop-heart-beat', environment.analyticsApiUrl)
      .subscribe();
  }

  getRelatedVideos() {
    const url = 'v2/related_videos/';
    this.apiService.callGetAPI(url + this.video.slug).subscribe(
      (response) => {
        this.related_videos = response['response']['endscreen'];
        this.recommendLogo = response['response']['logo'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  callTvodViewCount(completePercent) {
    if (this.video && this.video.price > 0) {
      this.apiService
        .callPostAPI('v2/tvod_view_count', {
          transaction_id: this.videoPaymentInfo.transaction_id,
          complete_percentage: completePercent
        })
        .subscribe((data) => {
          if (data.hasOwnProperty('response')) {
            this.viewCountStatus = data['response'].status;
            if (this.viewCountStatus === 'Expired') {
              this.storeVideoDuration(true, 0);
            }
          }
        });
    }
  }

  xrayListClicked() {
    this.showXrayList = !this.showXrayList;
  }

  showXrayCastDetailMethod(cast) {
    const a = document.createElement('a');
    if (cast.external_url !== null && cast.external_url !== undefined) {
      a.href = cast.external_url;
      a.setAttribute('target', '_blank');
      this.player.pause();
      a.click();
      this.showXrayList = false;
    }
  }

  handleContinueWatching(method?: string) {
    if (this.video.currentTime && !this.video.is_live && this.videoProgressPercentage >= 5) {
      const continueWatchingIsActive = (this.viewCountStatus === 'Expired') ? 0 : 1;
      this.storeVideoDuration(false, continueWatchingIsActive);
    } else {
      let continueWatchingIsActive = 0;
      let seconds = 0;
      if (!this.video.currentTime) {
        // tslint:disable-next-line:max-line-length
        continueWatchingIsActive = (this.video.hasOwnProperty('recently_watched') && this.video.recently_watched.length > 0 && this.video.recently_watched[0].hasOwnProperty('continue_watching_is_active')) ? this.video.recently_watched[0].continue_watching_is_active : 0;
        // tslint:disable-next-line:max-line-length
        seconds = (this.video.hasOwnProperty('recently_watched') && this.video.recently_watched.length > 0 && this.video.recently_watched[0].hasOwnProperty('seconds')) ? this.video.recently_watched[0].seconds : 0;
      }
      this.storeVideoDuration(true, continueWatchingIsActive, seconds);
    }
    if (method && method === 'callPlayerApi') {
      return false;
    }
  }

  storeVideoDuration(isEnded: boolean | string, isContinueWatchingHistoryActive: number, storedSeconds?: number) {
    let seconds = 0;
    if (storedSeconds) {
      seconds = storedSeconds;
    } else {
      seconds = (this.player) ? this.player.currentTime : 0;
    }
    const isWatchHistoryActive = 1;
    if (this.videoProgressPercentage > 95) {
      isContinueWatchingHistoryActive = 0;
    }
    if (this.video.is_live === 0) {
      const payload = {
        video_slug: this.video.slug,
        seconds,
        isWatchHistoryActive: isWatchHistoryActive,
        isContinueWatchingHistoryActive: isContinueWatchingHistoryActive,
        is_ended: (isEnded === 'yes' || this.videoProgressPercentage > 99)
      };
      this.apiService
        .callPostAPI(
          'v2/videoanalytics',
          payload,
          environment.analyticsApiUrl
        )
        .subscribe();
    }
  }

  public playVideo(dailymotionId: string) {
    if (this.isPlayerReady.value) {
      this.player.load({ video: dailymotionId });
    } else {
      this.isPlayerReady.subscribe((value) => {
        if (value === true) {
          this.player.load({ video: dailymotionId });
        }
      });
    }
  }

  public play() {
    this.player.play();
  }

  getDailymotionId(dailymotionUrl: string) {
    if (!dailymotionUrl) {
      return null;
    }
    let regex = /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/;
    if (dailymotionUrl.includes('dailymotion')) {
      regex = /^.+dailymotion.com\/((video|hub)\/([^_]+))?[^#]*(#video=([^_&]+))?/;
    }
    const matches = dailymotionUrl.match(regex);
    return matches ? matches[5] || matches[3] : null;
  }
}
