<div class="login-signup-modal modal flex-modal">
  <div class="overlay fadeIn animated"></div>
  <div class="modal-body zoomIn animated wow">
    <div class="login-modal bg-modal" style="display: block;">
      <svg (click)="onClose()" class="modal-close" viewBox="0 0 15 14" style="background-color:#ffffff00" version="1.1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
        width="14px" height="14px">
        <g class="Layer%201">
          <path id="Forma%201" d="M 8.7347 7.1472 L 14.1725 1.7849 C 14.5104 1.452 14.5104 0.9119 14.1725 0.5791 C 13.8347 0.2459 13.2876 0.2459 12.9497 0.5791 L 7.512 5.9413 L 2.0739 0.5791 C 1.736 0.2459 1.189 0.2459 0.8511 0.5791 C 0.5132 0.9119 0.5132 1.452 0.8511 1.7849 L 6.2891 7.1472 L 0.8511 12.5095 C 0.5132 12.8424 0.5132 13.3824 0.8511 13.7153 C 1.02 13.882 1.2413 13.9653 1.4625 13.9653 C 1.6836 13.9653 1.9049 13.882 2.0739 13.7153 L 7.512 8.353 L 12.9497 13.7153 C 13.1187 13.882 13.3401 13.9653 13.5612 13.9653 C 13.7822 13.9653 14.0037 13.882 14.1725 13.7153 C 14.5104 13.3824 14.5104 12.8424 14.1725 12.5095 L 8.7347 7.1472 Z"
            fill="#000"></path>
        </g>
      </svg>
      <svg width="62" height="49" viewBox="0 0 62 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34 37C34 36.4477 33.5523 36 33 36H4.9998C4.4476 36 3.99991 35.5524 3.9998 35.0002L3.99438 7.72105C3.99422 6.91429 4.90029 6.43945 5.5636 6.89867L26.862 21.644C27.1964 21.8754 27.5934 21.9993 28 21.9993C28.4066 21.9993 28.8036 21.8754 29.138 21.644L50.4309 6.90607C51.0941 6.44701 52 6.92171 52 7.72833V23C52 23.5523 52.4477 24 53 24H55C55.5523 24 56 23.5523 56 23V4C55.9984 2.93962 55.5765 1.92313 54.8267 1.17333C54.0769 0.423524 53.0604 0.001587 52 0H4C2.93946 0.00105895 1.92266 0.422827 1.17274 1.17274C0.422826 1.92266 0.00105895 2.93946 0 4V36C0.001587 37.0604 0.423524 38.0769 1.17333 38.8267C1.92313 39.5765 2.93962 39.9984 4 40H33C33.5523 40 34 39.5523 34 39V37ZM44.3968 4C45.3771 4 45.772 5.26415 44.966 5.82219L28.5692 17.1739C28.2268 17.411 27.7732 17.411 27.4308 17.1739L11.034 5.82219C10.228 5.26415 10.6229 4 11.6032 4H44.3968Z" fill="url(#paint0_linear_29_177)"/>
        <path d="M47 49C55.2843 49 62 42.2843 62 34C62 25.7157 55.2843 19 47 19C38.7157 19 32 25.7157 32 34C32 42.2843 38.7157 49 47 49Z" fill="url(#paint1_linear_29_177)"/>
        <path d="M41.4644 34.5357L46.0179 39.0893L53.6072 29.9821" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_29_177" x1="28" y1="0" x2="28" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#222222"/>
        <stop offset="1" stop-color="#101010" stop-opacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint1_linear_29_177" x1="47" y1="19" x2="47" y2="49" gradientUnits="userSpaceOnUse">
        <stop stop-color="#45BCFF"/>
        <stop offset="1" stop-color="#0075FF"/>
        </linearGradient>
        </defs>
      </svg>
      <h3 class="pop-head">
        {{"RESENT_LINK.AFTER_SENT_EMAIL" | translate
      }}
      </h3>
        <p class="modal-para">{{"RESENT_LINK.EMAIL_RECIVE_DISCRIPTION" | translate
          }}
        </p>
      </div>
  </div>
</div>