<div class="slider-item">
  <div class="slider-img" lazy-load-images>
    <img alt="video.title" src="assets/images/blank_new.png">
  </div>
  <div class="slider-content shimmer">
    <p>
    </p>
    <span class="category-text">
    </span>
    <!-- <div class="video-options">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
        x="0px" y="0px" viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve"
        width="20px" height="20px">
        <g>
          <g>
            <circle cx="192" cy="42.667" r="46.667" fill="#696969" />
          </g>
        </g>
        <g>
          <g>
            <circle cx="192" cy="192" r="46.667" fill="#696969" />
          </g>
        </g>
        <g>
          <g>
            <circle cx="192" cy="341.333" r="46.667" fill="#696969" />
          </g>
        </g>
      </svg>
      <div class="video-option-list">
        <ul>
          <li></li>
        </ul>
        <div class="overlay"></div>
      </div>
    </div> -->
  </div>
</div>