<div class="container cms-content"> 
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 3pt;" dir="ltr"><span style="font-size: 26pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Penyataan Kebijakan Privasi.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Terima kasih telah berkunjung ke website kami. Kebijakan Privasi ini menjelaskan tentang bagaimana cara kami menggunakan informasi personal/pribadi yang telah terkumpul dalam situs ini.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Mohon dibaca dan dicermati kebijakan privasi ini sebelum menggunakan situs atau memasukan berbagai informasi pribadi kedalam situs ini. Dengan menggunakan situs ini, anda dapat menerima segala perbuatan perbuatan/ketentuan yang telah dijelaskan dalam kebijakan privasi ini. Kebijakan ini dapat berubah, setiap perubahan yang ada akan post didalam situs dan perubahan-perubahan tersebut hanya akan diterapkan untuk segala aktivitas dan informasi yang telah berjalan ke depan, tidak berlaku surut. Anda disarankan untuk meninjau kembali kebijakan privasi ketika anda mengunjungi situs ini untuk memastikan anda telah mengerti bahwa informasi pribadi yang telah diberikan sewaktu-waktu akan digunakan.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Catatan: Perbuatan-perbuatan yang telah diatur dalam kebijakan privasi ini hanya berlaku untuk website ini. Jika anda menghubungkan dengan website lain mohon agar ditinjau atau dibaca kembali kebijakan privasi yang telah diumumkan dalam situsnya.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;" dir="ltr"><span style="font-size: 15pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Kumpulan Informasi</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Kami mengumpulkan informasi yang dapat diidentifikasi, seperti Nama, alamat, alamat email,dll., yang dengan sukarela telah dimasukkan oleh para pengunjung situs ini. Informasi yang anda berikan digunakan untuk memenuhi permohonan khusus. Informasi tersebut hanya untuk memenuhi permohonan khusus, kecuali jika&nbsp; anda mengizinkan kami untuk menggunakannya dengan cara lain, sebagai contoh, untuk memasukan email anda ke dalam salah satu mailing list kami.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;" dir="ltr"><span style="font-size: 15pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Cookie/Tracking Technology</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Cookie dan tracking technology tergantung pada fitur yang ditampilkan. Cookie dan Tracking Technologyberguna untuk mengumpulkan berbagai informasi seperti tipe browser dan sistem pengoperasian, merekam jumlah pengunjung situs, dan pemahaman tentang bagaimana pengunjung menggunakan situs ini. Cookies juga dapat membantu pengunjung agar dapat menyesuaikan dengan situs.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Informasi Pribadi tidak dapat dikumpulkan melalui cookies dan tracking technology lainnya, akan tetapi jika anda sebelumnya telah memberikan informasi pribadi yang dapat diidentifikasi, cookies dapat terhubung pada informasi tersebut.</span></p>
<p><span><br /><br /></span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;" dir="ltr"><span style="font-size: 15pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Penyaluran Informasi</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Kami (situs) dapat memberikan informasi pada perwakilan pemerintah atau perusahaan-perusahaan lain yang membantu kami (situs) dalam pencegahan penipuan atau dalam proses penyelidikan. Kami juga dapat melakukan sesuatu jika : (1) Diizinkan dan diwajibkan oleh hukum;atau, (2) usaha untuk melindungi dari pelanggaran atau pencegahan atau potensi penipuan atau transaksi-transaksi tidak sah;atau, (3) proses penyelidikan pelanggaran yang sedang berjalan. Informasi tidak dapat diberikan kepada perusahaan lain untuk kepentingan atau maksud pemasaran/penjualan.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;" dir="ltr"><span style="font-size: 15pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Komitmen untuk Perlindungan Data</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Informasi data pribadi anda yang mudah teridentifikasi tersimpan dengan aman. Hanya orang-orang dan/atau karyawan-karyawan yang memiliki kewenangan, (yang telah sepakat untuk menjaga informasi aman dan rahasia) memiliki akses untuk informasi tersebut.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Semua email dan berita-berita yang bersumber dari situs ini memperbolehkan anda untuk menyisihkannya atau menyimpannya dalam email Anda.</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;" dir="ltr"><span style="font-size: 15pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Kontak dan Informasi Tentang Kebijakan Privasi.</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Jika anda memiliki pertanyaan, perhatian, atau komentar-komentar tentang kebijakan privasi anda dapat menghubungi kami melalui kontak informasi dibawah ini :</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Email : support@netverse.id</span></p>
<p><span>&nbsp;</span></p>
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;" dir="ltr"><span style="font-size: 11pt; font-family: Arial; color: #ffffff; background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;">Kami memiliki hak untuk melakukan perubahan pada kebijakan ini. Perubahan-perubahan yang ada akan dipost di situs ini.</span></p>
<p><span id="docs-internal-guid-9fc02671-7fff-c31f-5ded-7ea55520f57c">&nbsp;</span></p>

</div>