<div class="login-signup-modal modal flex-modal">
  <div class="overlay fadeIn animated"></div>
  <div class="modal-body zoomIn animated wow">
    <div class="login-modal bg-complete-modal" style="display: block;">
      <svg class="modal-close" (click)="onClose('userIncomplete')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.3 5.70998C18.2075 5.61728 18.0976 5.54373 17.9766 5.49355C17.8556 5.44337 17.726 5.41754 17.595 5.41754C17.464 5.41754 17.3343 5.44337 17.2134 5.49355C17.0924 5.54373 16.9825 5.61728 16.89 5.70998L12 10.59L7.10999 5.69998C7.01741 5.6074 6.9075 5.53396 6.78654 5.48385C6.66557 5.43375 6.53593 5.40796 6.40499 5.40796C6.27406 5.40796 6.14442 5.43375 6.02345 5.48385C5.90249 5.53396 5.79258 5.6074 5.69999 5.69998C5.60741 5.79256 5.53397 5.90247 5.48387 6.02344C5.43376 6.1444 5.40797 6.27405 5.40797 6.40498C5.40797 6.53591 5.43376 6.66556 5.48387 6.78652C5.53397 6.90749 5.60741 7.0174 5.69999 7.10998L10.59 12L5.69999 16.89C5.60741 16.9826 5.53397 17.0925 5.48387 17.2134C5.43376 17.3344 5.40797 17.464 5.40797 17.595C5.40797 17.7259 5.43376 17.8556 5.48387 17.9765C5.53397 18.0975 5.60741 18.2074 5.69999 18.3C5.79258 18.3926 5.90249 18.466 6.02345 18.5161C6.14442 18.5662 6.27406 18.592 6.40499 18.592C6.53593 18.592 6.66557 18.5662 6.78654 18.5161C6.9075 18.466 7.01741 18.3926 7.10999 18.3L12 13.41L16.89 18.3C16.9826 18.3926 17.0925 18.466 17.2135 18.5161C17.3344 18.5662 17.4641 18.592 17.595 18.592C17.7259 18.592 17.8556 18.5662 17.9765 18.5161C18.0975 18.466 18.2074 18.3926 18.3 18.3C18.3926 18.2074 18.466 18.0975 18.5161 17.9765C18.5662 17.8556 18.592 17.7259 18.592 17.595C18.592 17.464 18.5662 17.3344 18.5161 17.2134C18.466 17.0925 18.3926 16.9826 18.3 16.89L13.41 12L18.3 7.10998C18.68 6.72998 18.68 6.08998 18.3 5.70998Z" fill="#F8F8F8"/>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path d="M29.3333 40H34.6667V45.3334H29.3333V40ZM29.3333 18.6667H34.6667V34.6667H29.3333V18.6667ZM32 5.33337C17.2533 5.33337 5.33334 17.3334 5.33334 32C5.33334 39.0725 8.14285 45.8552 13.1438 50.8562C15.6201 53.3324 18.5598 55.2967 21.7951 56.6368C25.0305 57.977 28.4981 58.6667 32 58.6667C39.0724 58.6667 45.8552 55.8572 50.8562 50.8562C55.8572 45.8552 58.6667 39.0725 58.6667 32C58.6667 28.4981 57.9769 25.0305 56.6368 21.7951C55.2967 18.5598 53.3324 15.6201 50.8562 13.1439C48.38 10.6676 45.4402 8.70338 42.2049 7.36325C38.9695 6.02313 35.5019 5.33337 32 5.33337ZM32 53.3334C26.342 53.3334 20.9158 51.0858 16.9151 47.085C12.9143 43.0842 10.6667 37.658 10.6667 32C10.6667 26.3421 12.9143 20.9159 16.9151 16.9151C20.9158 12.9143 26.342 10.6667 32 10.6667C37.658 10.6667 43.0842 12.9143 47.0849 16.9151C51.0857 20.9159 53.3333 26.3421 53.3333 32C53.3333 37.658 51.0857 43.0842 47.0849 47.085C43.0842 51.0858 37.658 53.3334 32 53.3334Z" fill="#F8F8F8"/>
      </svg>

      <h3 class="pop-head-complete">
        {{ 'COMPLETE_PROFILE.COMPLETE_PROFILE_ACCOUNT' | translate }}
      </h3>

      <a href="javascript:void(0)" class="resend-btn" (click)="onClick()">{{ 'MY_PROFILE.EDIT_PROFILE' | translate }}
      </a>
    </div>
  </div>
</div>