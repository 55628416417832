import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  private bannerData = new BehaviorSubject<any>(null);
  public bannerData$ = this.bannerData.asObservable();
  private newData = new BehaviorSubject<any>(null);
  public newData$ = this.newData.asObservable();
  private webseriesData = new BehaviorSubject<any>(null);
  public webseriesData$ = this.webseriesData.asObservable();
  private popular = new BehaviorSubject<any>(null);
  public popular$ = this.popular.asObservable();
  public homeCategoryData;
  private mainmenuCategoryData = new BehaviorSubject<any>(null);
  public mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
  private selectedCommentData = new BehaviorSubject<any>(null);
  public selectedCommentData$ = this.selectedCommentData.asObservable();

  // Ideal for handling the guest user video view restriction.
  private restrictGuestUser = new BehaviorSubject<any>(false);
  public restrictGuestUser$ = this.restrictGuestUser.asObservable();

  constructor(private apiService: ApiService,
    private titleService: Title) {}

  fetchData() {
    return this.apiService
      .callGetAPI('v2/footer', environment.commonApiUrl)
      .pipe(
        map((data) => {
          if (data['response'].site_link.site_name !== '') {
            this.titleService.setTitle(data['response'].site_link.site_name);
          }
          return data;
        })
      );
  }
  setData(data) {
    this.apiData.next(data);
  }

  fetchBanner() {
    this.apiService.callGetAPI('v2/netvers_home_page_banner').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.bannerData.next(data['response'].banner);
          this.newData.next(data['response'].new);
          this.webseriesData.next(data['response'].webseries);
          this.popular.next(data['response'].popular_on_netprime);
          const isBanner = data['response'].banner.total > 0 ? true : false;
          if (isBanner) {
            localStorage.setItem('isBanner', '1');
          } else {
            localStorage.setItem('isBanner', '0');
          }
        }
      },
      (error) => {
      }
    );
  }

  fetchMainmenuCategories() {
    return this.apiService
      .callGetAPI('v2/menu-categories', environment.commonApiUrl)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  setHomeCategory(data) {
    this.homeCategoryData = data;
  }

  getHomeCategory() {
    return this.homeCategoryData;
  }

  setMainMenuCategory(data) {
    this.mainmenuCategoryData.next(data);
  }

  setViewRestriction(doRestrict = false) {
    this.restrictGuestUser.next(doRestrict);
  }
  updateSelectedComment(message: string) {
    this.selectedCommentData.next(message)
  }
}
