import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/platform-browser";
export class HeaderService {
    constructor(apiService, titleService) {
        this.apiService = apiService;
        this.titleService = titleService;
        this.apiData = new BehaviorSubject(null);
        this.apiData$ = this.apiData.asObservable();
        this.bannerData = new BehaviorSubject(null);
        this.bannerData$ = this.bannerData.asObservable();
        this.newData = new BehaviorSubject(null);
        this.newData$ = this.newData.asObservable();
        this.webseriesData = new BehaviorSubject(null);
        this.webseriesData$ = this.webseriesData.asObservable();
        this.popular = new BehaviorSubject(null);
        this.popular$ = this.popular.asObservable();
        this.mainmenuCategoryData = new BehaviorSubject(null);
        this.mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
        this.selectedCommentData = new BehaviorSubject(null);
        this.selectedCommentData$ = this.selectedCommentData.asObservable();
        // Ideal for handling the guest user video view restriction.
        this.restrictGuestUser = new BehaviorSubject(false);
        this.restrictGuestUser$ = this.restrictGuestUser.asObservable();
    }
    fetchData() {
        return this.apiService
            .callGetAPI('v2/footer', environment.commonApiUrl)
            .pipe(map((data) => {
            if (data['response'].site_link.site_name !== '') {
                this.titleService.setTitle(data['response'].site_link.site_name);
            }
            return data;
        }));
    }
    setData(data) {
        this.apiData.next(data);
    }
    fetchBanner() {
        this.apiService.callGetAPI('v2/netvers_home_page_banner').subscribe((data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
                this.bannerData.next(data['response'].banner);
                this.newData.next(data['response'].new);
                this.webseriesData.next(data['response'].webseries);
                this.popular.next(data['response'].popular_on_netprime);
                const isBanner = data['response'].banner.total > 0 ? true : false;
                if (isBanner) {
                    localStorage.setItem('isBanner', '1');
                }
                else {
                    localStorage.setItem('isBanner', '0');
                }
            }
        }, (error) => {
        });
    }
    fetchMainmenuCategories() {
        return this.apiService
            .callGetAPI('v2/menu-categories', environment.commonApiUrl)
            .pipe(map((data) => {
            return data;
        }));
    }
    setHomeCategory(data) {
        this.homeCategoryData = data;
    }
    getHomeCategory() {
        return this.homeCategoryData;
    }
    setMainMenuCategory(data) {
        this.mainmenuCategoryData.next(data);
    }
    setViewRestriction(doRestrict = false) {
        this.restrictGuestUser.next(doRestrict);
    }
    updateSelectedComment(message) {
        this.selectedCommentData.next(message);
    }
}
HeaderService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(i0.inject(i1.ApiService), i0.inject(i2.Title)); }, token: HeaderService, providedIn: "root" });
