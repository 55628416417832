/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./watch-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../video-detail/player/player.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../video-detail/player/player.component";
import * as i5 from "../../core/services/api.service";
import * as i6 from "@angular/router";
import * as i7 from "../../core/services/player.service";
import * as i8 from "ng-connection-service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../core/auth/auth.service";
import * as i11 from "@angular/fire/database";
import * as i12 from "ngx-toastr";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../video-detail/dailymotion-player/dailymotion-player.component.ngfactory";
import * as i15 from "../video-detail/dailymotion-player/dailymotion-player.component";
import * as i16 from "../../core/services/dailymotion.service";
import * as i17 from "../../core/services/header.service";
import * as i18 from "../../core/services/mobile-exclusive.service";
import * as i19 from "../video-detail/trailer/trailer.component.ngfactory";
import * as i20 from "../video-detail/trailer/trailer.component";
import * as i21 from "./watch-video.component";
var styles_WatchVideoComponent = [i0.styles];
var RenderType_WatchVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WatchVideoComponent, data: {} });
export { RenderType_WatchVideoComponent as RenderType_WatchVideoComponent };
function View_WatchVideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader-ring"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "loader-ring-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loader-ring-track"]], null, null, null, null, null))], null, null); }
function View_WatchVideoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-player", [["class", "video-player-center title-grd"]], null, [[null, "livchatStatus"], ["window", "scroll"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:beforeunload" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).callPlayerApi($event) !== false);
        ad = (pd_1 && ad);
    } if (("livchatStatus" === en)) {
        var pd_2 = (_co.toggleLiveChat($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_PlayerComponent_0, i2.RenderType_PlayerComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "player-live-chat": 0 }), i1.ɵdid(3, 4440064, null, 0, i4.PlayerComponent, [i5.ApiService, i6.ActivatedRoute, i7.PlayerService, i8.ConnectionService, i6.Router, i9.TranslateService, i10.AuthService, i11.AngularFireDatabase, i12.ToastrService, i13.DomSanitizer, i3.PlatformLocation], null, { livchatStatus: "livchatStatus" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "video-player-center title-grd"; var currVal_1 = _ck(_v, 2, 0, (_co.isMobile || _co.liveChatStatus)); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 3, 0); }, null); }
function View_WatchVideoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dailymotion-player", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).callPlayerApi($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_DailymotionPlayerComponent_0, i14.RenderType_DailymotionPlayerComponent)), i1.ɵdid(1, 4440064, null, 0, i15.DailymotionPlayerComponent, [i16.DailymotionApiService, i5.ApiService, i6.ActivatedRoute, i6.Router, i17.HeaderService, i1.Renderer2, i18.MobileExclusiveService, i3.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WatchVideoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trailer", [], null, null, null, i19.View_TrailerComponent_0, i19.RenderType_TrailerComponent)), i1.ɵdid(1, 245760, null, 0, i20.TrailerComponent, [i6.Router, i6.ActivatedRoute, i5.ApiService, i8.ConnectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WatchVideoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "video-cs-blk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "back_btn"], ["style", "z-index: 10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.PageBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [[":xml:space", "preserve"], ["height", "33px"], ["id", "Layer_1"], ["version", "1.1"], ["viewBox", "0 0 512 512"], ["width", "33px"], ["x", "0px"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "], ["fill", "#FFFFFF"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "movie-title title-grd"], ["style", "z-index: 10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "revoke-popup-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 12, "div", [["class", "revoke-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "h2", [["class", "revoke-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 4, ":svg:svg", [["height", "512"], ["style", "enable-background: new 0 0 512 512"], ["viewBox", "0 0 357 357"], ["width", "512"], ["x", "0"], ["y", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.PageBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, ":svg:g", [["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, ":svg:g", [["id", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, ":svg:polygon", [["data-original", "#000000"], ["fill", "#3b3a36"], ["points", "357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3     214.2,178.5   "]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "revoke-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoTitle; _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("VIDEO_DETAIL.STREAMING_ERROR")); _ck(_v, 12, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("VIDEO_DETAIL.STREAMING_ERROR_DESC")); _ck(_v, 21, 0, currVal_2); }); }
export function View_WatchVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WatchVideoComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WatchVideoComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WatchVideoComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WatchVideoComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WatchVideoComponent_5)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.videos; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.videos && _co.isYoutube); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.videos && !_co.isYoutube); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.videos && !_co.isMainPlayer); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.videoTitle; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_WatchVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-watch-video", [], null, [["window", "popstate"]], function (_v, en, $event) { var ad = true; if (("window:popstate" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPopState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_WatchVideoComponent_0, RenderType_WatchVideoComponent)), i1.ɵdid(1, 4440064, null, 0, i21.WatchVideoComponent, [i5.ApiService, i6.ActivatedRoute, i7.PlayerService, i16.DailymotionApiService, i3.PlatformLocation, i6.Router, i1.ElementRef, i13.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WatchVideoComponentNgFactory = i1.ɵccf("app-watch-video", i21.WatchVideoComponent, View_WatchVideoComponent_Host_0, {}, {}, []);
export { WatchVideoComponentNgFactory as WatchVideoComponentNgFactory };
