<div class="adsdetail-page">
 <div class="banner-logo" [ngStyle]="{ 'background-color': promotionalAds.color_code }">
  <img src="{{ promotionalAds.web_logo }}" class="forlogos"/>
  <img src="{{ promotionalAds.web_background_image }}" class="forbanners"/>
 </div>
 <div class="carousels-section" [ngStyle]="{ 'background-color': promotionalAds.color_code }">
   <h2>{{ promotionalAds.title }}</h2>
   <section class="small-vplay container continue-wrap">
    <div class="small-vplay" *ngIf="promotionalAds?.data?.length > 0" [@enterMainAnimation]>
        <div class="genre-videos" *ngIf="promotionalAds?.data?.length > 0">
            <div class="cs-slider main-webseries-section">
                <app-contus-slider #contusSlider [id]="'promotional_ads'" url="promotional_ads"
                    (nextPageCall)="next($event)" [total]="promotionalAds.total"
                    [length]="promotionalAds.data.length" class="small-slider webseries-slider"
                    [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 4, slideBy: 4, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 5, slideBy: 5, margion : 10} } }">
                    <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
                      *ngFor="let video of promotionalAds.data" (click)="gotoVideoDetail(video.slug)">
                      <app-netverse-video [video]="video" [Video_type]="2"></app-netverse-video>
                    </div>
                </app-contus-slider>
                <div class="hidden-block"></div>
            </div>
        </div>
    </div>
  </section>
 </div>
</div>
