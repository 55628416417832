<div class="login-signup-modal modal flex-modal">
  <div class="overlay fadeIn animated"></div>
  <div class="modal-body zoomIn animated wow">
    <div class="login-modal bg-modal" style="display: block;">
      <svg (click)="onClose()" class="modal-close" viewBox="0 0 15 14" style="background-color:#ffffff00" version="1.1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
        width="14px" height="14px">
        <g class="Layer%201">
          <path id="Forma%201" d="M 8.7347 7.1472 L 14.1725 1.7849 C 14.5104 1.452 14.5104 0.9119 14.1725 0.5791 C 13.8347 0.2459 13.2876 0.2459 12.9497 0.5791 L 7.512 5.9413 L 2.0739 0.5791 C 1.736 0.2459 1.189 0.2459 0.8511 0.5791 C 0.5132 0.9119 0.5132 1.452 0.8511 1.7849 L 6.2891 7.1472 L 0.8511 12.5095 C 0.5132 12.8424 0.5132 13.3824 0.8511 13.7153 C 1.02 13.882 1.2413 13.9653 1.4625 13.9653 C 1.6836 13.9653 1.9049 13.882 2.0739 13.7153 L 7.512 8.353 L 12.9497 13.7153 C 13.1187 13.882 13.3401 13.9653 13.5612 13.9653 C 13.7822 13.9653 14.0037 13.882 14.1725 13.7153 C 14.5104 13.3824 14.5104 12.8424 14.1725 12.5095 L 8.7347 7.1472 Z"
            fill="#000"></path>
        </g>
      </svg>
      <!-- <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z" fill="#ff4b4b" stroke="#ff4b4b" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z" fill="#ff4b4b" stroke="#ff4b4b" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#ff4b4b" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5" stroke="#ff4b4b" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0417 18.5687C14.0417 19.7884 15.0304 20.7771 16.25 20.7771C17.4696 20.7771 18.4583 19.7884 18.4583 18.5687H14.0417ZM18.4583 18.3479C18.4583 17.1283 17.4696 16.1396 16.25 16.1396C15.0304 16.1396 14.0417 17.1283 14.0417 18.3479H18.4583ZM31.7083 18.5687C31.7083 19.7884 32.697 20.7771 33.9167 20.7771C35.1363 20.7771 36.125 19.7884 36.125 18.5687H31.7083ZM36.125 18.3479C36.125 17.1283 35.1363 16.1396 33.9167 16.1396C32.697 16.1396 31.7083 17.1283 31.7083 18.3479H36.125ZM31.8217 34.615C32.2073 35.772 33.458 36.3974 34.615 36.0117C35.772 35.626 36.3974 34.3754 36.0117 33.2183L31.8217 34.615ZM14.155 33.2183C13.7693 34.3754 14.3946 35.626 15.5517 36.0117C16.7087 36.3974 17.9593 35.772 18.345 34.615L14.155 33.2183ZM44.9583 25.0833C44.9583 36.06 36.06 44.9583 25.0833 44.9583V49.375C38.4992 49.375 49.375 38.4992 49.375 25.0833H44.9583ZM25.0833 44.9583C14.1067 44.9583 5.20833 36.06 5.20833 25.0833H0.791667C0.791667 38.4992 11.6674 49.375 25.0833 49.375V44.9583ZM5.20833 25.0833C5.20833 14.1067 14.1067 5.20833 25.0833 5.20833V0.791667C11.6674 0.791667 0.791667 11.6674 0.791667 25.0833H5.20833ZM25.0833 5.20833C36.06 5.20833 44.9583 14.1067 44.9583 25.0833H49.375C49.375 11.6674 38.4992 0.791667 25.0833 0.791667V5.20833ZM18.4583 18.5687V18.3479H14.0417V18.5687H18.4583ZM36.125 18.5687V18.3479H31.7083V18.5687H36.125ZM36.0117 33.2183C34.7042 29.2959 31.1894 25.0833 25.0833 25.0833V29.5C28.7381 29.5 30.9208 31.9125 31.8217 34.615L36.0117 33.2183ZM25.0833 25.0833C18.9773 25.0833 15.4625 29.2959 14.155 33.2183L18.345 34.615C19.2459 31.9125 21.4285 29.5 25.0833 29.5V25.0833Z" fill="#FF4B4B"/>
      </svg>

        <h3 class="pop-head">
          {{ 'RESENT_LINK.INACTIVE_ACCOUNT' | translate }}
        </h3>
        <a href="javascript:void(0)" class="resend-btn" (click)="onClick('resent')">{{ 'RESENT_LINK.RESENT_EMAIL' | translate }}
        </a>
    </div>
  </div>
</div>