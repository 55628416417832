import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-tv',
  templateUrl: './privacy-policy-tv.component.html',
  styleUrls: ['./privacy-policy-tv.component.scss']
})
export class PrivacyPolicyTvComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    document.body.classList.add('main-dark');
  }

}
