<!-- <div *ngIf="splashScreenVisible">
  <app-lottie-splash-screen></app-lottie-splash-screen>

</div> -->
<app-header *ngIf="headerFlag"></app-header>
<div class="min-content">
  <router-outlet></router-outlet>
  <div class="no-connection" *ngIf="!status">
    <div class="overlay"></div>
    <div class="content">
      <div class="content-header">
        <h2>Alert</h2>
      </div>
      <div class="content-text">
        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="200" viewBox="0 0 301.488 148.903">
          <g id="Group_21130" data-name="Group 21130" transform="translate(-3016 7100)">
            <g id="Group_20010" data-name="Group 20010" transform="translate(3126.721 -7042.667)">
              <rect id="Rectangle_6581" data-name="Rectangle 6581" width="47.47" height="0.86" transform="matrix(0.884, 0.467, -0.467, 0.884, 15.456, 59.065)" fill="#1d8ebc"/>
              <path id="Path_28084" data-name="Path 28084" d="M280.491,319.224l43.37-24.822-.48-.807L280.01,318.417Z" transform="translate(-272.55 -236.802)" fill="#1d8ebc"/>
              <rect id="Rectangle_6582" data-name="Rectangle 6582" width="32.18" height="0.599" transform="translate(21.965 41.294) rotate(30.256)" fill="#1d8ebc"/>
              <rect id="Rectangle_6583" data-name="Rectangle 6583" width="32.18" height="0.599" transform="translate(44.14 41.94) rotate(149.744)" fill="#1d8ebc"/>
              <rect id="Rectangle_6584" data-name="Rectangle 6584" width="22.728" height="0.423" transform="translate(25.396 27.138) rotate(30.256)" fill="#1d8ebc"/>
              <rect id="Rectangle_6585" data-name="Rectangle 6585" width="22.728" height="0.423" transform="translate(41.061 27.596) rotate(149.744)" fill="#1d8ebc"/>
              <path id="Path_28085" data-name="Path 28085" d="M363.822,279.235s-7.734-4.7-13.406-25.2l-16.242-54.786-2.965.645s8.121,29.907,9.024,32.227c0,0,7.734,24.622,8.379,26.684s2.707,11.086,6.574,15.856c0,0,4.512,6.961,6.317,7.863Z" transform="translate(-297.348 -191.104)" fill="#1d8ebc"/>
              <path id="Path_28086" data-name="Path 28086" d="M265.542,279.526s7.735-4.7,13.406-25.2L295.19,199.54l2.965.645s-8.121,29.907-9.024,32.227c0,0-7.734,24.622-8.379,26.684s-2.707,11.086-6.574,15.856c0,0-4.512,6.961-6.317,7.863Z" transform="translate(-265.542 -191.245)" fill="#1d8ebc"/>
              <circle id="Ellipse_3695" data-name="Ellipse 3695" cx="5.285" cy="5.285" r="5.285" transform="translate(27.865 0)" fill="#1d8ebc"/>
            </g>
            <g id="Group_20011" data-name="Group 20011" transform="translate(3117.958 -7068.089)">
              <path id="Path_28087" data-name="Path 28087" d="M293.5,161.518a21.777,21.777,0,0,0-.167,30.84l2.807-2.807a17.8,17.8,0,0,1,.164-25.227Z" transform="translate(-267.184 -147.407)" fill="#1d8ebc"/>
              <path id="Path_28088" data-name="Path 28088" d="M279.981,150.728l-2.8-2.8a31.677,31.677,0,0,0-.167,44.857l2.8-2.8a27.718,27.718,0,0,1,.166-39.25Z" transform="translate(-257.867 -140.823)" fill="#1d8ebc"/>
              <path id="Path_28089" data-name="Path 28089" d="M263.652,137.134l-2.8-2.8a41.576,41.576,0,0,0-.166,58.877l2.8-2.8a37.62,37.62,0,0,1,.164-53.27Z" transform="translate(-248.55 -134.238)" fill="#1d8ebc"/>
              <path id="Path_28090" data-name="Path 28090" d="M356.443,161.518l-2.806,2.806a17.806,17.806,0,0,1,.165,25.227l2.806,2.807a21.779,21.779,0,0,0-.165-30.84Z" transform="translate(-299.451 -147.407)" fill="#1d8ebc"/>
              <path id="Path_28091" data-name="Path 28091" d="M370.035,147.924l-2.8,2.8a27.717,27.717,0,0,1,.166,39.251l2.8,2.8a31.679,31.679,0,0,0-.167-44.858Z" transform="translate(-306.035 -140.823)" fill="#1d8ebc"/>
              <path id="Path_28092" data-name="Path 28092" d="M383.629,134.152l-2.8,2.8A37.617,37.617,0,0,1,381,190.221l2.8,2.8a41.578,41.578,0,0,0-.167-58.877Z" transform="translate(-312.619 -134.152)" fill="#1d8ebc"/>
            </g>
            <path id="Path_28093" data-name="Path 28093" d="M537.84,81.107s-2.191-9.8,5.543-8.766h30.294s9.539,4.77,1.031,10.7l-13.535.387s1.676,8.895-4.512,12.246l-7.734.258s-.387,1.676-1.676,1.676l-24.235-.516s-14.051-9.539,1.8-15.727Z" transform="translate(2739.249 -7172.265)" fill="#1d8ebc"/>
            <path id="Path_28094" data-name="Path 28094" d="M80.256,97.2l-15.985.129s-22.817-4-9.024-8.508c0,0,7.477-1.934,9.539-2.063,0,0,2.449-8.508,9.8-6.961l17.532-.387s5.93,2.063,5.414,6.832c0,0,14.825,6.317,19.078,5.672,0,0,8.9,6.317-1.289,9.8,0,0-15.856,5.285-26.168.773C89.151,102.488,81.674,100.3,80.256,97.2Z" transform="translate(2965.184 -7175.727)" fill="#1d8ebc"/>
            <path id="Path_28095" data-name="Path 28095" d="M115.637,357.194a1,1,0,0,1-.964,1.031H76.124a1,1,0,0,1-.964-1.031h0a1,1,0,0,1,.964-1.031h38.55a1,1,0,0,1,.964,1.031Z" transform="translate(2953.393 -7309.774)" fill="#1d8ebc"/>
            <path id="Path_28096" data-name="Path 28096" d="M210.215,357.194c0,.569-.225,1.031-.5,1.031H189.661c-.277,0-.5-.462-.5-1.031h0c0-.569.225-1.031.5-1.031h20.053c.277,0,.5.462.5,1.031Z" transform="translate(2898.176 -7309.774)" fill="#1d8ebc"/>
            <path id="Path_28097" data-name="Path 28097" d="M435.215,356.527c0,.569-.225,1.031-.5,1.031H414.661c-.277,0-.5-.462-.5-1.031h0c0-.569.225-1.031.5-1.031h20.053c.277,0,.5.461.5,1.031Z" transform="translate(2789.194 -7309.452)" fill="#1d8ebc"/>
            <path id="Path_28098" data-name="Path 28098" d="M552.731,357.194c0,.569-.64,1.031-1.43,1.031H494.09c-.789,0-1.43-.462-1.43-1.031h0c0-.569.64-1.031,1.43-1.031H551.3c.789,0,1.43.462,1.43,1.031Z" transform="translate(2751.171 -7309.774)" fill="#1d8ebc"/>
          </g>
        </svg>
        
        <p>Please check your internet connection or try again later.</p>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="footerFlag"></app-footer>