import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  Output
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from '../../core/auth/auth.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import { UpdateFavouriteIcon } from '../../shared/utils/updatefavouriteicon';
import { HandleLastItemonHover } from '../../shared/utils/handlelastitemonhover';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  // @Output() user: any = new EventEmitter();
  isMobile: Boolean = false;
  trending: any = [];
  new: any = [];
  webseries: any = [];
  banner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  newNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  continueWatchingData: any;
  ItemNumber = 7;
  trendingPage = 1;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  search: any = false;
  homeSection: Boolean = true;
  site_link: any = [];
  getHomeCategory;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  error: any = {};
  mostPopular: any = [];
  myList: any = [];
  forYou: any = [];
  promotionalAds: any = [];
  genrePage = 2;
  totalSections = 0;
  renderedSections = 0;
  homePages = new Map();
  isMoreLoaded = false;
  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.homeSection === false) {
      this.homeSection = true;
      if (!this.getHomeCategory) {
        this.getHome();
      }
    }
  }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private userService: UserService,
    private _cdr: ChangeDetectorRef
  ) {
    this.continueWatchingData = [];
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        this.fetchwatchVideo();
        this.checkAPIAuthorization();
      }
    });
  }
  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
    });
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.getHomeCategory = this.headerService.getHomeCategory();
    this.genre.data = [];
    this.new.data = [];
    this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.getSiteLinks();

  //  this.headerService.fetchBanner();
    this.userService.localUser.subscribe((data) => {
      if (data) {
        setTimeout(() => {
          this.getLoggedInUserSections();
        }, 1000);
      }
      if (!data) {
        this.myList.data = [];
        this.forYou.data = [];
      }
    });
    this.headerService.bannerData$.subscribe((data) => {
      this.banner = data;
      if (this.banner === null) {
        this.headerService.fetchBanner();
      }
    });
    this.headerService.newData$.subscribe((data) => {
      this.new = data;
    });
    this.headerService.popular$.subscribe((data) => {
      this.mostPopular = data;
      this.homePages.set('popular_on_netprime', 1);
    });
    this.headerService.webseriesData$.subscribe((data) => {
      this.webseries = data;
    });
    this.homeSection = false;
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
    }
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
  }

  getWindowSize() {
    if (window.innerWidth >= 1199) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  onVideoClick(videoSlug) {
    if(videoSlug.player_type == 1){
      this.router.navigate(['play/' + videoSlug.slug]);
    }else{
    this.router.navigate(['watch/' + videoSlug.slug]);
    }
  }

  getLoggedInUserSections() {
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          if (data['response'].my_list) {
            this.myList = data['response'].my_list;
          }
          if (data['response'].for_you) {
            this.forYou = data['response'].for_you;
          }
        }
      },
      (error) => {
        this.apiService.failureResponse(error);
      }
    );
  }

  getMoreGenres() {
    if (this.isMoreLoaded === false) {
      this.isMoreLoaded = true;
      this.apiService.callGetAPI('v2/home_page_genre?genre_page=' + this.genrePage).subscribe(
        (data) => {
          this.genrePage += 1;
          this.updateHomeGenres(data);
        },
        (error) => {
        }
      );
    }
  }

  updateHomeGenres(data) {
    let index = this.renderedSections;
    for (const section of data['response']) {
      this.renderedSections += 1;
      this.categoriesVideos.push(section);
      this.sectionPage.push(1);
      if (section.total === this.categoriesVideos[index].data.length) {
        this.sectionNextPage[index] = false;
      } else {
        this.sectionNextPage[index] = true;
      }
      index++;
    }
    this.isMoreLoaded = false;
  }

  getHome() {
    this.load = false;
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {
        this.headerService.setHomeCategory(data);
        this.getHomeAfterData(data);
      },
      (error) => {
        // (error) => {
          this.apiService.failureResponse(error);
        // }
      }
    );
  }

  goToPlaylistDetail(slug, title) {
    this.router.navigate(['/profile/my-playlist/' + title + '/' + slug]);
  }

  gotoVideoDetail(slug) {
    this.router.navigate(['video/' + slug]);
  }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      if (data['response'].my_list) {
        this.myList = data['response'].my_list;
        this.homePages.set('my_list', 1);
      }
      if (data['response'].for_you) {
        this.forYou = data['response'].for_you;
        this.homePages.set('for_you', 1);
      }
      if (data['response'].total_sections) {
        this.totalSections = data['response'].total_sections;
      }
      if (data['response'].promotional_ads.data) {
        this.promotionalAds = data['response'].promotional_ads;
        this.promotionalAds.data = this.promotionalAds.data.slice(0, 10);
        this.homePages.set('promotional_ads', 1);
      }
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.total === this.categoriesVideos[index].data.length) {
          this.sectionNextPage[index] = false;
        } else {
          this.sectionNextPage[index] = true;
        }
        index++;
      }
      this.renderedSections = index;
      this.load = true;
    }
  }

  next(url) {
    switch (url) {
      case 'new':
        this.nextObj();
        break;
      case 'trending':
        this.nextObjTrending();
        break;
      case 'popular_on_netprime':
        this.getDynamicSectionData(url);
        break;
      case 'for_you':
        this.getDynamicSectionData(url);
        break;
      case 'my_list':
        this.getDynamicSectionData(url);
        break;
      case 'promotional_ads':
        this.getDynamicSectionData(url);
        break;
    }
  }

  getDynamicSectionData(url) {
    this.homePages.set(url, this.homePages.get(url) + 1);
    this.apiService
      .callGetAPI('v2/home_more?type=' + url + '&page=' + this.homePages.get(url))
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            data['response'].data.forEach((value) => {
              if (url === 'popular_on_netprime') {
                this.mostPopular.data.push(value);
              }
              if (url === 'for_you') {
                this.forYou.data.push(value);
              }
              if (url === 'my_list') {
                this.myList.data.push(value);
              }
              if (url === 'promotional_ads') {
                this.promotionalAds.data.push(value);
              }
            });
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }

  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=new&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.new.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService
        .callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.trending.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  sectionNexts(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_video_more?genre=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }


  sectionNextSeries(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_series_more?genre=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }
  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
    this.homeSection = false;
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link
        ? this.site_link.android_app_link
        : undefined;
      a.click();
    }
  }
  activateUser(key) {
    this.apiService
      .callPostAPI(
        'v2/auth/check_activation_code',
        {
          login_type: 'normal',
          acesstype: 'web',
          key: key
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.userService.displayPopup('sign-in');
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/home']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }
  fetchwatchVideo() {
    if (localStorage.getItem('currentUser')) {
      // If logged in then hit the API
      this.apiService.callGetAPI('v2/continue_watching').subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.continueWatchingData = data['response'].continue_watching.data;
          }
        },
        (error) => {
        }
      );
    } else {
      this.continueWatchingData = [];
    }

  }
  /**
   * Method to add class to parent div when it is hovered
   * @param number i
   */
  handleLastItemOnHover(event, i: number, section: string) {
    HandleLastItemonHover(event, i, section, this.sliderMovedData);
  }
  /**
   * Method to handle the favourite icon when the icon is clicked.
   * @param event
   * @param array video
   */
  updateFavourite(event: any, video) {
    UpdateFavouriteIcon(event, video);
  }
  /**
   * This method checks for user authorization like 403, 401 and handle response.
   *
   * @return void
   */
  checkAPIAuthorization() {
    if (localStorage.getItem('currentUser')) {
      // If logged in then hit the API
      this.apiService.callGetAPI('v2/check_authorization', environment.userApiUrl).subscribe(
        (data) => {
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
    }
  }
}
