<video id="videos"></video>

<div class="grid_box" *ngFor="let relatedVideo of related_videos">
  <a href="{{ relatedVideo?.url }}" class="box" target="_blank">
    <div class="img-video">
      <img alt="thumbnail" src="{{ relatedVideo?.image_url }}" />
    </div>
    <a class="overlay-top">
      <span>{{ relatedVideo?.title }}</span>
    </a>
  </a>
</div>
<div class="subscribe_box" *ngIf="video?.is_subscribe_recommend === 1">
  <a href="/subscription">
    <img alt="Logo" *ngIf="recommendLogo != ''" src="{{ recommendLogo }}" style="margin: 33px 7px" width="104"
      height="47.498" viewBox="0 0 104 47.498" />
  </a>
</div>

<div *ngIf="video && video.is_live === 1 && !video.hls_playlist_url && !geoStatus" class="webrtc-video">
  <div class="player">
    <div class="video-screen">
      <video disablePictureInPicture #videoPlayer id="remoteVideo" autoplay controls></video>
    </div>
    <div id="overaly-static-video" class="overaly-static-video" *ngIf="video.is_live === 1 && !video.hls_playlist_url"
      [ngStyle]="{
        opacity: showPlayerOverlay ? 1 : 0,
        visibility: showPlayerOverlay ? 'visible' : 'hidden'
      }">
      <span *ngIf="playerOverlayInfo == 'unavailable'">
        {{ 'PLAYER.OVERLAY_INFO' | translate }}
      </span>
      <span *ngIf="playerOverlayInfo == 'loading'">
        {{ 'PLAYER.LOADING' | translate }}
      </span>
      <span *ngIf="playerOverlayInfo == 'finished'">
        {{ 'PLAYER.LIVE_END' | translate }}
      </span>
    </div>
  </div>
</div>
<div *ngIf="video && video.is_live === 1 && !geoStatus && !isYoutubePlayer">
  <button type="button" class="open-live-chat btn" (click)="toggleLiveChat('open')"
    *ngIf="this.video && this.video.is_live === 1">
    <svg height="40.422" viewBox="0 0 39.508 40.422" width="39.508" xmlns="http://www.w3.org/2000/svg">
      <g data-name="live chat" id="live_chat" transform="translate(-295.754 -429.522)">
        <path
          d="M-7464.246,13507.794v-27.362a3.023,3.023,0,0,1,3.29-2.9c3.095.221,25.792,0,25.792,0a2.569,2.569,0,0,1,1.547,2.579c-.221,1.843,0,19.528,0,19.528l-23.213.295Z"
          data-name="Path 10178" fill="#ffffff" id="Path_10178" transform="translate(7760 -13048)"></path>
        <path
          d="M-7455.887,13503.964h27.025v-18.183h3.172s2.1-.218,1.943,1.4,0,30.762,0,30.762l-7.448-8.419s-22.633.02-22.667,0-2.026.693-2.026-1.426Z"
          data-name="Path 10179" fill="#ffffff" id="Path_10179" transform="translate(7759 -13048)"></path>
      </g>
    </svg>
    <span> {{ 'PLAYER.OPEN_LIVE_CHAT' | translate }}</span>
  </button>
  <!--Live Chat-->
  <div class="live-chat-app">
    <div class="live-chat-app" #scrollMe [scrollTop]="scrollMe.scrollHeight">
      <div class="livechat-header">
        <button href="javascript:void(0)" (click)="toggleLiveChat('close')" class="close-live-chat chat_back_btn">
          <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="612px" id="Capa_1"
            style="enable-background: new 0 0 612 612" version="1.1" viewBox="0 0 612 612" width="612px" x="0px"
            xmlns="http://www.w3.org/2000/svg" y="0px">
            <g _ngcontent-syl-c15="">
              <g id="cross">
                <g _ngcontent-syl-c15="">
                  <polygon fill="#ffffff" points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397
              306,341.411 576.521,611.397 612,575.997 341.459,306.011 			"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <h3 class="live-chat-title">{{ 'PLAYER.LIVE_CHAT' | translate }}</h3>
      </div>
      <div class="live-chat-contnet" #scrollMe [scrollTop]="scrollMe.scrollHeight"
        [ngStyle]="{ height: livechatInputHeight }">
        <ul>
          <li class="chat" *ngFor="let message of liveMessages; trackBy: trackByFn">
            <img alt="Avator" class="chat-avator" src="{{
                message?.photoUrl === ''
                  ? 'assets/images/user-icon.png'
                  : message?.photoUrl
              }}" />
            <div class="chat-message">
              <div class="chat-view">
                <p class="chat-person">{{ message.name }}</p>
                <span class="chat-time">{{ message.time }}</span>
              </div>
              <p class="message">
                {{ message.text }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="live-chat-input" id="live-chat-input">
        <img alt="Avator" class="chat-avator" src="{{
            user?.response?.profile_picture
              ? user?.response?.profile_picture
              : 'assets/images/user-icon.png'
          }}" />
        <div *ngIf="!loginError" id="live-chat-comment" class="live-chat-text-input-field" appContentEditable="true"
          [attr.data-placeholder]="'PLAYER.TYPE_MESSAGE' | translate" spellcheck="false" [(ngModel)]="liveChatText"
          (keydown.enter)="onKeydown($event)" (keyup.enter)="sendMessageInLive(liveChatText)"
          (keyup)="changeEvent(liveChatText)"></div>

        <div *ngIf="loginError" id="live-chat-comment" class="live-chat-text-input-field">
          <p class="chat-error">Please Login to perform this action</p>
        </div>

        <a href="javascript:void(0)" class="send-comment" (click)="sendMessageInLive(liveChatText)">
          <svg width="25.995" height="22.282" viewBox="0 0 25.995 22.282">
            <path d="M0,60.532,26,49.391,0,38.25v9.5l14.139,1.645L0,51.036Z" transform="translate(0 -38.25)"
              fill="#ed4a1a" />
          </svg>
        </a>
      </div>
      <div *ngIf="commentShortError || commentMaxlengthError || loginError" class="error-text">
        <p class="chat-error" *ngIf="commentMaxlengthError">
          {{ 'PLAYER.COMMENT_LIMIT' | translate }}
        </p>
        <p class="chat-error" *ngIf="commentShortError">Comment is empty.</p>
      </div>
    </div>
  </div>
</div>

<div class="video-cs-blk" *ngIf="!isYoutubePlayer">
  <div class="back_btn" (click)="PageBack()">
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" width="33px"
      height="33px">
      <g>
        <g>
          <path
            d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "
            fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  </div>
  <div class="movie-title title-grd">
    <h2>{{ video?.title }}</h2>
    <h4 *ngIf="video?.is_live == 1 && livestreamStarted && !geoStatus" class="viewscounter">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
      </svg>
      {{ viewersCount }}
    </h4>
    <h3 *ngIf="video && video.season_name">{{ video?.season_name }}</h3>
  </div>
  <div (mouseenter)="mouseOverXray = true" (mouseleave)="mouseOverXray = false" class="xray-contents xray-content-lists"
    *ngIf="showXray && xrayCastList.length > 0">
    <div class="xray-heading">
      <h2 (click)="xrayListClicked()">
        X-Ray
        <span class="xray-arrow">
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.847">
            <g>
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                  c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                  c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
            </g>
          </svg>
        </span>
      </h2>
    </div>
    <div class="xray-lists" *ngIf="showXrayList">
      <ul>
        <li *ngFor="let xray of xrayCastList">
          <div class="xray-image" *ngIf="xray.banner_image">
            <img src={{xray.banner_image}} alt="" [attr.src]="xray.banner_image"
              onerror="this.src='assets/images/xray_placeholder.png';" />
          </div>
          <div class="xray-image" *ngIf="!xray.banner_image">
            <img alt="ThumbnailImage" src="assets/images/xray_placeholder.png" />
          </div>
          <div class="xray-content" (click)="showXrayCastDetailMethod(xray)">
            <h3 class="xray-title" *ngIf="xray.name">{{ xray.name }}</h3>
            <p class="xray-para">{{ xray.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="video-cs-blk foryoutube" *ngIf="isYoutubePlayer">
  <div class="back_btn" (click)="PageBack()">
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" width="33px"
      height="33px">
      <g>
        <g>
          <path
            d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "
            fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  </div>
  <div class="movie-title title-grd">
    <h2>{{ video?.title }}</h2>
    <h4 *ngIf="video?.is_live == 1 && livestreamStarted && !geoStatus" class="viewscounter">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
      </svg>
      {{ viewersCount }}
    </h4>
    <h3 *ngIf="video && video.video_category_name">{{ video?.video_category_name }}</h3>
  </div>
  <div (mouseenter)="mouseOverXray = true" (mouseleave)="mouseOverXray = false" class="xray-contents xray-content-lists"
    *ngIf="showXray && xrayCastList.length > 0">
    <div class="xray-heading">
      <h2 (click)="xrayListClicked()">
        X-Ray
        <span class="xray-arrow">
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.847">
            <g>
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                  c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                  c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
            </g>
          </svg>
        </span>
      </h2>
    </div>
    <div class="xray-lists" *ngIf="showXrayList">
      <ul>
        <li *ngFor="let xray of xrayCastList">
          <div class="xray-image" *ngIf="xray.banner_image">
            <img src={{xray.banner_image}} alt="" [attr.src]="xray.banner_image"
              onerror="this.src='assets/images/xray_placeholder.png';" />
          </div>
          <div class="xray-image" *ngIf="!xray.banner_image">
            <img alt="ThumbnailImage" src="assets/images/xray_placeholder.png" />
          </div>
          <div class="xray-content" (click)="showXrayCastDetailMethod(xray)">
            <h3 class="xray-title" *ngIf="xray.name">{{ xray.name }}</h3>
            <p class="xray-para">{{ xray.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Next Button for Web series -->
<button (mouseenter)="mouseOverEpisode = true" (mouseleave)="mouseOverEpisode = false" class="next-video"
  (click)="nextVideo()">
  <span *ngIf="showEpisode && next_video">Next Episode
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background: new 0 0 492.004 492.004"
      xml:space="preserve">
      <g>
        <g>
          <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
              c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
              c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
              c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" />
        </g>
      </g>
    </svg>
  </span>
</button>

<div class="initimation-subscribe" id="demo" style="display: none">
  <div class="initimation-subscribe-inner">
    <p>Subscribe to watch rest of the video</p>
    <a class="org-btn wave make-subscription" ui-sref="subscribeinfo" title="subscribe now"
      (click)="goToSubscription()">subscribe now</a>
  </div>
</div>

<div class="geofence-region" id="geofence-region" *ngIf="geoStatus">
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 330 330">
    <path
      d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z
                M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z" />
    <path d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991
            c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z" />
    <path d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z
            " />
  </svg>

  <span>This video is not available in your region</span>
</div>

<div>
  <span id="online-toast" [style.display]="status == 'OFFLINE' ? 'block' : 'none'" class="online-toast">You are not
    connected to the internet</span>
</div>

<div *ngIf="isYoutubePlayer">
  <div id="poster-img" style="background-position: center;background-repeat: no-repeat;background-size: cover;"
     [ngStyle]="{background: 'url(' + video.poster_image + ')'}">
    <img src="../../../../assets/images/youtube-play.png" (click)="playYouTube();"/>
  </div>
  <div id="ytplayer"></div>
</div>