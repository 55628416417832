/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./netverse-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../modules/jw-watch-video-series/jw-watch-video-series.component.ngfactory";
import * as i3 from "../../../../modules/jw-watch-video-series/jw-watch-video-series.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./netverse-video.component";
import * as i9 from "../../../../core/services/api.service";
var styles_NetverseVideoComponent = [i0.styles];
var RenderType_NetverseVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetverseVideoComponent, data: {} });
export { RenderType_NetverseVideoComponent as RenderType_NetverseVideoComponent };
function View_NetverseVideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["onerror", "this.src='assets/images/blank_new.png';"], ["src", "assets/images/blank_new.png"]], [[1, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.video.poster_image; _ck(_v, 0, 0, currVal_0); }); }
function View_NetverseVideoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["onerror", "this.src='assets/images/blank_new.png';"], ["src", "assets/images/blank_new.png"]], null, null, null, null, null))], null, null); }
function View_NetverseVideoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "position: absolute; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-jw-watch-video-series", [["customHeight", "30%"], ["customWidth", "100%"]], null, null, null, i2.View_JwWatchVideoSeriesComponent_0, i2.RenderType_JwWatchVideoSeriesComponent)), i1.ɵdid(2, 4374528, null, 0, i3.JwWatchVideoSeriesComponent, [i4.ActivatedRoute, i4.Router, i1.Renderer2, i5.Title, i6.DOCUMENT], { video: [0, "video"], customWidth: [1, "customWidth"], customHeight: [2, "customHeight"], jwId: [3, "jwId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.video.video_trailer, ""); var currVal_1 = "100%"; var currVal_2 = "30%"; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.video.slug, ""); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_NetverseVideoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "premium-tag"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("VIDEO_CARD.PREMIUM")); _ck(_v, 1, 0, currVal_0); }); }
function View_NetverseVideoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "continue-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h6", [["class", "continue-title"], ["style", "min-height: 2.8rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.video.video_category_name; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.seasonName ? _co.seasonName : ""); var currVal_2 = (_co.video.episode_order ? ((":E" + _co.video.episode_order) + " - ") : ""); var currVal_3 = _co.video.title; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }); }
function View_NetverseVideoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "continue-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.video.video_category_name; _ck(_v, 2, 0, currVal_0); }); }
export function View_NetverseVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "full-net"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.over() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.out() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "continue-img slider-img"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_3)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_4)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "fornewbg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_5)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetverseVideoComponent_6)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.video.poster_image && !_co.showVideo); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.video.poster_image && !_co.showVideo); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.showVideo && (_co.video.is_web_series === 1)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.video == null) ? null : _co.video.is_premium) === 1); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.seasonName; _ck(_v, 12, 0, currVal_4); var currVal_5 = !_co.seasonName; _ck(_v, 14, 0, currVal_5); }, null); }
export function View_NetverseVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-netverse-video", [], null, null, null, View_NetverseVideoComponent_0, RenderType_NetverseVideoComponent)), i1.ɵdid(1, 114688, null, 0, i8.NetverseVideoComponent, [i9.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NetverseVideoComponentNgFactory = i1.ɵccf("app-netverse-video", i8.NetverseVideoComponent, View_NetverseVideoComponent_Host_0, { video: "video", Video_type: "Video_type", video_genres: "video_genres" }, {}, []);
export { NetverseVideoComponentNgFactory as NetverseVideoComponentNgFactory };
