import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResentLinkRoutingModule } from './resent-link-routing.module';
import { ResentLinkComponent } from './resent-link.component';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [ResentLinkComponent],
  imports: [
    CommonModule,
    ResentLinkRoutingModule,
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    ResentLinkComponent
  ]
})
export class ResentLinkModule { }
