import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from '../../core/services/player.service';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DailymotionApiService } from 'src/app/core/services/dailymotion.service';

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  player: any;
  params: any;
  videos: any;
  live: boolean;
  currentVideos: any;
  fullParams: any;
  liveChatStatus: Boolean = false;
  videoTitle: String;
  x: any;
  isMobile: Boolean = true;
  isMainPlayer = false;
  isYoutube = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault();
    setTimeout(() => {
      if (this.fullParams['playlist_id'] !== undefined) {
        this.router.navigate([
          'video/' +
            this.fullParams['video_id'] +
            '/' +
            this.fullParams['playlist_id'] +
            '/' +
            this.fullParams['name']
        ]);
      } else {
        this.router.navigate(['video/' + this.fullParams['video_id']]);
      }
    }, 0.1);
  }
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private dailymotionPlayerService: DailymotionApiService,
    private location: PlatformLocation,
    private router: Router,
    private el: ElementRef,
    private titleService: Title
  ) {
    this.stopHeartBeat();
    this.isMainPlayer = (this.router.url.indexOf('watch-trailer') === -1) ? true : false;
    this.route.params.subscribe((params) => {
      this.fullParams = params;
      this.params = params['video_id'];
        this.getVideos();
    });
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].removeAttribute('style');
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        document.body.classList.add('main-light');
      }
      if (localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('main-dark');
      }
    } else {
      document.body.classList.add('main-dark');
    }
  }
  ngAfterViewInit() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '#111418';
  }
  videoPlay(event) {
    this.player = event;
  }
  getVideos() {
    const url = 'v2/watchvideo/';
    this.apiService.callGetAPI(url + this.fullParams['video_id']).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.videos = data['response'];
          if (this.videos.videos.is_live === 1) {
            this.isYoutube = true;
          }
          this.currentVideos = this.videos;
          this.playerService.videoData(this.videos.videos);
          this.dailymotionPlayerService.videoData(this.videos.videos);
          this.dailymotionPlayerService.videoPaymentData(this.videos.videos);
          this.playerService.videoPaymentData(this.videos.payment_info);
          // tslint:disable-next-line:max-line-length
          const videoMetaTitle = (this.videos.video_meta_data && this.videos.video_meta_data.hasOwnProperty('title') && this.videos.video_meta_data.title) ? this.videos.video_meta_data.title : this.videos.videos.title;
          this.titleService.setTitle(
            'NETVERSE | ' + videoMetaTitle
          );
        }
        const date = new Date(this.videos.videos.scheduled_time);
        const launchDate = new Date(date).getTime();
        const now = new Date(this.videos.web_server_time).getTime();
        if (
          this.videos.videos.scheduled_time !== '' && launchDate > now
        ) {
          if (this.fullParams['playlist_id'] !== undefined) {
            this.router.navigate([
              'video/' +
                this.fullParams['video_id'] +
                '/' +
                this.fullParams['playlist_id'] +
                '/' +
                this.fullParams['name']
            ]);
          } else {
            this.router.navigate(['video/' + this.fullParams['video_id']]);
          }
        }
        if (this.videos.videos.is_premium === 1) {
          if (this.videos.videos.is_subscribed === 0) {
            if (this.fullParams['playlist_id'] !== undefined) {
              this.router.navigate([
                'video/' +
                  this.fullParams['video_id'] +
                  '/' +
                  this.fullParams['playlist_id'] +
                  '/' +
                  this.fullParams['name']
              ]);
            } else if (this.videos.payment_info.is_bought === 1) {
              return this.videos;
            } else {
              this.router.navigate(['video/' + this.fullParams['video_id']]);
            }
          }
        } else {
            this.storeVideoAnalytics();
        }
      },
      (error: any) => {
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          this.videoTitle = error.error.response.data.title;
          return;
        }

        if (this.fullParams['playlist_id'] !== undefined) {
          this.router.navigate([
            'video/' +
              this.fullParams['video_id'] +
              '/' +
              this.fullParams['playlist_id'] +
              '/' +
              this.fullParams['name']
          ]);
        }
        if (this.fullParams['video_id'] !== undefined) {
          this.router.navigate(['video/' + this.fullParams['video_id']]);
        }

        if (error.status === 404) {
          this.router.navigate(['video-not-found']);
        } else {
          this.apiService.failureResponse(error);
        }
      }
    );
  }

  PageBack() {
    this.router.navigate(['video/' + this.fullParams['video_id']]);
  }

  ngOnDestroy() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '';
  }
  // Live Chat Configuration
  /**
   *  Toggle Live chat(Open/close)
   */
  toggleLiveChat(status) {
    this.liveChatStatus = status === 'open' ? true : false;
  }
  /**
   * Get window size for configuration
   */
  getWindowSize() {
    if (window.innerWidth >= 1199) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  stopHeartBeat() {
    this.apiService
      .callPostAPI('v2/stop-heart-beat', environment.analyticsApiUrl)
      .subscribe();
  }
  /**
   * Stores the video analytics related data which includes view count, user watch history and
   * continue watching
   *
   * @returns void
   *
   */
  storeVideoAnalytics() {
    const isWatchHistoryActive = 1;
    let isContinueWatchingHistoryActive;
    let seconds;
    if (this.videos.videos.hasOwnProperty('recently_watched')) {
      // tslint:disable-next-line:max-line-length
      isContinueWatchingHistoryActive = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('continue_watching_is_active')) ? this.videos.videos.recently_watched[0].continue_watching_is_active : 0;
      // tslint:disable-next-line:max-line-length
      seconds = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('seconds')) ? this.videos.videos.recently_watched[0].seconds : 0;
    } else {
      isContinueWatchingHistoryActive = 0;
      seconds = 0;
    }
    this.apiService
    .callPostAPI(
      'v2/videoanalytics',
      { is_ended: false, video_slug: this.videos.videos.slug, isWatchHistoryActive: isWatchHistoryActive, isContinueWatchingHistoryActive: isContinueWatchingHistoryActive, seconds: seconds},
      environment.analyticsApiUrl
    )
    .subscribe();
  }
}
