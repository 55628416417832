import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCardComponent } from './components/video/video-card/video-card.component';
import { PriceCardComponent } from './components/price-card/price-card.component';
import { LoadmoreComponent } from './components/loadmore/loadmore.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { Safe } from './safeHtml';
import { TrailerCardComponent } from './components/video/trailer-card/trailer-card.component';
import { NetverseVideoComponent } from './components/video/netverse-video/netverse-video.component';

import { ViewCount } from './view-count';
import { NoVideosComponent } from './components/no-videos/no-videos.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContusSliderComponent } from '../modules/contus-slider/contus-slider.component';
import { LoaderCardComponent } from './components/video/loader-card/loader-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { CouponComponent } from './components/coupon/coupon.component';
import { StringRemoveWhitespace } from './stringremovewhitespace';
import { TimeTextFormat } from './time-text-format';
import { BannerAdComponent } from '../modules/banner-ad/banner-ad.component';
import { JwWatchVideoSeriesComponent } from '../modules/jw-watch-video-series/jw-watch-video-series.component';


@NgModule({
  imports: [
    CommonModule,
    LazyLoadImagesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [
    VideoCardComponent,
    PriceCardComponent,
    LoadmoreComponent,
    Safe,
    ViewCount,
    TrailerCardComponent,
    NetverseVideoComponent,
    StringRemoveWhitespace,
    NoVideosComponent,
    ContusSliderComponent,
    JwWatchVideoSeriesComponent,
    LoaderCardComponent,
    CouponComponent,
    TimeTextFormat,
    BannerAdComponent
  ],
  exports: [
    VideoCardComponent,
    PriceCardComponent,
    LoadmoreComponent,
    Safe,
    TrailerCardComponent,
    NetverseVideoComponent,
    ViewCount,
    NoVideosComponent,
    ContusSliderComponent,
    JwWatchVideoSeriesComponent,
    LoaderCardComponent,
    CouponComponent,
    TimeTextFormat,
    BannerAdComponent
  ]
})
export class SharedModule { }
