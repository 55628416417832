<ng-container *ngIf="banner?.data?.length">
  <app-banner [banner]="banner?.data"></app-banner>
</ng-container>

<section class="home-banner" *ngIf="!banner">
  <div class="banner-background shimmer"></div>
</section>

<div class="horizontal-ad-section" *ngIf="!isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1647923760949-0'" [adUnitPath]="'/140032985/netverse-homepage-1'" [slotSize]="[728, 90]"></app-banner-ad>
</div>

<div class="horizontal-ad-section" *ngIf="isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1695353891439-0'" [adUnitPath]="'/140032985/Responsive-Homepage-1'" [slotSize]="[320, 50]"></app-banner-ad>
</div>

<section *ngIf="new?.data?.length === 0" class="small-vplay container skeleton-loader">
  <div class="tittle-blk"></div>
  <div class="cs-slider">
    <app-contus-slider #contusSlider [id]="1" url="loadingSlider" (previousPages)="previous($event)"
      (nextPageCall)="next($event)" [total]="0" [length]="new.length" class="small-slider" [options]="{
        nav: true,
        loop: false,
        margin: 10,
        dots: false,
        stagePadding: 0,
        responsiveClass:true,
        responsive: {
          '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
          '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
          '768': { items: 3, slideBy: 3, nav: false, margion: 11 },
          '1000': { items: 5, slideBy: 4, margion: 11 },
          '1024': { items: 5, slideBy: 4, margion: 11 },
          '1200': { items: 5, slideBy: 5, margion: 11 },
          '1500': { items: 6, slideBy: 6, margion: 12 }
        }
      }">
      <app-loader-card class="item" *ngFor="let video of loadingSlider.data; let i = index"></app-loader-card>
    </app-contus-slider>
    <div class="hidden-block"></div>
  </div>
</section>

<section class="small-vplay container continue-wrap">
  <div class="small-vplay" *ngIf="mostPopular?.data?.length > 0" [@enterMainAnimation]>
      <div class="tittle-blk">
          <h2>{{ mostPopular.category_name }}</h2>
      </div>
      <div class="genre-videos" *ngIf="mostPopular?.data?.length > 0">
          <div class="cs-slider main-webseries-section">
              <app-contus-slider #contusSlider [id]="'popular_on_netprime'" url="popular_on_netprime"
                  (nextPageCall)="next($event)" [total]="mostPopular.total"
                  [length]="mostPopular.data.length" class="small-slider webseries-slider"
                  [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 12}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 6, slideBy: 6, margion: 12 }, '1500': { items: 6, slideBy: 6, margion : 12} } }">
                  <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
                    *ngFor="let video of mostPopular.data" (click)="gotoVideoDetail(video.slug)">
                    <app-netverse-video [video]="video" [Video_type]="2"></app-netverse-video>
                  </div>
              </app-contus-slider>
              <div class="hidden-block"></div>
          </div>
      </div>
  </div>
</section>



<section *ngIf="new?.data?.length > 0" class="small-vplay container">
  <div class="tittle-blk">
    <h2>{{ new.category_name }}</h2>
  </div>
  <div class="cs-slider new-videos-section latest-vid">
    <app-contus-slider [id]="new.category_name" url="new" (previousPages)="previous($event)" (nextPageCall)="next($event)"
      [total]="new.total" [length]="new.data.length" class="small-slider" [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 12}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 6, slideBy: 6, margion: 12 }, '1500': { items: 6, slideBy: 6, margion : 12} } }">
      <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
        *ngFor="let video of new.data" (click)="gotoVideoDetail(video.slug)">
        <app-netverse-video [video]="video" [Video_type]="2"></app-netverse-video>
      </div>
    </app-contus-slider>
    <div class="hidden-block"></div>
  </div>
</section>

<!-- Continue Watching -->
<section *ngIf="continueWatchingData?.length > 0" class="small-vplay container continue-wrap onlyforcontinue">
  <div class="tittle-blk">
    <h2>{{ 'HOME.CONTINUE_WATCHING' | translate }}</h2>
  </div>
  <div class="cs-slider">
    <app-contus-slider  [id]="'continue'" url="continueWatchingData" (previousPages)="previous($event)"
      (nextPageCall)="next($event)" [total]="continueWatchingData.length" [length]="continueWatchingData.length"
      class="small-slider" [options]="{
        nav: true,
        loop: false,
        margin: 10,
        dots: false,
        stagePadding: 0,
        responsiveClass:true,
        responsive: {
          '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
          '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
          '1000': { items: 5, slideBy: 4, margion: 11 },
          '1200': { items: 5, slideBy: 5, margion: 11 },
          '1500': { items: 6, slideBy: 6, margion: 12 }
        }
      }">
      <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
        *ngFor="let continueWatching of continueWatchingData" (click)="onVideoClick(continueWatching.video)">
        <div class="continue-img slider-img">
          <img *ngIf="continueWatching.video.poster_image" src="assets/images/blank_new.png" alt="" [attr.src]="continueWatching.video.poster_image"
            onerror="this.src='assets/images/blank_new.png';" />
          <img *ngIf="!continueWatching.video.poster_image" src="assets/images/blank_new.png" alt="" onerror="this.src='assets/images/blank_new.png';" />
          <span class="continue-bar" [ngStyle]="{ 'width.%': continueWatching.progress_percentage }"></span>
        </div>
        <div class="fornewbg">
          <h3 class="continue-title" >{{ continueWatching.video.title }}</h3>
          <h6 class="continue-title" *ngIf="continueWatching.video.season_name">{{ (continueWatching.video.season_name) ? "S" + (continueWatching.video.season_name.substring(continueWatching.video.season_name.indexOf(' ') + 1)) : "" }}{{ (continueWatching.video.episode_order) ? ":E" + continueWatching.video.episode_order + " - " : "" }} {{ continueWatching.video.title }}</h6>
        </div>
      </div>
    </app-contus-slider>
    <div class="hidden-block"></div>
  </div>
</section>
<!-- Continue Watching -->

<!-- For you section start -->
<section *ngIf="forYou?.data?.length > 0" class="small-vplay container">
  <div class="tittle-blk">
    <h2>{{ forYou.category_name }} </h2>
  </div>
  <div class="genre-videos" *ngIf="forYou?.data?.length > 0">
    <div class="cs-slider main-webseries-section">
        <app-contus-slider #contusSlider [id]="'for_you'" url="for_you"
            (nextPageCall)="next($event)" [total]="forYou.total"
            [length]="forYou.data.length" class="small-slider webseries-slider"
            [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
            <app-trailer-card class="item" [category]="'main-webseries'" [video_type]="'2'" [video]="video"
                [class.mouse-hovered]="mouseOvered"
                (mouseover)="handleLastItemOnHover($event, i, 'main-webseries-section')"
                (mouseout)="handleLastItemOnHover($event, i, 'main-webseries-section')"
                [type]="'webseries'" *ngFor="let video of forYou.data; let i=index;"
                [class.row-first-item]="i == sliderMovedData - sliderRowItem"
                [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i"
                [attr.data-moved]="sliderMovedData">
            </app-trailer-card>

        </app-contus-slider>
        <div class="hidden-block"></div>
    </div>
</div>
</section>
<!-- For you section end -->

<!-- My list section start -->
<section *ngIf="myList?.data?.length > 0" class="small-vplay container mylists">
  <div class="tittle-blk">
    <h2>{{ myList.category_name }}</h2>
  </div>

  <div class="cs-slider">
      <app-contus-slider [id]="'my_list'" url="my_list" (previousPages)="previous($event)"
        (nextPageCall)="next($event)" [total]="myList?.total" [length]="myList?.data?.length"
        class="small-slider" [options]="{
          nav: true,
          loop: false,
          margin: 10,
          dots: false,
          stagePadding: 0,
          responsiveClass:true,
          responsive: {
            '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
            '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
            '1000': { items: 5, slideBy: 4, margion: 11 },
            '1200': { items: 5, slideBy: 5, margion: 11 },
            '1500': { items: 6, slideBy: 6, margion: 12 }
          }
        }">
        <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
          *ngFor="let video of myList.data" (click)="gotoVideoDetail(video.slug)">
          <app-netverse-video [video]="video" [Video_type]="2"></app-netverse-video>
        </div>
      </app-contus-slider>
      <div class="hidden-block"></div>
    </div>

  <!-- <div class="my-playlist-container">
    <ul class="my-playlist-ul">
      <li class="my-playlist-list" *ngFor="let playlist of myList['data'];let i=index">
        <div class="my-playlist-head" (click)="goToPlaylistDetail(playlist._id, playlist.name)">
          <div class="my-playlist-left">
            <span class="my-playlist-thumb"
              [ngStyle]="{'background-image': 'url('+ playlist.poster_image +')'}"></span>
          </div>
          <div class="my-playlist-right">
            <span class="playlist-video-count">{{playlist.video_count}}</span>
            <svg version="1.1" x="0px" y="0px" viewBox="-0.979 -0.345 30 30">
              <defs>
              </defs>
              <path
                d="M27.062,6.692H0.978C0.441,6.692,0,7.133,0,7.669v20.665c0,0.537,0.441,0.977,0.978,0.977h26.086  c0.535,0,0.979-0.438,0.979-0.977V7.669C28.041,7.133,27.599,6.692,27.062,6.692z M26.085,27.355H1.955V8.646h24.13V27.355z   M9.771,23.74c0.146,0.098,0.342,0.148,0.488,0.148s0.293-0.052,0.44-0.099l9.427-4.888c0.342-0.146,0.537-0.488,0.537-0.877  c0-0.393-0.195-0.685-0.537-0.879l-9.378-4.937c-0.293-0.146-0.684-0.146-0.977,0.051c-0.292,0.193-0.489,0.486-0.489,0.828v9.82  C9.282,23.254,9.478,23.547,9.771,23.74z M11.235,14.704l6.304,3.272l-6.304,3.272V14.704z M2.395,4.349  c0-0.538,0.439-0.978,0.977-0.978h21.297c0.537,0,0.977,0.439,0.977,0.978c0,0.537-0.438,0.977-0.977,0.977H3.372  C2.833,5.325,2.395,4.886,2.395,4.349z M4.3,0.977C4.3,0.44,4.739,0,5.276,0h17.485C23.3,0,23.74,0.44,23.74,0.977  c0,0.538-0.438,0.977-0.979,0.977H5.276C4.739,1.953,4.3,1.515,4.3,0.977z" />
            </svg>
          </div>
        </div>
        <div class="my-playlist-detail">
          <h3 (click)="goToPlaylistDetail(playlist._id, playlist.name)">{{ playlist.name }}</h3>
        </div>
      </li>
    </ul>
  </div> -->
</section>
<!-- My list section end -->

<div class="horizontal-ad-section" *ngIf="!isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1647923920335-0'" [adUnitPath]="'/140032985/netverse-homepage-2'" [slotSize]="[728, 90]"></app-banner-ad>
</div>

<div class="horizontal-ad-section" *ngIf="isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1695354241069-0'" [adUnitPath]="'/140032985/Responsive-Homepage-2'" [slotSize]="[320, 50]"></app-banner-ad>
</div>

<div class="mid-banners" [ngStyle]="{'background-image': 'url('+ promotionalAds.web_background_image +')', 'z-index': '999'}" *ngIf="promotionalAds.data">
  <img src="../../../assets/images/adbgs.png" class="for-clicking" routerLink="/adsdetail"/>
  <div class="for-logos">
    <img src="{{ promotionalAds.web_logo }}"/>
  </div>
  <div class="for-caro">
    <h2>{{ promotionalAds.title }}</h2>
    <section class="small-vplay container continue-wrap">
      <div class="small-vplay" *ngIf="promotionalAds?.data?.length > 0" [@enterMainAnimation]>
          <div class="genre-videos" *ngIf="promotionalAds?.data?.length > 0">
              <div class="cs-slider main-webseries-section">
                  <app-contus-slider #contusSlider [id]="'promotional_ads'" url="promotional_ads"
                      (nextPageCall)="next($event)" [total]="promotionalAds.total"
                      [length]="promotionalAds.data.length" class="small-slider webseries-slider"
                      [options]="
                      {
                        nav : true, 
                        loop:false,
                        margin: 10, 
                        dots: false, 
                        stagePadding: 20, 
                        responsive: { 
                          '0': { items: 1, slideBy: 1, nav : false, margion : 3 }, 
                          '600': { items: 1, slideBy: 1, nav : false, margion : 3 }, 
                          '1000': { items: 3, slideBy: 3, margion : 3 }, 
                          '1024': { items: 3, slideBy: 3, margion: 3 }, 
                          '1200': { items: 3, slideBy: 3, margion: 3 }, 
                          '1500': { items: 3, slideBy: 3, margion : 3 } 
                        } 
                      }">
                      <div class="continuew-card-list continue-watching-slider-item-selector slider-item for-ads"
                        *ngFor="let video of promotionalAds.data" (click)="gotoVideoDetail(video.slug)">
                        <app-netverse-video [video]="video" [Video_type]="2"></app-netverse-video>
                      </div>
                  </app-contus-slider>
                  <div class="hidden-block"></div>
              </div>
          </div>
      </div>
    </section>
  </div>
</div>


<!-- web series list -->
<section *ngIf="webseries?.data?.length > 0" class="small-vplay container">
  <div class="tittle-blk">
    <h2>{{ webseries.category_name }}</h2>
  </div>
  <div class="cs-slider">
    <app-contus-slider [id]="webseries.category_name" url="webseries" (previousPages)="previous($event)" (nextPageCall)="next($event)"
      [total]="webseries.total" [length]="webseries.data.length" class="small-slider" [options]="{
        nav: true,
        loop: false,
        margin: 10,
        dots: false,
        stagePadding: 0,
        responsiveClass:true,
        responsive: {
          '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
          '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
          '1000': { items: 5, slideBy: 4, margion: 11 },
          '1024': { items: 5, slideBy: 4, margion: 11 },
          '1200': { items: 5, slideBy: 5, margion: 11 },
          '1500': { items: 6, slideBy: 6, margion: 12 }
        }
      }">
      <app-video-card (dragEvent)="next($event)" [video]="video" [type]="'webseries'" class="item"
        *ngFor="let video of webseries.data; let i = index" (favouriteChange)="updateFavourite($event, webseries.data)">
      </app-video-card>
    </app-contus-slider>
    <div class="hidden-block"></div>
  </div>
</section>
<!-- End / web series list -->

<section *ngIf="categoriesVideos.length === 0" class="small-vplay container">
  <div class="tittle-blk"></div>
  <div class="cs-slider popular-video-section">
    <app-contus-slider #contusSlider [id]="1" url="loadingSlider" (previousPages)="previous($event)"
      (nextPageCall)="next($event)" [total]="0" [length]="categoriesVideos.length" class="small-slider" [options]="{
        nav: true,
        loop: false,
        margin: 10,
        dots: false,
        stagePadding: 0,
        responsiveClass:true,
        responsive: {
          '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
          '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
          '1000': { items: 5, slideBy: 4, margion: 11 },
          '1024': { items: 5, slideBy: 4, margion: 11 },
          '1200': { items: 5, slideBy: 5, margion: 11 },
          '1500': { items: 6, slideBy: 6, margion: 12 }
        }
      }">
      <app-trailer-card [blockType]="'popular'" [class.row-first-item]="i == sliderMovedData - sliderRowItem"
        [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i" [attr.data-moved]="sliderMovedData"
        [video]="video" [type]="'new'" class="item" *ngFor="let video of loadingSlider.data; let i = index"
        (favouriteChange)="updateFavourite($event, loadingSlider.data)"
        (mouseover)="handleLastItemOnHover($event, i, 'popular-video-section')"
        (mouseout)="handleLastItemOnHover($event, i, 'popular-video-section')"></app-trailer-card>
    </app-contus-slider>
    <div class="hidden-block"></div>
  </div>
</section>
<ng-container *ngIf="categoriesVideos?.length > 0">
  <ng-container *ngFor="let category of categoriesVideos; let i = index; let PI = index;">
    <section *ngIf="category?.data?.length > 0" class="small-vplay container">
      <div class="tittle-blk">
        <h2>{{ category.category_name }}  </h2>
      </div>
      <!--Start of webseries part-->
      <div *ngIf="category.category_type =='2'" class="cs-slider category-section-{{PI}}">
        <app-contus-slider [id]="i + 2 + 1" url="new" (previousPages)="previous($event)"
          (nextPageCall)="sectionNextSeries(category.category_slug, i)" [total]="category.total" [length]="category.data.length"
          class="small-slider" [options]="{
            nav: true,
            loop: false,
            margin: 10,
            dots: false,
            stagePadding: 0,
            responsiveClass:true,
            responsive: {
              '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
              '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
              '1000': { items: 5, slideBy: 4, margion: 11 },
              '1024': { items: 5, slideBy: 4, margion: 11 },
              '1200': { items: 5, slideBy: 5, margion: 11 },
              '1500': { items: 6, slideBy: 6, margion: 12 }
            }
          }">
          <app-trailer-card [video_type]="'2'" [blockType]="category.category_name"
            [class.row-first-item]="i == sliderMovedData - sliderRowItem"
            [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i" [attr.data-moved]="sliderMovedData"
            (dragEvent)="next($event)" [video]="video" [type]="'webseries'" class="item"
            *ngFor="let video of category.data; let i = index"
            (favouriteChange)="updateFavourite($event, category.data)"
            (mouseover)="handleLastItemOnHover($event, i, 'category-section-' + PI)"
            (mouseout)="handleLastItemOnHover($event, i, 'category-section-' + PI)">
          </app-trailer-card>
        </app-contus-slider>
        <div class="hidden-block"></div>
      </div>
      <!--End of webseries part-->

      <!--Start of Single video part-->
      <div  *ngIf="category.category_type =='1'" class="cs-slider category-section-{{PI}}">
        <app-contus-slider [id]="i + 2 + 1" url="new" (previousPages)="previous($event)"
          (nextPageCall)="sectionNexts(category.category_slug, i)" [total]="category.total" [length]="category.data.length"
          class="small-slider" [options]="{
            nav: true,
            loop: false,
            margin: 10,
            dots: false,
            stagePadding: 0,
            responsiveClass:true,
            responsive: {
              '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
              '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
              '1000': { items: 5, slideBy: 4, margion: 11 },
              '1024': { items: 5, slideBy: 4, margion: 11 },
              '1200': { items: 5, slideBy: 5, margion: 11 },
              '1500': { items: 6, slideBy: 6, margion: 12 }
            }
          }">
          <app-trailer-card [video_type]="'1'" [blockType]="category.category_name"
            [class.row-first-item]="i == sliderMovedData - sliderRowItem"
            [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i" [attr.data-moved]="sliderMovedData"
            (dragEvent)="next($event)" [video]="video" [type]="'webseries'" class="item"
            *ngFor="let video of category.data; let i = index"
            (favouriteChange)="updateFavourite($event, category.data)"
            (mouseover)="handleLastItemOnHover($event, i, 'category-section-' + PI)"
            (mouseout)="handleLastItemOnHover($event, i, 'category-section-' + PI)">
          </app-trailer-card>
        </app-contus-slider>
        <div class="hidden-block"></div>
      </div>
      <!-- <div *ngIf="category.category_type =='1'"  class="cs-slider category-section-{{PI}}">
        <app-contus-slider [id]="i + 2 + 1" url="new" (previousPages)="previous($event)" (nextPageCall)="sectionNexts(category.category_slug, i)"
          [total]="category.total" [length]="category.data.length" class="small-slider" [options]="{
            nav: true,
            loop: false,
            margin: 10,
            dots: false,
            stagePadding: 0,
            responsiveClass:true,
            responsive: {
              '0': { items: 2, slideBy: 2, nav: false, margion: 10 },
              '600': { items: 3, slideBy: 3, nav: false, margion: 11 },
              '1000': { items: 5, slideBy: 4, margion: 11 },
              '1024': { items: 5, slideBy: 4, margion: 11 },
              '1200': { items: 5, slideBy: 5, margion: 11 },
              '1500': { items: 6, slideBy: 6, margion: 12 }
            }
          }">
          <div class="continuew-card-list continue-watching-slider-item-selector slider-item"
            *ngFor="let video of category.data" (click)="gotoVideoDetail(video.slug)">
            <app-netverse-video [video]="video" [Video_type]="1"></app-netverse-video>
          </div>
        </app-contus-slider>
        <div class="hidden-block"></div>
      </div> -->
       <!--End of Single video part-->

    </section>
  </ng-container>
</ng-container>

<button class="genre-load-more" *ngIf="renderedSections < totalSections" (click)="getMoreGenres()">{{ 'HOME.LOAD_MORE' | translate }}</button>

<div class="horizontal-ad-section bottom-ad" *ngIf="!isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1674207714437-0'" [adUnitPath]="'/140032985/Web-Homepage-3'" [slotSize]="[728, 90]"></app-banner-ad>
</div>

<!-- <div class="horizontal-ad-section bottom-ad" *ngIf="isMobile">
  <app-banner-ad [id]="'div-gpt-ad-1695354352891-0'" [adUnitPath]="'/140032985/Responsive-Homepage-3'" [slotSize]="[320, 50]"></app-banner-ad>
</div> -->

<section class="app-banner" *ngIf="load">
  <div class="container">
    <h2>{{ 'HOME.WATCH_TV_ANYWHERE_ANT_DEVICE_ANYTIME' | translate }}</h2>
    <p>
      {{
        'HOME.WATCH_YOUR_DAILY_DOES_OF_ENTERTAIMENT_ANYTIME_YOU_WANT_IT_ON_OUR_ANDROID_AND_IOS_APPS'
          | translate
      }}
    </p>
  </div>

</section>
