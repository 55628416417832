<div class="login-signup-modal modal" style="display: block;">
  <div class="overlay fadeIn animated"></div>

  <div class="modal-body zoomIn animated wow">

    <div class="login-modal" style="display: block;">
      <div class="header">
        <h4>{{ 'SIGN_UP.SIGN_UP' | translate }}</h4>
        <svg (click)="onClose()" class="close" viewBox="0 0 15 14" style="background-color:#ffffff00" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px"
          width="15px" height="14px">
          <g class="Layer%201">
            <path id="Forma%201" d="M 8.7347 7.1472 L 14.1725 1.7849 C 14.5104 1.452 14.5104 0.9119 14.1725 0.5791 C 13.8347 0.2459 13.2876 0.2459 12.9497 0.5791 L 7.512 5.9413 L 2.0739 0.5791 C 1.736 0.2459 1.189 0.2459 0.8511 0.5791 C 0.5132 0.9119 0.5132 1.452 0.8511 1.7849 L 6.2891 7.1472 L 0.8511 12.5095 C 0.5132 12.8424 0.5132 13.3824 0.8511 13.7153 C 1.02 13.882 1.2413 13.9653 1.4625 13.9653 C 1.6836 13.9653 1.9049 13.882 2.0739 13.7153 L 7.512 8.353 L 12.9497 13.7153 C 13.1187 13.882 13.3401 13.9653 13.5612 13.9653 C 13.7822 13.9653 14.0037 13.882 14.1725 13.7153 C 14.5104 13.3824 14.5104 12.8424 14.1725 12.5095 L 8.7347 7.1472 Z"
              fill="#ffffff"></path>
          </g>
        </svg>
      </div>
      <div class="modal-content">
        <app-social-login></app-social-login>
        <div class="or large">
          <span>{{ 'SIGN_UP.OR' | translate }}</span>
        </div>
        <form #signupForm="ngForm" (ngSubmit)="signupForm.form.valid;onSignUp(signupForm)" novalidate>
          <div class="signup-content">
            <div class="left-side">
              <div class="form-control">
                <input type="text" class="input-data" required minlength="3" maxlength="254" [ngClass]="{ 'label-top' : pickedName.value }"
                  (focus)="onChange($event)" [(ngModel)]="firstName" name="firstName" #pickedName="ngModel">
                <div class="error" *ngIf="pickedName.errors?.required && (touched || pickedName?.touched)">
                  {{ 'SIGN_UP.NAME_REQUIRED' | translate }}
                </div>
                <div class="error" *ngIf="pickedName.errors?.minlength && touched">
                  {{ 'SIGN_UP.NAME_MIN' | translate }}
                </div>
                <span class="form-text error" *ngIf="error?.name">
                  {{error.name}}
                </span>
                <label>{{ 'SIGN_UP.NAME_PLACEHOLDER' | translate }}</label>
              </div>

              <div class="form-control">
                <input pattern=".+@.+\..+" class="input-data" name="email" type="email" required [ngClass]="{ 'label-top' : userEmail.value }"
                  (focus)="onChange($event)" [(ngModel)]="email" #userEmail="ngModel">
                <div class="error" *ngIf="userEmail.errors?.pattern && touched">
                  {{ 'SIGN_UP.EMAIL_VALID_ERROR' | translate }}
                </div>
                <div class="error" *ngIf="userEmail.errors?.required && (touched || userEmail?.touched)">
                  {{ 'SIGN_UP.EMAIL_REQUIRED_ERROR' | translate }}
                </div>
                <span class="form-text error" *ngIf="error?.email">
                  {{error.email}}
                </span>
                <label>{{ 'SIGN_UP.EMAIL_PLACEHOLDER' | translate }}</label>
              </div>

              <div class="form-control">
                <div class="passw-field">
                  <input [attr.type]="show?'text':'password'" class="input-data" [ngClass]="{ 'label-top' : userPassword.value }"
                    (focus)="onChange($event)" required minlength="6" maxlength="16" [(ngModel)]="password" name="password"
                    #userPassword="ngModel">
                  <label>{{ 'SIGN_UP.PASSWORD_PLACEHOLDER' | translate }}</label>
                  <button type="button" id="eye" (click)="toggleShowPassword()">
                    <div class="fa-eye" [ngClass]="{'' : !show, 'open': show }">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" *ngIf="!show" class="closed-eye" width="15px" height="15px" viewBox="0 0 59.049 59.049"
                        style="enable-background:new 0 0 59.049 59.049;" xml:space="preserve">
                        <g>
                          <path d="M11.285,41.39c0.184,0.146,0.404,0.218,0.623,0.218c0.294,0,0.585-0.129,0.783-0.377c0.344-0.432,0.273-1.061-0.159-1.405
                                  c-0.801-0.638-1.577-1.331-2.305-2.06l-7.398-7.398l7.629-7.629c7.334-7.333,18.003-9.836,27.839-6.534
                                  c0.523,0.173,1.09-0.107,1.267-0.63c0.175-0.523-0.106-1.091-0.63-1.267c-10.562-3.545-22.016-0.857-29.89,7.016L0,30.368
                                  l8.812,8.812C9.593,39.962,10.426,40.705,11.285,41.39z" />
                          <path d="M50.237,21.325c-1.348-1.348-2.826-2.564-4.394-3.616c-0.458-0.307-1.081-0.185-1.388,0.273
                                  c-0.308,0.458-0.185,1.08,0.273,1.388c1.46,0.979,2.838,2.113,4.094,3.369l7.398,7.398l-7.629,7.629
                                  c-7.385,7.385-18.513,9.882-28.352,6.356c-0.52-0.187-1.093,0.084-1.279,0.604c-0.186,0.52,0.084,1.092,0.604,1.279
                                  c3.182,1.14,6.49,1.693,9.776,1.693c7.621,0,15.124-2.977,20.665-8.518l9.043-9.043L50.237,21.325z" />
                          <path d="M30.539,41.774c-2.153,0-4.251-0.598-6.07-1.73c-0.467-0.29-1.084-0.148-1.377,0.321c-0.292,0.469-0.148,1.085,0.321,1.377
                                  c2.135,1.33,4.6,2.032,7.126,2.032c7.444,0,13.5-6.056,13.5-13.5c0-2.685-0.787-5.279-2.275-7.502
                                  c-0.308-0.459-0.93-0.582-1.387-0.275c-0.459,0.308-0.582,0.929-0.275,1.387c1.267,1.893,1.937,4.102,1.937,6.39
                                  C42.039,36.616,36.88,41.774,30.539,41.774z" />
                          <path d="M30.539,18.774c2.065,0,4.089,0.553,5.855,1.6c0.474,0.281,1.088,0.125,1.37-0.351c0.281-0.475,0.125-1.088-0.351-1.37
                                  c-2.074-1.229-4.451-1.879-6.875-1.879c-7.444,0-13.5,6.056-13.5,13.5c0,2.084,0.462,4.083,1.374,5.941
                                  c0.174,0.354,0.529,0.56,0.899,0.56c0.147,0,0.298-0.033,0.439-0.102c0.496-0.244,0.701-0.843,0.458-1.338
                                  c-0.776-1.582-1.17-3.284-1.17-5.06C19.039,23.933,24.198,18.774,30.539,18.774z" />
                          <path d="M54.621,5.567c-0.391-0.391-1.023-0.391-1.414,0l-46.5,46.5c-0.391,0.391-0.391,1.023,0,1.414
                                  c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l46.5-46.5C55.012,6.591,55.012,5.958,54.621,5.567z" />
                        </g>

                      </svg>

                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" *ngIf="show" class="opened-eye" viewBox="0 0 59.2 59.2" width="15px" height="15px"
                        style="enable-background:new 0 0 59.2 59.2;" xml:space="preserve">
                        <g>
                          <path d="M51.062,21.561c-11.889-11.889-31.232-11.889-43.121,0L0,29.501l8.138,8.138c5.944,5.944,13.752,8.917,21.561,8.917
                                  s15.616-2.972,21.561-8.917l7.941-7.941L51.062,21.561z M49.845,36.225c-11.109,11.108-29.184,11.108-40.293,0l-6.724-6.724
                                  l6.527-6.527c11.109-11.108,29.184-11.108,40.293,0l6.724,6.724L49.845,36.225z" />
                          <path d="M28.572,21.57c-3.86,0-7,3.14-7,7c0,0.552,0.448,1,1,1s1-0.448,1-1c0-2.757,2.243-5,5-5c0.552,0,1-0.448,1-1
                                  S29.125,21.57,28.572,21.57z" />
                          <path d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,40.57
                                  c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S35.638,40.57,29.572,40.57z" />
                        </g>

                      </svg>
                    </div>
                  </button>
                </div>
                <div class="error" *ngIf="userPassword.errors?.required && (touched || userPassword?.touched)">
                  {{ 'SIGN_UP.PASSWORD_REQUIRED_ERROR' | translate }}
                </div>
                <div class="error" *ngIf="userPassword.errors?.minlength || userPassword.errors?.maxlength && touched">
                  {{ 'SIGN_UP.PASSWORD_LENGTH_ERROR' | translate }}
                </div>
                <span class="form-text error" *ngIf="error?.password">
                  {{error.password}}
                </span>
              </div>

              <div class="form-control">
                <select type="text" class="input-data" required minlength="3" 
                (focus)="onChange($event)" [(ngModel)]="selectGender" name="selectGender" #gender="ngModel" required>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <div class="error" *ngIf="gender.errors?.required && (touched || gender?.touched)">
                  {{ 'SIGN_UP.GENDER_REQUIRED' | translate }}
                </div>
                <span class="form-text error" *ngIf="error?.gender">
                  {{error.gender}}
                </span>
                <label style="top: 0 !important; z-index: 1!important;">{{ 'SIGN_UP.GENDER' | translate }}</label>
              </div>

              <div class="form-control">
                <div class="date-of-birth">
                  <ng-datepicker name="dob" [options]="options" class="dob" autocomplete="off" (click)="onChange($event)"
                    [(ngModel)]="dob" #dobVal="ngModel" required>
                  </ng-datepicker>
                  <div class="error" *ngIf="dobVal.errors?.required && (touched || dobVal?.touched)">
                    {{ 'SIGN_UP.D_O_B_REQUIRED_ERROR' | translate }}
                  </div>
                  <div class="form-text error" *ngIf="error?.dobVal && datepick">
                    DOB is invalid</div>
                  <div class="form-text error" *ngIf="datepick">{{ 'SIGN_UP.D_O_B_AFE_ERROR' | translate }}</div>
                  <label style="top: 0 !important; z-index: 10!important;">{{ 'SIGN_UP.D_O_B' | translate }}</label>
                </div>
              </div>

              <button type="submit" [disabled]="submitted" class="signin">{{ title }}</button>

              <div class="or">
                <span>{{ 'SIGN_UP.OR' | translate }}</span>
              </div>

              <p class="dont-have">
                {{ 'SIGN_UP.MEMBER' | translate }}
                <a title="Sign In" href="javascript:void(0)" oncontextmenu="return false" class="login-link" (click)="onClick('sign-in')">{{
                  'SIGN_UP.SIGN_IN' | translate }}</a>
              </p>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
