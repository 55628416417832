/**
 * Method to validate video duration whether in the formats like '00:00:00' ,'00:00'
 * @param any event
 * @param array video
 */
const VideoDurationValidation = (input) => {
    if (input) {
        const splitInput = input.split(':');
        let result;
        if (parseInt(splitInput[0], 10) > 0) {
            result = (splitInput[0].substring(0, 1) !== '0') ? splitInput[0] + ' hr ' : splitInput[0].substring(1, 2) + ' hr ';
        }
        else {
            result = '';
        }
        if (parseInt(splitInput[1], 10) > 0) {
            result += (splitInput[1].substring(0, 1) !== '0') ? splitInput[1] + ' m ' : splitInput[1].substring(1, 2) + ' m ';
        }
        if (parseInt(splitInput[2], 10) > 0) {
            result += (splitInput[2].substring(0, 1) !== '0') ? splitInput[2] + ' s' : splitInput[2].substring(1, 2) + ' s';
        }
        return result;
    }
};
const ɵ0 = VideoDurationValidation;
export { VideoDurationValidation };
export { ɵ0 };
