import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as cryptoJS from 'crypto-js';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
import * as i4 from "../services/user.service";
import * as i5 from "@ngx-translate/core";
export class AuthService {
    constructor(http, router, toasterService, userService, translate) {
        this.http = http;
        this.router = router;
        this.toasterService = toasterService;
        this.userService = userService;
        this.translate = translate;
        this.getUniqueBrowserValue().then((data) => {
            this.deviceId = data.deviceId;
            this.operatingSystem = data.platform;
        });
        this.browserName = this.getBrowserName();
    }
    login(data) {
        this.apiHeaders = new HttpHeaders();
        this.apiHeaders.headers['Accept'] = 'application/json';
        this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
        this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
        this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
            ? this.operatingSystem
            : '';
        this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';
        this.apiHeaders.headers['X-LANGUAGE-CODE'] = localStorage.getItem('currentLanguage')
            ? localStorage.getItem('currentLanguage')
            : 'en';
        const provider = data.provider === 'NORMAL'
            ? 'normal'
            : data.provider === 'FACEBOOK'
                ? 'fb'
                : 'google+';
        const authToken = data.provider === 'NORMAL' ? '' : data.authToken;
        const socialUserId = data.provider === 'NORMAL' ? '' : data.id;
        return this.http
            .post(environment.userApiUrl + 'v2/auth/login', {
            login_type: provider,
            acesstype: 'web',
            email: data.email,
            password: data.password ? data.password : '',
            social_user_id: socialUserId,
            token: authToken,
            name: data.name ? data.name : '',
            profile_picture: data.photoUrl ? data.photoUrl : ''
        }, this.apiHeaders)
            .pipe(map((user) => {
            if (user['statusCode'] === 200 && user['error'] === false) {
                if (data.provider !== 'NORMAL') {
                    if (user['access_token']) {
                        user.response.name = data.name;
                        const userData = this.encryption(user);
                        this.userService.userData(userData);
                        localStorage.removeItem('GUVL');
                        this.router.navigate(['/']);
                        return user;
                    }
                    else {
                        if (user['message'] === 'We have sent an activation link to your registered email. Please open it up to activate your account' || user['message'] === 'Kami telah mengirimkan link aktivasi ke alamat email Kamu. Mohon periksa email Kamu') {
                            this.onClick('active');
                            return;
                        }
                        this.toasterService.success('', user['message']);
                        this.userService.displayPopup('colse');
                    }
                }
                else {
                    this.toasterService.success('', user['message']);
                    this.userService.displayPopup('colse');
                }
                const userData = this.encryption(user);
                this.userService.userData(userData);
                localStorage.removeItem('GUVL');
                this.router.navigate(['/']);
            }
            return user;
        }));
    }
    logout(showToaster = true) {
        const authorization = this.decryption();
        this.apiHeaders = new HttpHeaders();
        this.apiHeaders.headers['Accept'] = 'application/json';
        this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
        this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
        this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
            ? this.operatingSystem
            : '';
        this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';
        if (authorization.hasOwnProperty('response')) {
            this.apiHeaders.headers['Authorization'] =
                'Bearer ' + authorization.response.access_token;
        }
        return this.http
            .post(environment.userApiUrl + 'v2/auth/logout', {}, this.apiHeaders)
            .subscribe(() => {
            this.logoutCallBack();
        }, (error) => {
            this.logoutCallBack();
        });
    }
    logoutCallBack() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.userService.userData('');
        this.router.navigate(['/']);
        let getMessage;
        this.translate.get('LOGOUT.LOGOUT_MESSAGE').subscribe((res) => {
            getMessage = res;
        });
        this.toasterService.success('', getMessage);
        if (this.apiHeaders.headers.hasOwnProperty('Authorization')) {
            delete this.apiHeaders.headers['Authorization'];
        }
    }
    encryption(user) {
        return cryptoJS.AES.encrypt(JSON.stringify(user), environment.encryption_key);
    }
    decryption(data = null) {
        const currentUser = data === null ? localStorage.getItem('currentUser') : data;
        let decryptUser = {};
        if (currentUser) {
            decryptUser = cryptoJS.AES.decrypt(currentUser.toString(), environment.encryption_key);
            decryptUser = JSON.parse(decryptUser.toString(cryptoJS.enc.Utf8));
        }
        return decryptUser;
    }
    getUniqueBrowserValue() {
        return new Promise((res) => {
            if (localStorage.getItem('device-id')) {
                res({
                    deviceId: localStorage.getItem('device-id'),
                    platform: localStorage.getItem('platform')
                });
            }
            Fingerprint2.get(function (components) {
                const values = components.map(function (component) {
                    return component.value;
                });
                const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
                const platform = components[16].value;
                localStorage.setItem('device-id', deviceId);
                localStorage.setItem('platform', components[16].value);
                res({ deviceId, platform });
            });
        });
    }
    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1 || agent.indexOf('Edg') > -1 || agent.indexOf('edg') > -1:
                return 'Edge';
            case agent.indexOf('opr') > -1 && !!window.opr:
                return 'Opera';
            case agent.indexOf('chrome') > -1 && !!window.chrome:
                return 'Chrome';
            case agent.indexOf('trident') > -1:
                return 'Internet Explore';
            case agent.indexOf('firefox') > -1:
                return 'Firefox';
            case agent.indexOf('safari') > -1:
                return 'Safari';
            default:
                return 'Other';
        }
    }
    onClick(value) {
        if (value === 'active') {
            this.userService.displayPopup('active');
        }
        if (value === 'sign-in') {
            this.userService.displayPopup('sign-in');
        }
    }
}
AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.ToastrService), i0.inject(i4.UserService), i0.inject(i5.TranslateService)); }, token: AuthService, providedIn: "root" });
