<div class="login-signup-modal modal" style="display: block;">
  <div class="overlay fadeIn animated"></div>

  <div class="modal-body zoomIn animated wow">
    <div class="forgot-password-modal" style="display: block;">
      <div class="header">
        <h4>{{ 'FORGET_PASSWORD.TITLE' | translate }}</h4>
        <svg (click)="onClose()" class="close" viewBox="0 0 15 14" style="background-color:#ffffff00" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px"
          y="0px" width="15px" height="14px">
          <g class="Layer%201">
            <path id="Forma%201"
              d="M 8.7347 7.1472 L 14.1725 1.7849 C 14.5104 1.452 14.5104 0.9119 14.1725 0.5791 C 13.8347 0.2459 13.2876 0.2459 12.9497 0.5791 L 7.512 5.9413 L 2.0739 0.5791 C 1.736 0.2459 1.189 0.2459 0.8511 0.5791 C 0.5132 0.9119 0.5132 1.452 0.8511 1.7849 L 6.2891 7.1472 L 0.8511 12.5095 C 0.5132 12.8424 0.5132 13.3824 0.8511 13.7153 C 1.02 13.882 1.2413 13.9653 1.4625 13.9653 C 1.6836 13.9653 1.9049 13.882 2.0739 13.7153 L 7.512 8.353 L 12.9497 13.7153 C 13.1187 13.882 13.3401 13.9653 13.5612 13.9653 C 13.7822 13.9653 14.0037 13.882 14.1725 13.7153 C 14.5104 13.3824 14.5104 12.8424 14.1725 12.5095 L 8.7347 7.1472 Z"
              fill="#ffffff"></path>
          </g>
        </svg>
      </div>

      <div class="modal-content">
        <p>
          {{ 'FORGET_PASSWORD.ENTER_YOUR_REGISTERED_EMAIL_ADDRESS_BELOW_AND_WE_WILL_SEND_YOU_INSTRUCTIONS_ON_RESETTING_YOUR_PASSWORD' | translate }}
        </p>
        <form (ngSubmit)="form.form.valid && forgotPassword()" #form="ngForm" autocomplete="nope">
          <div class="form-control">
            <input name="email" type="email" [(ngModel)]="user.email" (change)="onChange()" id="input-email"
              class="form-control" placeholder="{{ 'FORGET_PASSWORD.EMAIL_PLACEHOLDER' | translate }}" #email="ngModel"
              [class.form-control-danger]="email.invalid && email.touched" required [minlength]="emailMinLength"
              [maxlength]="emailMaxLength" pattern=".+@.+\..+">

            <span class="form-text error"
              *ngIf="!error?.email && email.invalid && email.touched && email.errors?.required">
              {{ 'FORGET_PASSWORD.EMAIL_VALID_ERROR_REQUIRED' | translate }}
            </span>
            <span class="form-text error" *ngIf="!error?.email && email.errors?.pattern">
              {{ 'FORGET_PASSWORD.EMAIL_VALID_ERROR' | translate }}
            </span>
            <span class="form-text error" *ngIf="error?.email">
              {{ 'FORGET_PASSWORD.EMAIL_NOT_REGISTERED' | translate }}
            </span>
          </div>
          <button [disabled]="submitted" [ngClass]="{
            disabledBtnState: !form.form.valid,
            activeBtnState: form.form.valid
          }" class="signin" [class.btn-pulse]="submitted">{{ buttonText }}</button>
        </form>
        <span class="back-btn" (click)="onClick()">{{ 'FORGET_PASSWORD.GO_BACK' | translate }}</span>
      </div>
    </div>
  </div>
</div>