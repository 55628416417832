import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileExclusiveService {

  private _isMobileExclusive = new BehaviorSubject<Boolean>(false);
  isMobileExclusive = this._isMobileExclusive.asObservable();

  constructor() { }

  changeMobileExclusive(data: Boolean) {
    this._isMobileExclusive.next(data);
  }

}
