import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-netverse-video',
  templateUrl: './netverse-video.component.html',
  styleUrls: ['./netverse-video.component.scss']
})
export class NetverseVideoComponent implements OnInit {

  @Input() video;
  @Input() Video_type;
  @Input() video_genres;
  // seriesRelated: any;
  showVideo: Boolean = false;
  seasonName = '';
  constructor(

    private apiService: ApiService,

  ) {

   }

  ngOnInit() {
    if (this.video.season_name) {
      this.seasonName = 'S' + this.video.season_name.substring(this.video.season_name.indexOf(' ') + 1);
    }
    // this.getVideoSeriesRelated();
  }
  over(){
    console.log(this.video)
    // console.log(this.seriesRelated)
    if(this.video.is_web_series === 1 && (this.video.video_trailer !== null && this.video.video_trailer !== undefined)){
      setTimeout(() => {
        this.showVideo = true;
      }, 500);
    }else{
    this.showVideo = false;

    }
    

  }
  out(){
    this.showVideo = false;
  }
  // getVideoSeriesRelated(){
  //   // this.isVideoLoaded = false;
  //   const url = 'v3/series-related/';
  //   // this.params += `/${this.route.snapshot.params.slug}`;
  //   // console.log("params")
  //   // console.log(this.videos)
  //   let param = this.video.video_info.video_category_slug;
  //   // let param = "black-mirror";
  //   this.apiService.callGetAPI(url + param).subscribe(
  //     (data) => {
  //       // this.relatedLoading = false;
  //       if (data['statusCode'] === 200 && data['error'] === false) {
  //         // this.loader = false;
  //         // this.relatedLoading = false;
  //         // this.videoSeriesRelated = data['response'];
       
  //         this.seriesRelated = data['response']
  //         console.log("seriesRelated")
  //         console.log(this.seriesRelated)
  //         // this.isVideoLoaded = true;
  //         // this.getRelatedVidoes();
  //       }
  //     },
  //     (error) => {
  //       if (error.status === 404) {
  //         // this.router.navigate(['video-not-found']);
  //         console.log("video not found")
  //       } else {
  //         this.apiService.failureResponse(error);
  //       }
  //     }
  //   );
  // }

}
