<div class="notification-tittle">
	<h4>{{ 'NOTIFICATION.NOTIFICATION' | translate }}</h4><span class="clear-all" (click)="notificationRemoveAll()"
		*ngIf="notifications.length > 0">{{ 'NOTIFICATION.CLEAR_ALL' | translate }}</span>
</div>
<div class="notification-container" (scroll)="onScroll($event)">
	<ul *ngIf="notifications.length > 0">
		<ng-container *ngFor="let notification of notifications let i=index">
			<li class="notify-list"
				[ngClass]="{'unread_msg': (notification.read_at === null),'' : (notification.read_at !== null)}"
				(mouseleave)="mouseout(i)">
				<div class="notifiy-user" (click)="readSingleNotification(i)">
					<span *ngIf="notification?.customer?.profile_picture === '' || notification?.customer === null "
						class="user__img" style="background-image: url(../assets/images/fav_logo_new.png)"></span>
					<span *ngIf="notification?.customer?.profile_picture !== '' " class="user__img"
						[ngStyle]="{'background-image': 'url('+ notification?.customer?.profile_picture +')'}"></span>
				</div>
				<div class="notify-content" (click)="readSingleNotification(i)">
					<span class="notify-msg">{{notification.title}}</span>
					<span class="notify-msg">{{notification.content}}</span>
					<span class="notify-time">{{notification.date}}</span>
				</div>
				<div class="notify-thumb" (click)="readSingleNotification(i)"
					*ngIf="notification.type !== 'subscription' && notification.type !== 'revoke_request' && notification?.video?.thumbnail_image !== '' && notification.type !== 'custom-notify'">
					<span class="notify-thumb-img"
						[ngStyle]="{'background-image':'url('+notification?.video?.thumbnail_image+')'}"></span>
				</div>
				<div class="notify-more" (mouseover)="mouseover(i)">
					<a href="javascript:void(0)" class="notify-more-ic">
						<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 408 408"
							style="enable-background:new 0 0 408 408;" xml:space="preserve">
							<g>
								<g id="more-vert">
									<path
										d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51    s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51    S232.05,306,204,306z"
										fill="#9c9c9c" />
								</g>
							</g>
						</svg>
					</a>
					<div class="notify-more-dropdown">
						<ul *ngIf="removeSingleNotify[i]" (mouseover)="mouseover(i)">
							<li>
								<a href="javascript:void(0)"
									(click)="removeSingleNotification(i)">{{ 'NOTIFICATION.HIDE_NOTIFICATION' | translate }}</a>
							</li>
						</ul>
					</div>
				</div>
			</li>
		</ng-container>
		<div id="notification_bottom"></div>
	</ul>
	<div *ngIf="notifications.length === 0 && !loading" class="no-notification">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="220" viewBox="0 0 102.781 100.672">
			<defs>
			  <clipPath id="clip-path">
				<path id="Path_28099" data-name="Path 28099" d="M142.442,114.3v8.767c0,20.919-8.867,20.727-8.867,30.207,0,4.609,3.89,3.966,8.644,3.966h37.028c4.754,0,8.644.788,8.644-3.966,0-9.547-8.93-9.288-8.93-30.208V114.3a18.261,18.261,0,1,0-36.522,0" transform="translate(-133.575 -96.039)"/>
			  </clipPath>
			  <clipPath id="clip-path-2">
				<rect id="Rectangle_6587" data-name="Rectangle 6587" width="43.66" height="7.796" fill="#1d8ebc"/>
			  </clipPath>
			  <clipPath id="clip-path-4">
				<rect id="Rectangle_6590" data-name="Rectangle 6590" width="62.891" height="5.457" fill="#1d8ebc"/>
			  </clipPath>
			  <clipPath id="clip-path-5">
				<rect id="Rectangle_6592" data-name="Rectangle 6592" width="7.465" height="7.464"/>
			  </clipPath>
			  <clipPath id="clip-path-6">
				<rect id="Rectangle_6593" data-name="Rectangle 6593" width="5.972" height="5.972"/>
			  </clipPath>
			  <clipPath id="clip-path-7">
				<rect id="Rectangle_6594" data-name="Rectangle 6594" width="7.464" height="7.464"/>
			  </clipPath>
			  <clipPath id="clip-path-8">
				<rect id="Rectangle_6595" data-name="Rectangle 6595" width="7.731" height="2.209" transform="translate(0 0)"/>
			  </clipPath>
			  <clipPath id="clip-path-9">
				<rect id="Rectangle_6596" data-name="Rectangle 6596" width="17.672" height="2.209" transform="translate(0 0)"/>
			  </clipPath>
			  <clipPath id="clip-path-10">
				<rect id="Rectangle_6597" data-name="Rectangle 6597" width="24.299" height="2.209"/>
			  </clipPath>
			  <clipPath id="clip-path-11">
				<rect id="Rectangle_6598" data-name="Rectangle 6598" width="5.523" height="2.209" transform="translate(0 0)"/>
			  </clipPath>
			  <clipPath id="clip-path-12">
				<rect id="Rectangle_6599" data-name="Rectangle 6599" width="19.881" height="2.209" transform="translate(0 0)"/>
			  </clipPath>
			  <clipPath id="clip-path-13">
				<rect id="Rectangle_6600" data-name="Rectangle 6600" width="4.418" height="5.523"/>
			  </clipPath>
			</defs>
			<g id="Group_21131" data-name="Group 21131" transform="translate(-3029.849 7249)">
			  <path id="Path_28104" data-name="Path 28104" d="M214.622,78.085a7.213,7.213,0,1,1,7.213-7.213,7.213,7.213,0,0,1-7.213,7.213m0-10.525a3.313,3.313,0,1,0,3.313,3.313,3.313,3.313,0,0,0-3.313-3.313" transform="translate(2862.325 -7312.66)" fill="#1d8ebc"/>
			  <path id="Path_28103" data-name="Path 28103" d="M178.276,122.615v-8.767a18.261,18.261,0,0,0-36.522,0v8.767c0,20.92-8.868,20.727-8.868,30.207,0,4.61,3.89,3.968,8.644,3.968h37.031c4.754,0,8.644.787,8.644-3.968,0-9.546-8.93-9.286-8.93-30.207" transform="translate(2916.987 -7335.691)" fill="#1d8ebc" opacity="0.38"/>
			  <g id="Group_20018" data-name="Group 20018" transform="translate(3049.789 -7240.253)">
				<g id="Group_20014" data-name="Group 20014" transform="translate(0 0)" opacity="0.5" clip-path="url(#clip-path)">
				  <g id="Group_20013" data-name="Group 20013" transform="translate(5.328 33.395)">
					<g id="Group_20012" data-name="Group 20012" clip-path="url(#clip-path-2)">
					  <path id="Path_28152" data-name="Path 28152" d="M0,0H43.66V7.8H0Z" fill="#1d8ebc"/>
					</g>
					<rect id="Rectangle_6588" data-name="Rectangle 6588" width="43.66" height="7.796" fill="none"/>
				  </g>
				</g>
				<g id="Group_20017" data-name="Group 20017" transform="translate(0 0)" opacity="0.5" clip-path="url(#clip-path)">
				  <g id="Group_20016" data-name="Group 20016" transform="translate(-4.417 56.184)">
					<g id="Group_20015" data-name="Group 20015" clip-path="url(#clip-path-4)">
					  <path id="Path_28153" data-name="Path 28153" d="M0,0H62.891V5.457H0Z" fill="#1d8ebc"/>
					</g>
					<rect id="Rectangle_6591" data-name="Rectangle 6591" width="62.891" height="5.457" fill="none"/>
				  </g>
				</g>
				<path id="Path_28101" data-name="Path 28101" d="M142.442,114.3v8.767c0,20.919-8.867,20.727-8.867,30.207,0,4.609,3.89,3.966,8.644,3.966h37.028c4.754,0,8.644.788,8.644-3.966,0-9.547-8.93-9.288-8.93-30.208V114.3a18.261,18.261,0,1,0-36.522,0" transform="translate(-133.575 -96.039)" fill="none" stroke="#1d8ebc" stroke-miterlimit="10" stroke-width="2"/>
			  </g>
			  <path id="Path_28102" data-name="Path 28102" d="M222.387,305.617a6.887,6.887,0,1,1-6.887-6.887,6.887,6.887,0,0,1,6.887,6.887" transform="translate(2861.446 -7484.229)" fill="#1d8ebc"/>
			  <path id="Path_28106" data-name="Path 28106" d="M178.955,123.067V114.3a18.261,18.261,0,1,0-36.522,0v8.767c0,20.92-8.867,20.727-8.867,30.207,0,4.61,3.89,3.967,8.644,3.967h37.031c4.754,0,8.644.787,8.644-3.967,0-9.546-8.93-9.286-8.93-30.207" transform="translate(2916.221 -7336.292)" fill="none" opacity="0.38"/>
			  <path id="Path_28107" data-name="Path 28107" d="M403.475,115.387a1.668,1.668,0,1,1-1.179.488A1.606,1.606,0,0,1,403.475,115.387Z" transform="translate(2720.441 -7350.414)" fill="#1d8ebc"/>
			  <path id="Path_28108" data-name="Path 28108" d="M337.609,173.02a1.67,1.67,0,1,1-1.18.488,1.609,1.609,0,0,1,1.18-.488Z" transform="translate(2768.516 -7392.478)" fill="#1d8ebc"/>
			  <g id="Group_20021" data-name="Group 20021" transform="translate(3105.758 -7233.534)" opacity="0.22">
				<g id="Group_20020" data-name="Group 20020" transform="translate(0)">
				  <g id="Group_20019" data-name="Group 20019" clip-path="url(#clip-path-5)">
					<path id="Path_28109" data-name="Path 28109" d="M348.971,126.383a2.035,2.035,0,1,1-1.437.6A1.959,1.959,0,0,1,348.971,126.383Z" transform="translate(-345.269 -124.906)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20024" data-name="Group 20024" transform="translate(3126.659 -7227.562)" opacity="0.22">
				<g id="Group_20023" data-name="Group 20023" transform="translate(0 0)">
				  <g id="Group_20022" data-name="Group 20022" clip-path="url(#clip-path-6)">
					<path id="Path_28110" data-name="Path 28110" d="M423.072,146.968a2.035,2.035,0,1,1-1.437.6,1.962,1.962,0,0,1,1.437-.594Z" transform="translate(-420.254 -145.902)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20027" data-name="Group 20027" transform="translate(3113.223 -7224.576)" opacity="0.22">
				<g id="Group_20026" data-name="Group 20026" transform="translate(0 0)">
				  <g id="Group_20025" data-name="Group 20025" clip-path="url(#clip-path-7)">
					<path id="Path_28111" data-name="Path 28111" d="M384.021,170.153Z" transform="translate(-379.8 -165.809)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <path id="Path_28112" data-name="Path 28112" d="M92.462,193.207a1.668,1.668,0,1,1-1.179.488A1.605,1.605,0,0,1,92.462,193.207Z" transform="translate(2947.439 -7407.212)" fill="#1d8ebc"/>
			  <path id="Path_28113" data-name="Path 28113" d="M134.224,139.981a1.67,1.67,0,1,1-1.179.488A1.61,1.61,0,0,1,134.224,139.981Z" transform="translate(2916.958 -7368.364)" fill="#1d8ebc"/>
			  <g id="Group_20030" data-name="Group 20030" transform="translate(3056.826 -7161.582)" opacity="0.22">
				<g id="Group_20029" data-name="Group 20029" transform="translate(0 0)">
				  <g id="Group_20028" data-name="Group 20028" transform="translate(0 0)" clip-path="url(#clip-path-8)">
					<line id="Line_428" data-name="Line 428" x2="6.162" transform="translate(0.678 0.74)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20033" data-name="Group 20033" transform="translate(3067.871 -7161.582)" opacity="0.22">
				<g id="Group_20032" data-name="Group 20032" transform="translate(0 0)">
				  <g id="Group_20031" data-name="Group 20031" transform="translate(0 0)" clip-path="url(#clip-path-9)">
					<line id="Line_429" data-name="Line 429" x2="16.915" transform="translate(0.385 0.74)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20036" data-name="Group 20036" transform="translate(3087.752 -7161.582)" opacity="0.22">
				<g id="Group_20035" data-name="Group 20035" transform="translate(0 0)">
				  <g id="Group_20034" data-name="Group 20034" clip-path="url(#clip-path-10)">
					<path id="Path_28114" data-name="Path 28114" d="M275.717,390.009H299.58" transform="translate(-275.282 -389.269)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20039" data-name="Group 20039" transform="translate(3118.678 -7161.582)" opacity="0.22">
				<g id="Group_20038" data-name="Group 20038" transform="translate(0 0)">
				  <g id="Group_20037" data-name="Group 20037" transform="translate(0 0)" clip-path="url(#clip-path-11)">
					<line id="Line_430" data-name="Line 430" x2="4.982" transform="translate(0.191 0.74)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <g id="Group_20042" data-name="Group 20042" transform="translate(3073.394 -7150.537)" opacity="0.22">
				<g id="Group_20041" data-name="Group 20041" transform="translate(0 0)">
				  <g id="Group_20040" data-name="Group 20040" transform="translate(0 0)" clip-path="url(#clip-path-12)">
					<line id="Line_431" data-name="Line 431" x2="18.763" transform="translate(0.5 0.826)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			  <path id="Path_28115" data-name="Path 28115" d="M310.742,425.447a1.235,1.235,0,1,1-.873.361A1.189,1.189,0,0,1,310.742,425.447Z" transform="translate(2787.807 -7576.715)" fill="#1d8ebc"/>
			  <path id="Path_28116" data-name="Path 28116" d="M143.227,410.22a1.234,1.234,0,1,1-1.234,1.234A1.234,1.234,0,0,1,143.227,410.22Z" transform="translate(2910.07 -7565.602)" fill="#1d8ebc"/>
			  <path id="Path_28117" data-name="Path 28117" d="M60.994,422.4a1.232,1.232,0,1,1-.873.361A1.19,1.19,0,0,1,60.994,422.4Z" transform="translate(2970.089 -7574.491)" fill="#1d8ebc"/>
			  <g id="Group_20045" data-name="Group 20045" transform="translate(3040.258 -7162.686)" opacity="0.22">
				<g id="Group_20044" data-name="Group 20044">
				  <g id="Group_20043" data-name="Group 20043" clip-path="url(#clip-path-13)">
					<path id="Path_28118" data-name="Path 28118" d="M102.9,387.9a1.5,1.5,0,1,1-1.064.44A1.447,1.447,0,0,1,102.9,387.9Z" transform="translate(-100.559 -386.624)" fill="none" stroke="#707070" stroke-width="1"/>
				  </g>
				</g>
			  </g>
			</g>
		  </svg>
		<p class="no-notify-text">
			{{ 'NOTIFICATION.NO_NOTIFICATION_MESSAGE' | translate }}
		</p>
	</div>
	<div *ngIf="notifications.length === 0 && loading">
		<app-loadmore *ngIf="loading"></app-loadmore>
	</div>
</div>