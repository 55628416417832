import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DailymotionApiService {
    constructor() {
        this.hasAlreadyStartedLoadingApi = false;
        this.isApiReady = false;
        this.currentVideo = new BehaviorSubject('');
        this.thisVideo = this.currentVideo.asObservable();
        this.videoPaymentDataVar = new BehaviorSubject('');
        this.videoPaymentInfo = this.videoPaymentDataVar.asObservable();
        this.setWatchFromBeginningFlag = new BehaviorSubject(false);
        this.fetchWatchFromBeginningFlag = this.setWatchFromBeginningFlag.asObservable();
        this.dailymotionApiReady = new BehaviorSubject(false);
    }
    loadApi() {
        // If not during server-side rendering
        if (typeof window !== 'undefined') {
            if (!!this.isApiReady) {
                this.dailymotionApiReady.next(true);
            }
            else if (!this.hasAlreadyStartedLoadingApi) {
                // Ensure the script is inserted only once
                this.hasAlreadyStartedLoadingApi = true;
                // Create scripttag
                this.scriptTag = window.document.createElement('script');
                this.scriptTag.src = 'https://api.dmcdn.net/all.js';
                // Setup callback
                this.scriptTag.addEventListener('load', () => {
                    this.isApiReady = true;
                    this.dailymotionApiReady.next(true);
                });
                this.scriptTag.addEventListener('error', () => {
                    throw new Error(`${this.scriptTag.src} failed to load`);
                });
                // Insert in DOM
                const firstScriptTag = window.document.getElementsByTagName('script')[0];
                if (!firstScriptTag) {
                    document.head.appendChild(this.scriptTag);
                }
                else if (firstScriptTag.parentNode) {
                    firstScriptTag.parentNode.insertBefore(this.scriptTag, firstScriptTag);
                }
                else {
                    throw new Error('First script tag has no parent node');
                }
            }
        }
    }
    videoData(data) {
        this.currentVideo.next(data);
    }
    videoPaymentData(data) {
        this.videoPaymentDataVar.next(data);
    }
    setWatchFromBeginning(data) {
        this.setWatchFromBeginningFlag.next(data);
    }
    reset() {
        this.currentVideo.unsubscribe();
        this.currentVideo = new BehaviorSubject('');
        this.thisVideo = this.currentVideo.asObservable();
    }
}
DailymotionApiService.ngInjectableDef = i0.defineInjectable({ factory: function DailymotionApiService_Factory() { return new DailymotionApiService(); }, token: DailymotionApiService, providedIn: "root" });
