import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';

declare var window: any;

@Component({
  selector: 'app-banner-ad',
  templateUrl: './banner-ad.component.html',
  styleUrls: ['./banner-ad.component.scss']
})
export class BannerAdComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() adUnitPath: string;
  @Input() id: string | number = Math.random();
  @Input() slotSize: any;

  private slot: any = null;
  constructor() {
  }

  ngOnInit() {
    // console.log('BANNER_AD_COMP_INIT::', this.id, this.slotSize, this.adUnitPath);
    window.googletag = window.googletag || {cmd: []};
    window.googletag.cmd.push(() => {
      this.slot = window.googletag
                    .defineSlot(this.adUnitPath, this.slotSize, this.id)
                    .setCollapseEmptyDiv(true) // TODO: Enable below line, if we dont want to show empty slots for ads
                    .addService(window.googletag.pubads());
      window.googletag.enableServices();
    });
  }

  ngAfterViewInit() {
    window.googletag.cmd.push(() => {
      this.slot = window.googletag.display(this.id);
    });
  }

  ngOnDestroy() {
    console.log('DESCTROY SLOTS', this.slot);
    // TODO: Destroy individual slots instead destroying all
    window.googletag.destroySlots();
  }

  getStyle() {
    let style = {};
    if (Array.isArray(this.slotSize)) {
      style = {
        width: `${this.slotSize[0]}px`,
        height: `${this.slotSize[1]}px`
      };
    }
    return style;
  }

}
