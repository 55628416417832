<section class="category-view">
    <div class="container">
        <div class="small-vplay skeleton-loader" *ngIf="loader">
            <div class="tittle-blk">
                <h2 class="shimmer"></h2>
            </div>
            <div class="genre-videos">
                <div class="cs-slider">
                    <app-contus-slider #contusSlider [id]="1" url="loadingSlider" (previousPages)="previous($event)"
                        (nextPageCall)="next($event)" [total]="0" [length]="0" class="small-slider"
                        [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
                        <app-loader-card class="item" *ngFor="let video of loadingSlider.data let i=index">
                        </app-loader-card>
                    </app-contus-slider>
                    <div class="hidden-block"></div>
                </div>
            </div>
        </div>

        <div class="small-vplay skeleton-loader" *ngIf="loader">
            <div class="tittle-blk">
                <h2 class="shimmer"></h2>
            </div>
            <div class="genre-videos">
                <div class="cs-slider">
                    <app-contus-slider #contusSlider [id]="1" url="loadingSlider" (previousPages)="previous($event)"
                        (nextPageCall)="next($event)" [total]="0" [length]="0" class="small-slider"
                        [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
                        <app-loader-card class="item" *ngFor="let video of loadingSlider.data let i=index">
                        </app-loader-card>
                    </app-contus-slider>
                    <div class="hidden-block"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!loader">
            <!-- web series parent-->
            <ng-container>
                <div class="small-vplay" *ngIf="webseriesList?.data?.length > 0" [@enterMainAnimation]>
                    <div class="tittle-blk">
                        <h2>{{ webseriesList?.category_name}} </h2>
                    </div>
                    <div class="genre-videos" *ngIf="webseriesList?.data?.length > 0">
                        <div class="cs-slider main-webseries-section">
                            <app-contus-slider #contusSlider [id]="'webseries'" url="webseriesList"
                                (nextPageCall)="getMoreWebseries()" [total]="webseriesList.total"
                                [length]="webseriesList.data.length" class="small-slider webseries-slider"
                                [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
                                <app-trailer-card class="item" [category]="'main-webseries'" [video]="video" [video_type]="'2'" 
                                    [class.mouse-hovered]="mouseOvered"
                                    (mouseover)="handleLastItemOnHover($event, i, 'main-webseries-section')"
                                    (mouseout)="handleLastItemOnHover($event, i, 'main-webseries-section')"
                                    [type]="'webseries'" *ngFor="let video of webseriesList.data; let i=index;"
                                    [class.row-first-item]="i == sliderMovedData - sliderRowItem"
                                    [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i"
                                    [attr.data-moved]="sliderMovedData">
                                </app-trailer-card>

                            </app-contus-slider>
                            <div class="hidden-block"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- End of webseries parent -->

            <!-- Genre based web series start-->
            <ng-container *ngFor="let category of genre_series_section; let i=index">
                <div class="small-vplay" *ngIf="category.series_list.data.length > 0" [@enterMainAnimation]>
                    <div class="tittle-blk">
                        <h2>{{ category?.name}}</h2>
                    </div>
                    <div class="genre-videos" *ngIf="category.series_list.data.length > 0">
                        <div class="cs-slider {{ category?.name}}">
                            <app-contus-slider #contusSlider [id]="category.slug" url="genre_series"
                                (nextPageCall)="getMoreSubwebseries(i, 'genre',this.fullParams, category.slug)"
                                [total]="category?.series_list?.total" [length]="category?.series_list?.data?.length"
                                class="small-slider webseries-slider"
                                [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
                                <app-trailer-card  [video_type]="'2'"  class="item" [video]="video"
                                    [webseries_category_text]="category?.name" [category]="'genre'" [type]="'webseries'"
                                    [class.mouse-hovered]="mouseOvered"
                                    (mouseover)="handleLastItemOnHover($event, i, category?.name)"
                                    (mouseout)="handleLastItemOnHover($event, i, category?.name)" [attr.data-index]="i"
                                    *ngFor="let video of category.series_list.data;  let i = index"
                                    [class.row-first-item]="i == sliderMovedData - sliderRowItem"
                                    [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i"
                                    [attr.data-moved]="sliderMovedData">
                                </app-trailer-card>
                            </app-contus-slider>
                            <div class="hidden-block"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let category of genre_series; let i=index">
                <div class="small-vplay" *ngIf="category.series_list.data.length > 0" [@enterMainAnimation]>
                    <div class="tittle-blk">
                        <h2>{{ category?.name}}</h2>
                    </div>
                    <div class="genre-videos" *ngIf="category.series_list.data.length > 0">
                        <div class="cs-slider {{ category?.name}}">
                            <app-contus-slider #contusSlider [id]="category.slug" url="genre_series"
                                (nextPageCall)="getMoreSubwebseries(i, 'genre',this.fullParams, category.slug)"
                                [total]="category?.series_list?.total" [length]="category?.series_list?.data?.length"
                                class="small-slider webseries-slider"
                                [options]="{nav : true, loop:false, margin: 10, dots: false, stagePadding: 20, responsive: { '0': { items: 2, slideBy: 2, nav : false, margion : 10}, '600': { items: 3, slideBy: 3, nav : false, margion : 10}, '1000': { items: 5, slideBy: 6, margion : 10}, '1024': { items: 5, slideBy: 6, margion: 11 }, '1200': { items: 5, slideBy: 5, margion: 11 }, '1500': { items: 6, slideBy: 6, margion : 10} } }">
                                <app-trailer-card class="item" [webseries_category_text]="category?.name"
                                    [video]="video" [category]="'genre'" [video_type]="'2'"  [type]="'webseries'"
                                    [class.mouse-hovered]="mouseOvered"
                                    (mouseover)="handleLastItemOnHover($event, i, category?.name)"
                                    (mouseout)="handleLastItemOnHover($event, i, category?.name)" [attr.data-index]="i"
                                    *ngFor="let video of category.series_list.data; let i=index;"
                                    [class.row-first-item]="i == sliderMovedData - sliderRowItem"
                                    [class.row-last-item]="i == sliderMovedData - 1" [attr.data-index]="i"
                                    [attr.data-moved]="sliderMovedData">
                                </app-trailer-card>
                            </app-contus-slider>
                            <div class="hidden-block"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <app-no-videos *ngIf="notFound && !loader" [data]="noVideo"></app-no-videos>
</section>