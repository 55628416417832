import { Component, OnInit } from '@angular/core';
import { Router, Scroll, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location, ViewportScroller } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { HeaderService } from 'src/app/core/services/header.service';
import { filter } from 'rxjs/operators';

declare var $: any;
declare var ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'VPLAY';
  routes: any;
  headerFlag: Boolean;
  footerFlag: Boolean;
  isConnected: any;
  status: any = true;
  splashScreenVisible: Boolean = true;
  
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private headerService: HeaderService,
    private loc: Location,
    private viewportScroller: ViewportScroller
  ) {
    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'en');
    }
    this.router.events.subscribe((res) => {});
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        console.log("true")
        this.status = true;
      } else {
        console.log("false")
        this.status = false;
      }
    });
    
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
          this.routes = this.router.url;
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/') ||
            this.routes.startsWith('/play/') ||
            this.routes.startsWith('/watch-trailer/')||
            this.routes.startsWith('/privacypolicy')
            ) {
            this.headerFlag = false;
          } else {
            this.headerFlag = true;
          }
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/') ||
            this.routes.startsWith('/play/') ||
            this.routes.startsWith('/watch-trailer/')||
            this.routes.startsWith('/privacypolicy')
            ) {
            this.footerFlag = false;
          } else {
            this.footerFlag = true;
          }
        }
      });

    this.router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          }
        });
      });
  }
  ngOnInit() {
    setTimeout(() => {
      this.splashScreenVisible = false;
    },6500);
  }
}
