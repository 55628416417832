/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jw-watch-video-series.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./jw-watch-video-series.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
var styles_JwWatchVideoSeriesComponent = [i0.styles];
var RenderType_JwWatchVideoSeriesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JwWatchVideoSeriesComponent, data: {} });
export { RenderType_JwWatchVideoSeriesComponent as RenderType_JwWatchVideoSeriesComponent };
export function View_JwWatchVideoSeriesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "embed-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "height: 150%!important; margin-top: -10%!important;"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "video-cs-blk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "movie-title title-grd"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.jwId, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_JwWatchVideoSeriesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jw-watch-video-series", [], null, null, null, View_JwWatchVideoSeriesComponent_0, RenderType_JwWatchVideoSeriesComponent)), i1.ɵdid(1, 4374528, null, 0, i2.JwWatchVideoSeriesComponent, [i3.ActivatedRoute, i3.Router, i1.Renderer2, i4.Title, i5.DOCUMENT], null, null)], null, null); }
var JwWatchVideoSeriesComponentNgFactory = i1.ɵccf("app-jw-watch-video-series", i2.JwWatchVideoSeriesComponent, View_JwWatchVideoSeriesComponent_Host_0, { video: "video", customWidth: "customWidth", customHeight: "customHeight", jwId: "jwId" }, {}, []);
export { JwWatchVideoSeriesComponentNgFactory as JwWatchVideoSeriesComponentNgFactory };
