<!-- <div class="slider-item" [ngClass]="{
  notrailertest: !video.trailer_hls_url,
  'slider-item-selector': !slidItemClass,
  zindex: addZindex,
  cardOverlay: currentlyPlaying
}" (mouseleave)="resetTrailer(video)" (mouseover)="playTrailer(video, $event)" [class.mouse-hovered]="mouseOvered"
(mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false"> -->
<div class="slider-item" [ngClass]="{
  notrailertest: !video.trailer_hls_url,
  'slider-item-selector': !slidItemClass,
  zindex: addZindex,
  cardOverlay: currentlyPlaying
}" (mouseenter)='over()' (mouseleave)='out()' [class.mouse-hovered]="mouseOvered"
(mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false">
<div class="items-wrapper">
  <div  *ngIf="this.video_type=='2'" class="slider-img" [attr.vidoe-slug]="video.slug" lazy-load-images
    (click)="!video.trailer_hls_url ? videoClick(video, undefined) : false"
    [ngStyle]="{ 'background-image': 'url(' + video.thumbnail_image + ')' }">
 <!--   <img *ngIf="!showVideo" alt="video.title" src="assets/images/blank_new.png" [attr.data-src]="video.thumbnail_image"
      onerror="this.onerror=null;this.src='assets/images/blank_new.png';" />
 -->
    
    <span class="premium-tag" *ngIf="video?.is_premium === 1">
      {{ 'VIDEO_CARD.PREMIUM' | translate }}</span>
    <span *ngIf="type === 'favorite'" class="like-box" (click)="favourite(video, $event)"><i
        [ngClass]="'liked'"></i></span>
  </div>

  <div *ngIf="this.video_type=='1'" class="slider-img" [attr.vidoe-slug]="video.slug" lazy-load-images
    (click)="!video.trailer_hls_url ? videoClick(video, undefined) : false"
    [ngStyle]="{ 'background-image': 'url(' + video.video_program_thumbnail + ')' }">
    <!--
    <img *ngIf="!showVideo" alt="video.title" src="assets/images/blank_new.png" [attr.data-src]="video.video_program_thumbnail"
      onerror="this.onerror=null;this.src='assets/images/blank_new.png';" />
    -->
    
    <span class="premium-tag" *ngIf="video?.is_premium === 1">
      {{ 'VIDEO_CARD.PREMIUM' | translate }}</span>
    <span *ngIf="type === 'favorite'" class="like-box" (click)="favourite(video, $event)"><i
        [ngClass]="'liked'"></i></span>
  </div>
  <!-- <div class="slider-content">
    <ng-container *ngIf="type !== 'web-series'; else webSeries">
    <div class="overlaps">
      <h3 (click)="videoClick(video, undefined)">
        {{ video?.title }}
      </h3>
      <h4>{{ video?.video_genre_name }}</h4>
    </div>
      <span class="category-text" *ngIf="type === 'search'" (click)="videoClick(video, undefined)">
        {{ video?.genre_name }}<i *ngIf="video?.genre_name">,</i>{{ video?.video_category_name }}
      </span>
    </ng-container>
    <ng-template #webSeries>
      <p (click)="videoClick(video, undefined)">
        <ng-container class="category-text" (click)="videoClick(video, undefined)"
          *ngIf="!live && type !== 'search' && category !== 'category'">
          <ng-container *ngIf="video?.video_category_name">
            {{ video?.video_category_name }}
          </ng-container>
        </ng-container>

        <ng-container class="category-text" *ngIf="type === 'search'" (click)="videoClick(video, undefined)">
          {{ video?.category_name }}
        </ng-container>
      </p>
      <span class="category-text" (click)="videoClick(video, undefined)">
        <ng-container *ngIf="category !== 'genre'">{{ video?.genre_name
          }}<i *ngIf="video?.genre_name">, </i> </ng-container>{{ video?.title }}
      </span>
    </ng-template>
    <span class="time" *ngIf="type === 'upcoming'" (click)="videoClick(video, undefined)">
      {{ video?.scheduledStartTime | date: 'MMM dd yyyy' }}
      {{ video?.scheduledStartTime | date: 'hh:mm'
      }}{{ video?.scheduledStartTime | date: 'a' | lowercase }}
    </span>
    <span class="live-icon" *ngIf="type === 'live'">
      <svg version="1.1" id="Layer_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px"
        height="30px" viewBox="0 0 30 30" enable-background="new 0 0 30 30" xml:space="preserve">
        <path fill="#5ea326" d="M26.387,23.657l-1.261-1.263c4.122-4.123,4.121-10.83-0.002-14.953l1.263-1.261
          C31.205,10.998,31.205,18.84,26.387,23.657z M22.942,20.212l1.261,1.266c3.614-3.617,3.614-9.5,0-13.115l-1.261,1.261
          C25.86,12.542,25.86,17.295,22.942,20.212z M21.952,19.224c2.374-2.373,2.374-6.238-0.001-8.611l-1.261,1.262
          c1.68,1.68,1.68,4.411,0,6.086L21.952,19.224z M5.353,7.441L4.09,6.18c-4.818,4.817-4.818,12.66,0,17.477l1.261-1.263
          C1.229,18.273,1.23,11.565,5.353,7.441z M6.271,21.478l1.262-1.266c-2.918-2.917-2.918-7.667,0-10.588L6.271,8.361
          C2.656,11.976,2.656,17.86,6.271,21.478z M8.522,19.224l1.263-1.263c-1.678-1.679-1.678-4.409,0.001-6.086l-1.263-1.262
          C6.149,12.989,6.149,16.852,8.522,19.224z" />
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#5ea326" d="M15.366,12.178c1.646,0,2.98,1.335,2.98,2.981
          s-1.334,2.98-2.98,2.98s-2.981-1.334-2.981-2.98S13.72,12.178,15.366,12.178z" />
      </svg>
    </span>
  </div> -->
  <div class="video-card-trailer">
    <div *ngIf="this.video_type=='2'" class="video-block" (click)="videoClick(video, undefined)"
    [ngStyle]="{ 'background-image': video.video_trailer === null ? 'url('+video.poster_image+')' : 'linear_gradient(white, white)' }" 
    >
      <img *ngIf="video.video_trailer === null" alt="video.title" src="{{ video.poster_image }}" 
          [attr.data-src]="video.poster_image" onerror="this.onerror=null;this.src='assets/images/blank_new.png';" />
      <div *ngIf="showVideo && video.video_trailer !== null " style="position: absolute; width: 100%;">
        <app-jw-watch-video-series jwId="{{video.slug}}" customWidth="100%" customHeight="30%" video="{{video.video_trailer}}"></app-jw-watch-video-series>
      </div>  
      <!-- <img *ngIf="!video.trailer_hls_url" alt="video.title" src="{{ video.poster_image }}"
        [attr.data-src]="video.thumbnail_image" onerror="this.onerror=null;this.src='assets/images/blank_new.png';" /> -->
      <!--<video [muted]="true" id="trailer-video-{{ this.blockType | strremovewhitespace }}-{{
          video.slug | strremovewhitespace
        }}" *ngIf="video.trailer_hls_url"></video> -->
    </div>
    <div   *ngIf="this.video_type=='1'" class="video-block" (click)="videoClick(video, undefined)"
    [ngStyle]="{ 'background-image': video.video_trailer === null ? 'url('+video.video_program_poster+')' : 'linear_gradient(white, white)' }" > 
      <img *ngIf="video.video_trailer === null" alt="video.title" src="{{ video.video_program_poster }}"
        [attr.data-src]="video.video_program_thumbnail" onerror="this.onerror=null;this.src='assets/images/blank_new.png';" />
      <div *ngIf="showVideo && video.video_trailer !== null " style="position: absolute; width: 100%;">
        <app-jw-watch-video-series jwId="{{video.slug}}" customWidth="100%" customHeight="30%" video="{{video.video_trailer}}"></app-jw-watch-video-series>
      </div> 
      <!-- <img *ngIf="!video.trailer_hls_url" alt="video.title" src="{{ video.poster_image }}"
        [attr.data-src]="video.thumbnail_image" onerror="this.onerror=null;this.src='assets/images/blank_new.png';" /> -->
      <!-- <video [muted]="true" id="trailer-video-{{ this.blockType | strremovewhitespace }}-{{
          video.slug | strremovewhitespace
        }}" *ngIf="video.trailer_hls_url"></video> -->
    </div>
    <div class="trailer-content">
      <ng-container *ngIf="type !== 'webseries'; else webSeries">

        <div class="category-block">
          <p class="category-text" (click)="videoClick(video, undefined)"
            *ngIf="type !== 'search' && type !== 'upcoming'">
            <ng-container *ngIf="
                category !== 'genre' && type !== 'upcoming' && type !== 'live'
              ">
              {{ video?.genre_name
              }}<i *ngIf="
                  video?.genre_name &&
                  category !== 'genre' &&
                  category !== 'category' &&
                  video?.video_category_name
                ">,</i>
            </ng-container>
            <ng-container *ngIf="video?.video_category_name && category !== 'category'">
              {{ video?.video_category_name }}
            </ng-container>
          </p>
          <p class="category-text" *ngIf="type === 'search'" (click)="videoClick(video, undefined)">
            {{ video?.genre_name }}<i *ngIf="video?.genre_name">,</i>{{ video?.video_category_name }}
          </p>
          <div class="user-options">

          </div>
        </div>

        <div class="titleBlock">
          <h3 (click)="videoClick(video, undefined)">
            {{ video?.title }}
          </h3>

        </div>
        <div class="trailer-description" (click)="videoClick(video, undefined)">
          <p>
            {{ video?.less_description }}
          </p>
        </div>
        <div class="trailer-details">
          <a *ngIf="video?.published_on" (click)="videoClick(video, undefined)">
            {{
              video?.published_on | date: format
            }}

          </a>
          <a (click)="videoClick(video, undefined)">
            {{
              video?.view_count === null
                ? 0
                : (video?.view_count | numberConversion)
            }}
            {{ 'VIDEO_DETAIL.VIEWS' | translate }}
          </a>
          <a *ngIf="video?.video_duration && video?.video_duration != '00:00:00'"
            (click)="videoClick(video, undefined)">
            {{video?.video_duration | timetextformat}}
          </a>
        </div>
      </ng-container>
      <ng-template #webSeries>
        <p class="category-text" *ngIf="category === 'main-webseries'" (click)="videoClick(video, undefined)">
          {{ video?.genre?.name }}
        </p>
        <p class="category-text" *ngIf="category === 'genre'" (click)="videoClick(video, undefined)">
          {{ webseries_category_text }}
        </p>
        <div class="titleBlock">
          <h3 *ngIf="this.video_type == '2'" (click)="videoClick(video, undefined)">
            {{ video?.title }} 
          </h3>
          <h3 *ngIf="this.video_type == '1'" (click)="videoClick(video, undefined)">
            {{ video?.video_program_name }} 
          </h3>
        </div>
        <div class="trailer-description" (click)="videoClick(video, undefined)">
          <p *ngIf="this.video_type == '2'">
            {{ video?.less_description }}
          </p>
          <p *ngIf="this.video_type == '1'">
            {{ video?.less_description }} 
          </p>
        </div>
        <div class="trailer-details">
          <a (click)="videoClick(video, undefined)" *ngIf="video?.webseries_season != 0 && this.video_type == '2'">
            {{ video?.webseries_season }}
            <ng-container *ngIf="video?.webseries_season === 1">{{ 'VIDEO_DETAIL.SEASON' | translate }}</ng-container>
            <ng-container *ngIf="video?.webseries_season > 1">{{ 'VIDEO_DETAIL.SEASONS' | translate }}</ng-container>
          </a>
          <a *ngIf="video?.created_at" (click)="videoClick(video, undefined)">
            {{
              video?.created_at | date: format
            }}
          </a>
          <a *ngIf="video?.program_detail?.created_at" (click)="videoClick(video, undefined)">
            {{
              video?.program_detail?.created_at | date: format
            }}
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</div>

</div>