import { Component, OnInit, OnDestroy } from '@angular/core';
import disableScroll from 'disable-scroll';
import {
  AuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { AuthService as auth } from '../../core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  user: SocialUser;
  login = false;
  authVariable: any;
  socialAuthService: any;
  constructor(
    private authService: AuthService,
    private authLogin: auth,
    private toasterService: ToastrService,
    private router: Router,
    private userService: UserService
  ) { }
  ngOnDestroy(): void {
    if (this.socialAuthService) {
      this.socialAuthService.unsubscribe();
    }
  }

  ngOnInit() { }

  signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((response) => {
        this.socailLogin();
      })
      .catch((error) => { });
  }

  signInWithFB(): void {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((response) => {
        this.socailLogin();
      })
      .catch((error) => { });
  }

  signOut(): void {
    this.authService.signOut();
  }

  socailLogin(): void {
    console.log('Came social login part');
    this.login = false;
    this.socialAuthService = this.authService.authState.subscribe((user) => {
      this.user = JSON.parse(JSON.stringify(user));
      if (this.user) {
        if (this.user.id !== undefined && !this.login) {
          this.authVariable = this.authLogin.login(this.user).subscribe(
            (data) => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                this.login = true;
                this.userService.displayPopup('close');
                if (
                  data['response'].name &&
                  !localStorage.getItem('currentUser')
                ) {
                  const userData = this.authLogin.encryption(data);
                  this.asyncLocalStorage
                    .setItem('currentUser', userData)
                    .then(() => {
                      if (window.location.pathname.indexOf('video') !== -1) {
                        window.location.reload();
                      } else {
                        this.userService.nextPage.subscribe((nextUrl) => {
                          this.router.navigate([nextUrl]);
                        });
                      }
                    });
                  disableScroll.off();
                }
                this.authService.signOut();
              }
            },
            (error) => {
              if (this.user.email) {
                this.socialAuthService.unsubscribe();
                if (error.error.message === 'Account you are trying to login is inactive, please check your email and activate the account' || error.error.message === 'Akun Kamu belum diaktifkan, silahkan periksa email Kamu dan aktivasi Akun Kamu terlebih dahulu.') {
                  this.onClick('resentlink');
                  return;
                }
                this.toasterService.error(
                  '',
                  error.error.message ? error.error.message : error.message
                );
              } else {
                this.toasterService.error(
                  '',
                  'Update your email id in social network'
                );
              }
            }
          );
        }
      }
    });
  }
  // tslint:disable-next-line:member-ordering
  asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  onClick(value: string) {
    if (value === 'forget') {
      this.userService.displayPopup('forget');
    } else if (value === 'resent') {
      this.userService.displayPopup('resent');
    } else if (value === 'resentlink') {
      this.userService.displayPopup('resentlink');
    } else {
      this.userService.displayPopup('sign-up');
    }

  }
}
