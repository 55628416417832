/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-videos.component";
var styles_NoVideosComponent = [i0.styles];
var RenderType_NoVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoVideosComponent, data: {} });
export { RenderType_NoVideosComponent as RenderType_NoVideosComponent };
export function View_NoVideosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "no-video-blk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "no-videos"], ["class", "video-not-found"], ["src", "assets/images/no-videos.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }); }
export function View_NoVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-videos", [], null, null, null, View_NoVideosComponent_0, RenderType_NoVideosComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoVideosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoVideosComponentNgFactory = i1.ɵccf("app-no-videos", i2.NoVideosComponent, View_NoVideosComponent_Host_0, { data: "data" }, {}, []);
export { NoVideosComponentNgFactory as NoVideosComponentNgFactory };
