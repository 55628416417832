import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class MobileExclusiveService {
    constructor() {
        this._isMobileExclusive = new BehaviorSubject(false);
        this.isMobileExclusive = this._isMobileExclusive.asObservable();
    }
    changeMobileExclusive(data) {
        this._isMobileExclusive.next(data);
    }
}
MobileExclusiveService.ngInjectableDef = i0.defineInjectable({ factory: function MobileExclusiveService_Factory() { return new MobileExclusiveService(); }, token: MobileExclusiveService, providedIn: "root" });
