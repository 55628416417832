

<div  class="embed-container">
<div id="jw-player-id"></div>
<div class="video-cs-blk">
  <div class="back_btn" (click)="gotoBack()">
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve" width="33px"
      height="33px">
      <g>
        <g>
          <path
            d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "
            fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  </div>
  <div class="movie-title title-grd">
    <h2>{{ video?.title }}</h2>
    <!-- <h4 *ngIf="video?.is_live == 1 && livestreamStarted && !geoStatus" class="viewscounter">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
      </svg>
      {{ viewersCount }} 
    </h4> -->
    <h3 *ngIf="video && video.video_category_name">{{ video?.video_category_name }}</h3>
  </div>
  <!-- <div (mouseenter)="mouseOverXray = true" (mouseleave)="mouseOverXray = false" class="xray-contents xray-content-lists"
    *ngIf="showXray && xrayCastList.length > 0">
    <div class="xray-heading">
      <h2 (click)="xrayListClicked()">
        X-Ray
        <span class="xray-arrow">
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 451.847 451.847">
            <g>
              <path
                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                  c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                  c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
            </g>
          </svg>
        </span>
      </h2>
    </div>
    <div class="xray-lists" *ngIf="showXrayList">
      <ul>
        <li *ngFor="let xray of xrayCastList">
          <div class="xray-image" *ngIf="xray.banner_image">
            <img src={{xray.banner_image}} alt="" [attr.src]="xray.banner_image"
              onerror="this.src='assets/images/xray_placeholder.png';" />
          </div>
          <div class="xray-image" *ngIf="!xray.banner_image">
            <img alt="ThumbnailImage" src="assets/images/xray_placeholder.png" />
          </div>
          <div class="xray-content" (click)="showXrayCastDetailMethod(xray)">
            <h3 class="xray-title" *ngIf="xray.name">{{ xray.name }}</h3>
            <p class="xray-para">{{ xray.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div> -->

  <!-- Next Button for Web series -->
  <!-- <button (mouseenter)="mouseOverEpisode = true" (mouseleave)="mouseOverEpisode = false" class="next-video"
    (click)="nextVideo()" *ngIf="next_video">
    <span *ngIf="showEpisode && next_video">Next Episode
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 492.004 492.004" style="enable-background: new 0 0 492.004 492.004"
        xml:space="preserve">
        <g>
          <g>
            <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" />
          </g>
        </g>
      </svg>
    </span>
  </button> -->
</div>
</div>

