import { Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { VideoModel } from '../../../../modules/video-detail/video.model';
import { Router, RoutesRecognized } from '@angular/router';
import { UserService } from '../../../../core/services/user.service';
import { PlayerService } from '../../../../core/services/player.service';
import { VideoShareService } from '../../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../../core/services/playlist-add.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import disableScroll from 'disable-scroll';
import { trigger, transition, style, animate } from '@angular/animations';
import videojs from '../../../../../assets/js/play.es.js';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../../environments/environment';
import { StringRemoveWhitespace } from 'src/app/shared/stringremovewhitespace';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { VideoDurationValidation } from '../../../utils/videodurationvalidation';
declare var $: any;
@Component({
  selector: 'app-trailer-card',
  templateUrl: './trailer-card.component.html',
  styleUrls: ['./trailer-card.component.scss'],
  animations: [
    trigger('videoCardAnimation', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ],
  providers: [StringRemoveWhitespace]
})
export class TrailerCardComponent implements OnInit {
  @Input() video: any = {};
  @Input() history: any;
  @Input() live: boolean;
  @Input() type: string;
  @Input() video_type: string;
  @Input() category: string;
  @Input() genres_slug: string;
  @Input() playlist_id: any;
  @Input() playlist_name: any;
  @Input() currentlyPlaying: any;
  @Output() favouriteChange = new EventEmitter();
  @Output() historyChange = new EventEmitter();
  @Output() playlistChange = new EventEmitter();
  @Output() dragEvent = new EventEmitter<string>();
  @Output() videoCardClicked = new EventEmitter;
  @Input() blockType: any;
  @Input() webseries_category_text: any;
  loggedIn: any = {};
  tostar: any = {};
  loggedTrue: Boolean = false;
  showOption: any = false;
  showFlag: Boolean = false;
  shareDrop: any;
  videoDetails: any;
  playlist: any = [];
  loading: boolean;
  shareFlag: boolean;
  flag: boolean;
  final: boolean;
  submitted: boolean;
  result: boolean;
  errors: any;
  success: any;
  utilityService: any;
  buttonText: string;
  playlistForm: any;
  playlistFlag: boolean;
  addZindex = false;
  slidItemClass = false;
  player: any;
  trailerHoverTimer;
  videoPlayHoverState = true;
  showVideo: Boolean = false;
  timeouts = [];
  cipherText: any;
  encrypted: any;
  decrypted: any;
  interVal: any;
  plainText: any;
  pre_url: any;
  playerInterval = [];
  mouseOvered = false;
  @HostListener('document:click', ['$event'])
  clickOut() {
    if (this.shareDrop !== undefined) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
    private playerService: PlayerService,
    private eRef: ElementRef,
    private playlistAddService: PlaylistAddService,
    private meta: Meta,
    private pipe: StringRemoveWhitespace
  ) {
    $(function () {
      $('.slider-img img').on('error', function () {
        $(this).attr({
          'src': '/assets/images/blank_new.png'
        });
      });
    });
  }
  ngOnInit() {
    this.slidItemClass = this.type === 'live';
    this.videoDetails = this.video;
    this.playlistForm = new FormGroup({
      playlist_name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\s]*$')])
    });
    this.video.thumbnail_image = (this.video.thumbnail_image !== null && this.video.thumbnail_image.length > 0) ? this.video.thumbnail_image : 'assets/images/blank_new.png';
    this.video.less_description =
      (this.video.description && this.video.description.length > 120)
        ? this.video.description.substr(0, 120) + '...'
        : this.video.description;

    this.video.less_description =
      (this.video.description && this.video.description.length > 120)
        ? this.video.description.substr(0, 120) + '...'
        : this.video.description;
    this.video.video_duration = (VideoDurationValidation(this.video.video_duration)) ? this.video.video_duration : 0;
    this.live = (this.live) ? true : false;
    if (localStorage.getItem('currentUser')) {
      this.loggedTrue = true;
    }
  }

  showOptions(event) {
    if (this.showOption === false) {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: false, disableKeys: false });
    }
    this.addZindex = true;
    this.shareDrop = event.target;
    this.showOption = !this.showOption;
  }

  openShareVideo() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.videoShareService.displayPopup(this.video);
  }
  openPlalistPopup() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.playlistAddService.displayPopup(this.video);
  }
  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = video.is_favourite;
      if (!favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      }

    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup('sign-in');
    }
  }

  removeHistory(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callPostAPI('v2/clear_recent_view', { video_id: video.slug })
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.apiService.successResponse(data, '');
          }
        },
        error => {
          this.apiService.failureResponse(error, false);
        });
    this.historyChange.emit(this.video);
  }
  playerConfiguration(videoElem) {
    this.player = videojs(videoElem, {
      autoplay: true,
      controls: false,
      preload: true,
      bigPlayButton: false,
      muted: true
    });
  }
  playTrailer(video, e) {
    if (this.videoPlayHoverState) {
      const blockType = this.pipe.transform(this.blockType);
      const videoslug = this.pipe.transform(video.slug);
      const videoSelector = 'trailer-video-' + blockType + '-' + videoslug;
      const getVideo = document.getElementById(videoSelector);
      if (getVideo != null) {
        if (video.trailer_hls_url !== null && video.trailer_hls_url !== '' && video.trailer_status === 'Complete') {
          this.playerConfiguration(getVideo);
          this.trailerHoverTimer = setTimeout(function () {
            this.player.src({
              src: video.trailer_hls_url,
              type: 'application/x-mpegURL'
            });
            getVideo.parentElement.classList.remove('trailer-ended');
            this.player.load();
            this.player.on('play', function (e) {
              this.videoPlayHoverState = false;
            }.bind(this));
            this.player.on('ended', function (e) {
              this.videoPlayHoverState = true;
              this.player.pause();
              getVideo.parentElement.classList.add('trailer-ended');
            }.bind(this));
          }.bind(this), 1000, getVideo, video);
          this.timeouts.push(this.trailerHoverTimer);
        }

      }
    }
  }
  resetTrailer(video) {
    const blockType = this.pipe.transform(this.blockType);
    const videoslug = this.pipe.transform(video.slug);
    const videoSelector = 'trailer-video-' + blockType + '-' + videoslug;
    const getVideo = document.getElementById(videoSelector);
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    for (let i = 0; i < this.playerInterval.length; i++) {

      clearInterval(this.playerInterval[i]);
    }
    this.playerInterval = [];
    this.plainText = null;
    this.timeouts = [];
    if (video.trailer_hls_url !== null && video.trailer_hls_url !== '' && video.trailer_status === 'Complete') {
      this.playerConfiguration(getVideo);
      this.videoPlayHoverState = true;
      this.player.reset();
    }
  }
  videoClick(video, imageClick) {

    if (video) {
      this.router.events
        .filter(e => e instanceof RoutesRecognized)
        .pairwise()
        .subscribe((event: any[]) => {
          this.pre_url = event[0].urlAfterRedirects;
          localStorage.setItem('pre_url', this.pre_url);
        });
    }

    if (this.type === 'webseries') {
      if (this.video_type === '1') {
        localStorage.setItem('is_explore', 'true');
        localStorage.setItem('is_explore_slug', this.genres_slug);
        this.router.navigate(['video/' + video.slug]);
      } else {
        localStorage.setItem('is_explore', 'true');
        if (video.is_single_video === 1) {
          localStorage.setItem('is_explore', 'true');
          this.router.navigate(['video/' + video.slug]);
        } else {
          this.router.navigate(['webseries/' + video.slug]);
        }
      }
    } else {
      if (imageClick === 'image') {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
        this.playerService.videoData(video);
        if (video.hasOwnProperty('is_live') && video.is_live === 0) {
          if (video.is_premium === '1') {
            if (video.is_subscribed === 1) {
              if (video.slug) {
                if (this.type === 'playlist') {
                  this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                } else {
                  this.router.navigate(['watch/' + video.slug]);
                }
              }

            } else {
              if (video.slug) {
                if (this.type === 'playlist') {
                  this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                } else {
                  this.router.navigate(['video/' + video.slug]);
                }
              }
            }
          } else {
            if (video.slug) {
              if (this.type === 'playlist') {
                this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              } else {
                this.router.navigate(['watch/' + video.slug]);
              }
            }
          }
        } else {
          if (video.is_premium === 1) {
            if (this.type === 'playlist') {
              this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
            } else {
              this.router.navigate(['video/' + video.slug]);
            }
          } else {
            this.router.navigate(['watch/' + video.slug]);
          }


        }
      } else {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
        this.playerService.videoData(video);
        if (video.slug) {
          if (this.type === 'playlist') {
            this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
          } else {
            this.router.navigate(['video/' + video.slug]);
          }
        }
      }
    }
  }


  removeFromPlaylist(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callDeleteAPI('v2/create_playlist_videos?playlist_id=' + this.playlist_id + '&video_id=' + this.video.slug)
      .subscribe(data => {
        this.apiService.successResponse(data);
      }, error => {
        this.apiService.failureResponse(error);
      });
    this.playlistChange.emit(video);
  }

  videoCardFullClick(video) {
    this.videoCardClicked.emit(video);
  }
  /**
   * Method to open playlist popup
   */
  openPlaylistPopup(videoData) {
    this.userService.redirectUrl(this.router.url);
    this.playlistAddService.displayPopup(videoData);
  }
  over(){
    console.log(this.video.thumbnail_image)
    // console.log(this.seriesRelated)
    if((this.video.video_trailer !== null && this.video.video_trailer !== undefined)){
      setTimeout(() => {
        this.showVideo = true;
      }, 500);
    }else{
    this.showVideo = false;

    }
    

  }
  out(){
    this.showVideo = false;
  }

}


