<ul class="social-login-buttons">
    <li>
      <a class="facebook" (click)="signInWithFB()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60.734px" height="60.733px" viewBox="0 0 60.734 60.733" style="enable-background:new 0 0 60.734 60.733;" xml:space="preserve">
        <g>
        <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
          v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
          v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"></path></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
        </svg>

        <span>Facebook</span>
      </a>
    </li>
    <li>
      <a class="google-plus" (click)="signInWithGoogle()">
        <svg id="Untitled-Page%201" viewBox="0 0 320 321" style="background-color:#ffffff00" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
          x="0px" y="0px" width="320px" height="321px"
        >
        <g id="Layer">
          <path id="Forma%201" d="M 160 321 C 71.776 321 0 249.224 0 161 C 0 72.776 71.776 1 160 1 C 198.432 1 235.424 14.824 264.224 39.912 L 222.176 88.168 C 205.024 73.224 182.944 65 160 65 C 107.072 65 64 108.072 64 161 C 64 213.928 107.072 257 160 257 C 201.728 257 237.312 230.248 250.528 193 L 160 193 L 160 129 L 320 129 L 320 161 C 320 249.224 248.224 321 160 321 Z" fill="#ffffff"/>
        </g>
        </svg>

        <span>Google</span>
      </a>
    </li>
  </ul>
