import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute) { }

  ngOnInit() {

  }
  onClose() {
    if (this.route.snapshot['_routerState'].url === '/forget-password') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

}
