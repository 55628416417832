import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { HeaderService } from '../../../core/services/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adsdetail',
  templateUrl: './adsdetail.component.html',
  styleUrls: ['./adsdetail.component.scss']
})
export class AdsdetailComponent implements OnInit {

  getHomeCategory: any;
  promotionalAds: any = [];
  homePages = new Map();

  constructor(
    private apiService: ApiService,
    private headerService: HeaderService,
    private router: Router
  ) { }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.getHomeCategory = this.headerService.getHomeCategory();
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
    } else {
      this.getHome();
    }
  }

  getHome() {
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {
        this.getHomeAfterData(data);
      },
      (error) => {
      }
    );
  }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      if (data['response'].promotional_ads) {
        this.promotionalAds = data['response'].promotional_ads;
        this.promotionalAds.data = this.promotionalAds.data.slice(0, 10);
        this.homePages.set('promotional_ads', 1);
      }
    }
  }

  next(url) {
    switch (url) {
      case 'promotional_ads':
        this.getDynamicSectionData(url);
        break;
    }
  }

  getDynamicSectionData(url) {
    this.homePages.set(url, this.homePages.get(url) + 1);
      this.apiService
        .callGetAPI('v2/home_more?type=' + url + '&page=' + this.homePages.get(url))
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                if (url === 'promotional_ads') {
                  this.promotionalAds.data.push(value);
                }
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
  }

  gotoVideoDetail(slug) {
    this.router.navigate(['video/' + slug]);
  }

}
