import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResendModalComponent } from './resend-modal.component';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
  declarations: [ResendModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    ResendModalComponent
  ]
})
export class ResendModalModule { }
