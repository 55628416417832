<div class="full-net" (mouseenter)='over()' (mouseleave)='out()'>
<div class="continue-img slider-img">
    <img *ngIf="video.poster_image && !showVideo" src="assets/images/blank_new.png" alt="" [attr.src]="video.poster_image"
    onerror="this.src='assets/images/blank_new.png';" />
    <img *ngIf="!video.poster_image && !showVideo" src="assets/images/blank_new.png" alt="" onerror="this.src='assets/images/blank_new.png';" />
   
    <!-- <img *ngIf="video.poster_image && !showVideo" src="assets/images/blank_new.png" alt="" [attr.src]="video.poster_image"
    onerror="this.src='assets/images/blank_new.png';" />
    <img *ngIf="!video.poster_image && !showVideo" src="assets/images/blank_new.png" alt="" onerror="this.src='assets/images/blank_new.png';" />
    -->
   
    <div *ngIf="showVideo && (video.is_web_series === 1)" style="position: absolute; width: 100%;">
        <!-- <div  style="position: absolute; width: 100%;"> -->
        <app-jw-watch-video-series jwId="{{video.slug}}" customWidth="100%" customHeight="30%" video="{{video.video_trailer}}"></app-jw-watch-video-series>
    </div>
    <!-- <app-jw-watch-video-series video="{{seriesRelated.related.video_trailer}}"></app-jw-watch-video-series> -->
    
    <span class="premium-tag" *ngIf="video?.is_premium === 1">{{ 'VIDEO_CARD.PREMIUM' | translate }}</span>
</div>
<div class="fornewbg">
    <div *ngIf="seasonName">
        <h3  class="continue-title">{{ video.video_category_name }}</h3>
        <h6  class="continue-title" style="min-height: 2.8rem;"> {{ (seasonName) ? (seasonName) : "" }}{{ (video.episode_order) ? ":E" + video.episode_order + " - " : "" }} {{ video.title }}</h6>
    </div>
    <div *ngIf="!seasonName">
        <h3  class="continue-title">{{ video.video_category_name }}</h3>
     </div>
   
</div>
</div>