import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent } from './alert-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AlertModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],

  exports: [
    AlertModalComponent
  ]
})
export class AlertModalModule { }
