import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() banner;
  bannerData = [];
  isMobile: Boolean = false;

  constructor(
    private router: Router
  ) { 
    this.getWindowSize();
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.bannerData = this.banner;
    console.log(this.bannerData);
  }
  // Banner click
  onBannerClick(video) {
    console.log(video);
    if (video.is_external_link == 0) {
      this.router.navigate(['video/' + video.video_slug]);
    } else {
      window.open(video.external_url);
    }
  }

  getWindowSize() {
    if (window.innerWidth >= 1199) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
}
