<footer id="footer" *ngIf="footerFlag">
  <div class="s-container f-footer">
    <div class="footer-text">
      <h2 class="tittle">
        {{ 'FOOTER.CONNECT_WITH_US' | translate }}
      </h2>
      <ul>
        <li>
          <div class="social-icons">
            <a *ngIf="site_link.fb_link !== ''" href="{{ site_link.fb_link }}" target="_blank" class="fb">facebook</a>
            <a *ngIf="site_link.twitter_link !== ''" href="{{ site_link.twitter_link }}" target="_blank" class="tw">Tweeter</a>
            <a *ngIf="site_link.instagram_link !== ''" href="{{ site_link.instagram_link }}" target="_blank"
              class="inst">Instagram</a>
          </div>
        </li>
      </ul>      
    </div>
    <div class="footer-text">
      <h2 class="tittle">{{ 'FOOTER.GENERAL' | translate }}</h2>
      <ul>
        <!-- <li>
          <a routerLink="/subscription"> {{ 'FOOTER.PRICING' | translate }}</a>
        </li> -->
        <li *ngFor="let cms of cmsLink">
          <a *ngIf="cms.slug != 'offers'" routerLink="content/{{ cms.slug }}"> {{ cms.title }}</a>
          <a *ngIf="cms.slug == 'offers'" routerLink="{{ cms.slug }}"> {{ cms.title }}</a>
        </li>
      </ul>
    </div>
    <div class="footer-text categories-ul">
      <h2 class="tittle">{{ 'FOOTER.CATEGORIES' | translate }}</h2>
      <ul>
        <ng-container *ngIf="categories !== null">
          <!-- <li *ngFor="let category of categories; let i = index">
            <a style="cursor: pointer" *ngIf="i < 3" (click)="categoryNavigation(category)">{{ category.title }}</a>
          </li> -->
          <li>
            <a (click)="categoryNavigation()">All Shows</a>
          </li>
        </ng-container>
        <li *ngIf="categories?.length > 3">
          <a class="hide-menu" [routerLink]="['/category/']" [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active">
            {{ 'FOOTER.MORE' | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-text contact-ul">
      <h2 class="tittle">{{ 'FOOTER.CONTACT_INFO' | translate }}</h2>
      <ul>
        <li class="mobile">
          <a *ngIf="contact?.phone !== ''" [href]="'tel:' + contact?.phone" [ngStyle]="{ cursor: 'pointer' }">
            <i class="mobile-icon"></i> {{ contact?.phone }}
          </a>
        </li>
        <li class="email">
          <a *ngIf="contact?.email !== ''" [href]="'mailto:' + contact?.email" [ngStyle]="{ cursor: 'pointer' }">
            <i class="email-icon"></i> {{ contact?.email }}
          </a>
        </li>
        <li class="support">
          <a routerLink="/contact-us"><i class="support-icon"></i> {{ 'FOOTER.CONTACT_US' | translate }}</a>
        </li>
      </ul>
    </div>
    <div class="app-links">
      <h2 class="tittle">{{ 'FOOTER.APP_STORE' | translate }}</h2>
      <ul>
        <li>
          <div class="social-icons">
            <button class="app-store" (click)="google_play_clicked()"></button>
            <a *ngIf="site_link.ios_app_link !== ''; else noIosLink" href="{{ site_link.ios_app_link }}" target="_blank"
              class="google-play">
            </a>
            <ng-template #noIosLink>
              <!-- <a href="itms-services://?action=download-manifest&url=https://s3-us-west-1.amazonaws.com/contus-mobile/Vplayed/Vplayed.plist" -->
                <a href="#"
                target="_blank" class="google-play"></a>
            </ng-template>
              <!-- <a href="itms-services://?action=download-manifest&url=https://s3-us-west-1.amazonaws.com/contus-mobile/Vplayed/Vplayed.plist" -->
            <br/>
            <p class="sub-title hide-el">Also Available on :</p>
            <a href="https://satu.xl.co.id/"
            target="_blank" class="xl-home hide-el"></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="copyrights">
    <p>
      {{ 'FOOTER.COPYRIGHT' | translate: { year: year } }}
    </p>
  </div>
</footer>