import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { VideoShareService } from '../../core/services/video-share.service';
declare var $: any;
@Component({
  selector: 'app-contus-slider',
  templateUrl: './contus-slider.component.html',
  styleUrls: ['./contus-slider.component.scss']
})
export class ContusSliderComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input() options: any;
  @Input() id: any;
  @Output() nextPageCall = new EventEmitter<string>();
  @Output() previousPages = new EventEmitter<string>();
  @Input() total: any;
  @Input() length: any;
  @Input() url: any;
  @Input() isContinueWatching: any;
  @Input() currentMovedItems: any;
  itemWidth: any;
  sliderOuterWidth: any;
  singleItemWidth: any;
  lastItemWidth: any;
  nextMove: any;
  activeItem: any;
  ItemNumber;
  moved;
  objectLength;
  objectTotal;
  previousBtn: Boolean = false;
  nextBtn: Boolean = true;
  item;
  clickStatus: Boolean = true;
  screanXAxis = 0;
  dragStatus: Boolean = true;
  isLastItemInTheRow = false;
  constructor(private videoShareService: VideoShareService) {}
  ngOnInit() {
    const outerWidth = $('.cs-slider').width();
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1500) {
      this.sliderOuterWidth = '1500';
    } else if (windowWidth >= 1000) {
      this.sliderOuterWidth = '1000';
    } else if (windowWidth >= 600) {
      this.sliderOuterWidth = '600';
    } else {
      this.sliderOuterWidth = '0';
    }
    this.item = this.options['responsive'][this.sliderOuterWidth].items;
    const margion = this.options['responsive'][this.sliderOuterWidth].margion;
    this.lastItemWidth = outerWidth / this.item / 6;
    this.singleItemWidth =
      (outerWidth - (this.lastItemWidth + this.item * margion)) / this.item;
    this.objectLength = this.length;
    this.objectTotal = this.total;
    if (this.item >= this.objectTotal) {
      this.previousBtn = false;
      this.nextBtn = false;
    }
    this.ItemNumber = this.item;
    this.moved = this.item;
    this.videoShareService.setSliderMovedItems(this.moved);
    this.videoShareService.setSliderRowItems(this.item);
  }

  next() {
    if (this.clickStatus) {
      this.clickStatus = false;
      this.transform();
      this.nextPageCall.next(this.url);
      this.videoShareService.setSliderMovedItems(this.moved);
    }
  }

  previous() {
    if (this.clickStatus) {
      this.clickStatus = false;
      this.moved -= this.ItemNumber;
      this.nextMove -= this.ItemNumber;
      this.previousTransform();
      this.videoShareService.setSliderMovedItems(this.moved);
    }
  }
  ngAfterViewChecked() {
    const halfWidth = this.singleItemWidth / 200;
    const width = this.singleItemWidth + halfWidth;

    $('.continue-watching-slider-item-selector').css('width', `${width}px`);
    $('.continue-img').css('width', `${width}px`);

    // $('.for-ads').css('width', `200px`);
    // $('.for-ads .continue-img').css('width', `200px`);

    $('.slider-item-selector').css('width', this.singleItemWidth + 'px');
  }

  ngAfterViewInit() {
    document.getElementById('1').style.transform = 'translate3d(0px, 0px, 0px)';
    document.getElementById('2').style.transform = 'translate3d(0px, 0px, 0px)';
    this.previousBtn = false;
    $('.owl-prev').addClass('disabled');
  }

  transform() {
    this.objectLength = this.length;
    this.objectTotal = this.total;
    $('.owl-prev').removeClass('disabled');
    this.previousBtn = true;
    const margion = this.options['responsive'][this.sliderOuterWidth].margion;
    const trasnformElement = this.objectLength - this.moved;
    if (trasnformElement >= this.ItemNumber) {
      this.nextMove = this.moved;
      this.moved += this.ItemNumber;
      this.activeItem = this.nextMove + this.ItemNumber;
    } else {
      this.nextMove = this.moved - this.ItemNumber + trasnformElement;
      this.activeItem = this.moved + trasnformElement;
      this.moved += trasnformElement;
    }
    const sliderCardWidth = $('.slider-item').width();
    document.getElementById(this.id).style.transform =
    'translate3d(-' +
    (this.nextMove * sliderCardWidth + this.nextMove * margion) +
    'px, 0px, 0px)';
    document.getElementById(this.id).style.transition = '1s';
    if (this.activeItem === this.objectTotal) {
      this.nextBtn = false;
    }
    this.clickStatus = true;
  }
  previousTransform() {
    this.nextBtn = true;
    $('.owl-next').removeClass('disabled');
    let margion = 0;
    let previouseMove = 0;
    if (this.nextMove >= this.ItemNumber) {
      previouseMove = this.nextMove;
      margion = this.options['responsive'][this.sliderOuterWidth].margion;
    } else if (this.nextMove >= 0 && this.nextMove < this.ItemNumber) {
      previouseMove = this.nextMove;
      margion = this.options['responsive'][this.sliderOuterWidth].margion;
    } else {
      this.moved = this.ItemNumber;
      this.nextMove = 0;
    }
    const sliderCardWidth = $('.slider-item').width();
    document.getElementById(this.id).style.transform =
    'translate3d(-' +
    (this.nextMove * sliderCardWidth + this.nextMove * margion) +
    'px, 0px, 0px)';
    document.getElementById(this.id).style.transition = '1s';
    if (previouseMove === 0) {
      this.previousBtn = false;
    }
    this.clickStatus = true;
  }
  itemDrag(event) {
    if (this.dragStatus) {
      if (this.screanXAxis === 0) {
        this.screanXAxis = event.clientX;
      } else if (this.screanXAxis !== event.clientX) {
        if (this.screanXAxis > event.clientX) {
          this.next();
        } else {
          this.previous();
        }
        this.screanXAxis = 0;
        this.dragStatus = false;
      }
    }
  }
  itemDragEnd() {
    this.dragStatus = true;
  }
  @HostListener('touchmove', ['$event'])
  touch() {
    const draggable = document.getElementById(this.id);
    draggable.addEventListener('touchmove', (event) => {
      const touch = event.targetTouches[0];
      // this.itemDrag(touch);
    });
    draggable.addEventListener('touchend', (event) => {
      this.dragStatus = true;
    });
  }
}
