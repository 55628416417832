import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
@Component({
  selector: 'app-complete-profile-modal',
  templateUrl: './complete-profile-modal.component.html',
  styleUrls: ['./complete-profile-modal.component.scss']
})
export class CompleteProfileModalComponent implements OnInit {
  constructor(private userService: UserService,
    private router: Router) { }
  ngOnInit() {
  }
  onClose(value) {
    if (value === 'userIncomplete') {
      this.userService.displayPopup('close')
    }
    // disableScroll.off();
  }

  onClick() {
    console.log("masuk profile")
    this.router.navigate(['profile']);
  }
}
