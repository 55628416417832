import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
import * as i4 from "../auth/auth.service";
import * as i5 from "./user.service";
export const apiNotifier = new Subject();
export class ApiService {
    constructor(http, router, toasterService, authService, userService) {
        this.http = http;
        this.router = router;
        this.toasterService = toasterService;
        this.authService = authService;
        this.userService = userService;
        this.error = {};
        this.authService
            .getUniqueBrowserValue()
            .then((data) => {
            this.deviceId = data.deviceId;
            this.operatingSystem = data.platform;
        });
        this.browserName = this.authService.getBrowserName();
    }
    headers() {
        this.authorization = this.authService.decryption();
        this.apiHeaders = new HttpHeaders();
        this.apiHeaders.headers['Accept'] = 'application/json';
        this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
        this.apiHeaders.headers['X-DEVICE-NAME'] = this.browserName;
        this.apiHeaders.headers['X-DEVICE-OS'] = this.operatingSystem
            ? this.operatingSystem
            : '';
        this.apiHeaders.headers['X-DEVICE-ID'] = this.deviceId ? this.deviceId : '';
        if (localStorage.getItem('currentLanguage')) {
            this.apiHeaders.headers['X-LANGUAGE-CODE'] = localStorage.getItem('currentLanguage');
        }
        else {
            this.apiHeaders.headers['X-LANGUAGE-CODE'] = 'en';
        }
        if (this.authorization.hasOwnProperty('response')) {
            this.apiHeaders.headers['Authorization'] =
                'Bearer ' + this.authorization.response.access_token;
        }
        return this.apiHeaders;
    }
    callGetAPI(url, baseServiceUrl) {
        const serviceUrl = baseServiceUrl
            ? baseServiceUrl + url
            : environment.apiUrl + url;
        return this.http.get(serviceUrl, this.headers()).pipe(map((data) => {
            return data;
        }));
    }
    callExternalAPI(url, baseServiceUrl) {
        const serviceUrl = url;
        return this.http.get(serviceUrl).pipe(map((data) => {
            return data;
        }));
    }
    callPostAPI(url, parameters, baseServiceUrl) {
        const serviceUrl = baseServiceUrl
            ? baseServiceUrl + url
            : environment.apiUrl + url;
        return this.http.post(serviceUrl, parameters, this.headers()).pipe(map((data) => {
            return data;
        }));
    }
    callPutAPI(url, parameters, baseServiceUrl) {
        const serviceUrl = baseServiceUrl
            ? baseServiceUrl + url
            : environment.apiUrl + url;
        return this.http.put(serviceUrl, parameters, this.headers()).pipe(map((data) => {
            return data;
        }));
    }
    callDeleteAPI(url, baseServiceUrl) {
        const serviceUrl = baseServiceUrl
            ? baseServiceUrl + url
            : environment.apiUrl + url;
        return this.http.delete(serviceUrl, this.headers()).pipe(map((data) => {
            return data;
        }));
    }
    callGetJsonFile(url) {
        return this.http.get(url).pipe(map((data) => {
            return data;
        }));
    }
    successResponse(data, url = null) {
        if (data.statusCode === 200 && data.error === false) {
            this.toasterService.success('', data.message);
            if (url) {
                this.router.navigate([url.redirect]);
            }
        }
    }
    failureResponse(data, url = null) {
        this.error = {};
        if (data.error.statusCode === 422 || data.error.statusCode === 500) {
            if (typeof data.error.message === 'string' ||
                data.error.message instanceof String) {
                this.error.commonError = data.error.message;
            }
            else {
                this.error = data.error.message;
            }
        }
        else if (data.error.statusCode === 401) {
            this.authService.logout(false);
            this.userService.displayPopup('sign-in');
        }
        else if (data.error.statusCode === 403) {
            this.authService.logout(false);
            if (data.error.message && data.error.message !== '') {
                this.toasterService.error('', data.error.message);
            }
        }
        else if (data.error.statusCode === 404) {
            this.router.navigate(['404']);
            return false;
        }
        else {
            if (data.error.message && data.error.message !== '') {
                this.toasterService.error('', data.error.message);
            }
        }
        if (url) {
            console.log(url);
            // window.history.back();
        }
        return this.error;
    }
}
ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.ToastrService), i0.inject(i4.AuthService), i0.inject(i5.UserService)); }, token: ApiService, providedIn: "root" });
