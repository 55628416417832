/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner-ad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./banner-ad.component";
var styles_BannerAdComponent = [i0.styles];
var RenderType_BannerAdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerAdComponent, data: {} });
export { RenderType_BannerAdComponent as RenderType_BannerAdComponent };
export function View_BannerAdComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "banner-ad"]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getStyle(); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_BannerAdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner-ad", [], null, null, null, View_BannerAdComponent_0, RenderType_BannerAdComponent)), i1.ɵdid(1, 4440064, null, 0, i3.BannerAdComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerAdComponentNgFactory = i1.ɵccf("app-banner-ad", i3.BannerAdComponent, View_BannerAdComponent_Host_0, { adUnitPath: "adUnitPath", id: "id", slotSize: "slotSize" }, {}, []);
export { BannerAdComponentNgFactory as BannerAdComponentNgFactory };
