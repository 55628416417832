import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { TrailerComponent } from './modules/video-detail/trailer/trailer.component';
import { AdsdetailComponent } from './modules/home/adsdetail/adsdetail.component';
import { JwWatchVideoComponent } from './modules/jw-watch-video/jw-watch-video.component';
import { PrivacyPolicyTvComponent } from './modules/privacy-policy-tv/privacy-policy-tv.component';
const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  // {
  //   path: 'home/:utm_source',
  //   component: HomeComponent,
  // },
  {
    path: 'adsdetail',
    component: AdsdetailComponent,
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyTvComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'act/:token',
    component: HomeComponent,
  },
  {
    path: 'watch/:video_id',
    component: WatchVideoComponent,
  },
  { path: 'play/:video_id',
    component:JwWatchVideoComponent
  },
  {
    path: 'embed/:video_id',
    component: EmbedComponent,
  },
  {
    path: 'watch/:video_id/:playlist_id/:name',
    component: WatchVideoComponent,
  },
  {
    path: 'series/:slug',
    component: WebseriesDetailComponent,
  },
  {
    path: 'profile',
    loadChildren: './modules/profile/profile.module#ProfileModule',
  },
  {
    path: 'playlist',
    loadChildren: './modules/playlist/playlist.module#PlaylistModule',
  },
  {
    path: 'category',
    loadChildren: './modules/category/category.module#CategoryModule',
  },
  {
    path: 'genre',
    loadChildren: '../app/modules/genre/genre.module#GenreModule',
  },
  {
    path: 'explore',
    loadChildren: '../app/modules/explore/explore.module#ExploreModule',
  },
  {
    path: 'video/:slug',
    loadChildren:
      './modules/video-detail/video-detail.module#VideoDetailModule',
  },
  {
    path: 'webseries/:seriesslug',
    loadChildren:
      './modules/series-detail/series-detail.module#SeriesDetailModule',
  },
  {
    path: 'video/:slug/:playlist',
    loadChildren:
      './modules/video-detail/video-detail.module#VideoDetailModule',
  },
  {
    path: 'content/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule',
  },
  {
    path: 'contact-us',
    loadChildren: './modules/contact-us/contact-us.module#ContactUsModule',
  },
  {
    path: 'new-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'trending-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'search',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
  },
  {
    path: 'reset-password/:id',
    loadChildren:
      './modules/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'save-playlist',
    loadChildren:
      './modules/profile/save-playlist/save-playlist.module#SavePlaylistModule',
  },
  {
    path: 'subscription',
    loadChildren:
      './modules/subscription/subscription.module#SubscriptionModule',
  },
  {
    path: 'live',
    loadChildren: './modules/live-vidoes/live-vidoes.module#LiveVidoesModule',
  },
  {
    path: 'card-payment/:plan',
    loadChildren:
      './modules/card-payment/card-payment.module#CardPaymentModule',
  },
  {
    path: 'video-not-found',
    loadChildren:
      './modules/video-not-found/video-not-found.module#VideoNotFoundModule',
  },
  {
    path: 'checkout/:type/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule',
  },
  {
    path: 'offers',
    loadChildren:
      './modules/coupon-offers/coupon-offers.module#CouponOffersModule',
  },
  {
    path: 'watch-trailer/:video_id',
    component: TrailerComponent,
  },
  {
    path: '**',
    loadChildren:
      './modules/page-not-found/page-not-found.module#PageNotFoundModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
