/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-lazy-load-images";
import * as i3 from "./loader-card.component";
var styles_LoaderCardComponent = [i0.styles];
var RenderType_LoaderCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderCardComponent, data: {} });
export { RenderType_LoaderCardComponent as RenderType_LoaderCardComponent };
export function View_LoaderCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slider-img"], ["lazy-load-images", ""]], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.LazyLoadImagesDirective, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.PLATFORM_ID], { intersectionObserverConfig: [0, "intersectionObserverConfig"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "video.title"], ["src", "assets/images/blank_new.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "slider-content shimmer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "category-text"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoaderCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader-card", [], null, null, null, View_LoaderCardComponent_0, RenderType_LoaderCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoaderCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderCardComponentNgFactory = i1.ɵccf("app-loader-card", i3.LoaderCardComponent, View_LoaderCardComponent_Host_0, {}, {}, []);
export { LoaderCardComponentNgFactory as LoaderCardComponentNgFactory };
