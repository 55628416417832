import { Component, OnInit, Renderer2, Inject, Input,AfterViewInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Title } from '@angular/platform-browser';
declare var jwplayer: any;
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PlayerService } from 'src/app/core/services/player.service';
import { HeaderService } from 'src/app/core/services/header.service';
@Component({
  selector: 'app-jw-watch-video-series',
  templateUrl: './jw-watch-video-series.component.html',
  styleUrls: ['./jw-watch-video-series.component.scss']
})
export class JwWatchVideoSeriesComponent implements  AfterViewInit {
  @Input() video
  @Input() customWidth
  @Input() customHeight
  @Input() jwId

  params: any;
  videos: any;
  // video: any;
  live: boolean;
  currentVideos: any;
  fullParams: any;
  videoTitle: any;
  watched_duration: any;
  start_over_flag: Boolean;
  Jw_video_completed: Boolean = false;
  is_complete: Boolean = false;
  ads_url:any = '';

  constructor(
    // private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private titleService: Title,
    // private playerService: PlayerService,
    // private headerService: HeaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.renderer.addClass(this.document.body, 'player-page');
    // this.route.params.subscribe((params) => {
    //   this.fullParams = params;
    //   this.params = params['video_id'];
    //   // this.getVideos();
    // });
  }
  ngAfterViewInit() {
     this.JwPlayerConfig();
  }
  JwPlayerConfig() {
    console.log("jw video");
    console.log(this.video);
    // this.ads_url = (video.ads_url !='')?video.ads_url :"null";
    // console.log(this.ads_url );
    const playerJw = jwplayer(this.jwId).setup({
      title: 'NETVERSE',
      // playlist: 'https://cdn.jwplayer.com/v2/media/'+video.jw_video_media_id,
      width: 640,
      //  width: 1000,
       height: 360,
      //  controlbar:false,
      controls: false, //play button
      //  controlbar:false,
      // aspectratio: '10:9',
      aspectratio: '16:9',
      responsive: true,
      mute: false,
      autostart: true,
      primary: 'html5',
      repeat: true,
      // file: video.jw_video_url,
      file: this.video,
      advertising: {
        client: "",
        adscheduleid: "",
        schedule: [
          {
           "tag": null
          }
        ]
      }

      // advertising: {
      //   client: "vast",
      //   adscheduleid: "",
      //   schedule: [
      //     {
      //      "tag": null
      //     }
      //   ]
      // }
      // logo: {
      //   file: 'assets/images/logo/netverse_new.png', //watermark image path
      //   link:'https://netverse.id/',//water mark link
      //   position: 'bottom-right' //position of watermark on player
      //   }
    });
    jwplayer().resize(this.customWidth, this.customHeight);
    // this.playerService.fetchWatchJwFromBeginningFlag.subscribe((data) => {
    //   this.start_over_flag = data;

    // });

    // if (localStorage.getItem('currentUser')) {
    //   this.headerService.setViewRestriction(false);
    // }

    // if (video.recently_watched[0] && this.start_over_flag == false) {
    //   jwplayer().seek(video.recently_watched[0].seconds);
    // } else {
    //   jwplayer().seek(0);
    // }

    //Check Video is completed Played or not
    jwplayer(this.jwId).on('complete',
      ((data) => {
        this.Jw_video_completed = true;

      })
    );

    //Video Not Found or Redirect to the Video not found page
    jwplayer(this.jwId).on('error',
      ((data) => {
        // this.router.navigate(['video-not-found']);
      })
    );
  }

  gotoBack() {
    this.router.navigate(['video/' + this.fullParams['video_id']]);
  }

  ngOnDestroy() {
    this.watched_duration = jwplayer(this.jwId).getPosition();
       jwplayer().remove();
    // jwplayer("jw-player-id").stop();
    // jwplayer().stop();
    // this.storeVideoAnalytics();
    this.renderer.removeClass(this.document.body, 'player-page');

  }

  base64Encode(content: string): string {
    return window.btoa(content);
  }

  base64Decode(content: string): string {
    return window.atob(content);
  }

}
