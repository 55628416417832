import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DailymotionApiService {

    private hasAlreadyStartedLoadingApi = false;
    private isApiReady = false;
    private scriptTag!: HTMLScriptElement;

    public currentVideo = new BehaviorSubject<any>('');
    thisVideo = this.currentVideo.asObservable();

    private videoPaymentDataVar = new BehaviorSubject<any>('');
    videoPaymentInfo = this.videoPaymentDataVar.asObservable();

    private setWatchFromBeginningFlag = new BehaviorSubject<boolean>(false);
    fetchWatchFromBeginningFlag = this.setWatchFromBeginningFlag.asObservable();

    public dailymotionApiReady = new BehaviorSubject<boolean>(false);

    public loadApi() {
        // If not during server-side rendering
        if (typeof window !== 'undefined') {

            if (!!this.isApiReady) {
                this.dailymotionApiReady.next(true);
            } else if (!this.hasAlreadyStartedLoadingApi) {
                // Ensure the script is inserted only once
                this.hasAlreadyStartedLoadingApi = true;

                // Create scripttag
                this.scriptTag = window.document.createElement('script');
                this.scriptTag.src = 'https://api.dmcdn.net/all.js';

                // Setup callback
                this.scriptTag.addEventListener('load', () => {
                    this.isApiReady = true;
                    this.dailymotionApiReady.next(true);
                });
                this.scriptTag.addEventListener('error', () => {
                    throw new Error(`${this.scriptTag.src} failed to load`);
                });

                // Insert in DOM
                const firstScriptTag = window.document.getElementsByTagName('script')[0];
                if (!firstScriptTag) {
                    document.head.appendChild(this.scriptTag);
                } else if (firstScriptTag.parentNode) {
                    firstScriptTag.parentNode.insertBefore(this.scriptTag, firstScriptTag);
                } else {
                    throw new Error('First script tag has no parent node');
                }
            }
        }
    }

    videoData(data: any) {
        this.currentVideo.next(data);
    }

    videoPaymentData(data: any) {
        this.videoPaymentDataVar.next(data);
    }

    setWatchFromBeginning(data: boolean) {
        this.setWatchFromBeginningFlag.next(data);
    }

    reset() {
        this.currentVideo.unsubscribe();
        this.currentVideo = new BehaviorSubject<any>('');
        this.thisVideo = this.currentVideo.asObservable();
    }
}
