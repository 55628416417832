import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Title } from '@angular/platform-browser';
declare var jwplayer: any;
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PlayerService } from 'src/app/core/services/player.service';
import { HeaderService } from 'src/app/core/services/header.service';
@Component({
  selector: 'app-jw-watch-video',
  templateUrl: './jw-watch-video.component.html',
  styleUrls: ['./jw-watch-video.component.scss']
})
export class JwWatchVideoComponent implements OnInit {


  params: any;
  videos: any;
  video: any;
  live: boolean;
  currentVideos: any;
  fullParams: any;
  videoTitle: any;
  watched_duration: any;
  start_over_flag: Boolean;
  Jw_video_completed: Boolean = false;
  is_complete: Boolean = false;
  ads_url:any = '';
  notAuthorize: Boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private titleService: Title,
    private playerService: PlayerService,
    private headerService: HeaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer.addClass(this.document.body, 'player-page');
    this.route.params.subscribe((params) => {
      this.fullParams = params;
      this.params = params['video_id'];
      this.getVideos();
    });
  }
  ngOnInit() {
    //  this.JwPlayerConfig();
  }
  JwPlayerConfig(video) {

    
    
    // this.ads_url = (video.ads_url !='')?video.ads_url :"null";
    // console.log(this.ads_url );
    const playerJw = jwplayer('jw-player-id').setup({
      title: 'NETVERSE',
      // playlist: 'https://cdn.jwplayer.com/v2/media/'+video.jw_video_media_id,
      width: 640,
      height: 360,
      aspectratio: '21:9',
      mute: false,
      autostart: true,
      primary: 'html5',
      file: video.jw_video_url,
      

      // advertising: {
      //   client: "vast",
      //   adscheduleid: "",
      //   schedule: [
      //     {
      //      "tag": this.ads_url
      //     }
      //   ]
      // }
      // logo: {
      //   file: 'assets/images/logo/netverse_new.png', //watermark image path
      //   link:'https://netverse.id/',//water mark link
      //   position: 'bottom-right' //position of watermark on player
      //   }
    });
    jwplayer().resize('100%', '100%');
    this.playerService.fetchWatchJwFromBeginningFlag.subscribe((data) => {
      this.start_over_flag = data;

    });

    if (localStorage.getItem('currentUser')) {
      this.headerService.setViewRestriction(false);
    }

    if (video.recently_watched[0] && this.start_over_flag == false) {
      jwplayer().seek(video.recently_watched[0].seconds);
    } else {
      jwplayer().seek(0);
    }

    //Check Video is completed Played or not
    jwplayer('jw-player-id').on('complete',
      ((data) => {
        this.Jw_video_completed = true;

      })
    );

    //Video Not Found or Redirect to the Video not found page
    jwplayer('jw-player-id').on('error',
      ((data) => {
        this.router.navigate(['video-not-found']);
      })
    );


    if (!localStorage.getItem('currentUser')) {
      const guvl = (localStorage.getItem('GUVL')) ? Number(this.base64Decode(localStorage.getItem('GUVL'))) : 0;
      localStorage.setItem('GUVL', this.base64Encode(String(guvl + 1)));
    }
    this.handleGuestUserViewLimitation();


  }



  getVideos() {
    const url = 'v2/watchvideo/';
    this.apiService.callGetAPI(url + this.fullParams['video_id']).subscribe(
      (data) => {
        
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.videos = data['response'];
          this.video = data['response'].videos;
          this.JwPlayerConfig(this.video);
          if (this.videos.videos.is_live === 1) {
            // this.isYoutube = true;
          }
          this.currentVideos = this.videos;

          const videoMetaTitle = (this.videos.video_meta_data && this.videos.video_meta_data.hasOwnProperty('title') && this.videos.video_meta_data.title) ? this.videos.video_meta_data.title : this.videos.videos.title;
          this.titleService.setTitle(
            'NETVERSE | ' + videoMetaTitle
          );
        }

        const date = new Date(this.videos.videos.scheduled_time);
        const launchDate = new Date(date).getTime();
        const now = new Date(this.videos.web_server_time).getTime();
        if (
          this.videos.videos.scheduled_time !== '' && launchDate > now
        ) {
          if (this.fullParams['playlist_id'] !== undefined) {
            this.router.navigate([
              'video/' +
              this.fullParams['video_id'] +
              '/' +
              this.fullParams['playlist_id'] +
              '/' +
              this.fullParams['name']
            ]);
          } else {
            this.router.navigate(['video/' + this.fullParams['video_id']]);
          }
        }

        //IF PREMIUM CONTENT
        if (this.videos.videos.is_premium === 1) {
          if (this.videos.videos.is_subscribed === 0) {
            if (this.fullParams['playlist_id'] !== undefined) {
              this.router.navigate([
                'video/' +
                this.fullParams['video_id'] +
                '/' +
                this.fullParams['playlist_id'] +
                '/' +
                this.fullParams['name']
              ]);
            } else if (this.videos.payment_info.is_bought === 1) {
              return this.videos;
            } else {
              this.router.navigate(['video/' + this.fullParams['video_id']]);
            }
          }
        } else {
          //  this.storeVideoAnalytics();
        }
      },
      (error: any) => {
        
        console.log(error)

        if (error.error.message === 'Not authorized to view this video') {
          this.notAuthorize = true 
          window.location.href = '/video/' + this.fullParams['video_id'] ;
        } else {
          this.apiService.failureResponse(error);
        }
        
        if (
          error.status === 403 &&
          error &&
          error.error &&
          error.error.response &&
          error.error.response.data &&
          error.error.response.data.title
        ) {
          this.videoTitle = error.error.response.data.title;
          return;
        }

        if (this.fullParams['playlist_id'] !== undefined) {
          this.router.navigate([
            'video/' +
            this.fullParams['video_id'] +
            '/' +
            this.fullParams['playlist_id'] +
            '/' +
            this.fullParams['name']
          ]);
        }
        if (this.fullParams['video_id'] !== undefined) {
          this.router.navigate(['video/' + this.fullParams['video_id']]);
        }

       

        // if (error.status === 500) {
        //   window.location.href = '/video-not-found';
        // } else {
        //   this.apiService.failureResponse(error);
        // }
      }
    );
  }

  gotoBack() {
    this.router.navigate(['video/' + this.fullParams['video_id']]);
  }
  storeVideoAnalytics() {
    const isWatchHistoryActive = 1;
    let isContinueWatchingHistoryActive;
    let seconds;
    if (this.videos.videos.hasOwnProperty('recently_watched')) {
      // tslint:disable-next-line:max-line-length
      isContinueWatchingHistoryActive = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('continue_watching_is_active')) ? this.videos.videos.recently_watched[0].continue_watching_is_active : 0;
      // tslint:disable-next-line:max-line-length
      seconds = (this.videos.videos.recently_watched.length > 0 && this.videos.videos.recently_watched[0].hasOwnProperty('seconds')) ? this.videos.videos.recently_watched[0].seconds : 0;
    } else {
      isContinueWatchingHistoryActive = 1;
      seconds = 0;
    }

    if (this.Jw_video_completed == true) {
      this.is_complete = true;
      isContinueWatchingHistoryActive = 0;
      this.watched_duration = 0;
      // isWatchHistoryActive = 0;
    } else {
      this.is_complete = false;
      isContinueWatchingHistoryActive = 1;
      // isWatchHistoryActive = 1
    }
    this.apiService
      .callPostAPI(
        'v2/videoanalytics',
        { is_ended: this.is_complete, video_slug: this.videos.videos.slug, isWatchHistoryActive: isWatchHistoryActive, isContinueWatchingHistoryActive: isContinueWatchingHistoryActive, seconds: this.watched_duration },
        environment.analyticsApiUrl
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.watched_duration = jwplayer("jw-player-id").getPosition();
       jwplayer().remove();
    // jwplayer("jw-player-id").stop();
    // jwplayer().stop();
    this.storeVideoAnalytics();
    this.renderer.removeClass(this.document.body, 'player-page');

  }

  base64Encode(content: string): string {
    return window.btoa(content);
  }

  base64Decode(content: string): string {
    return window.atob(content);
  }

  handleGuestUserViewLimitation() {
    if (!localStorage.getItem('currentUser') && localStorage.getItem('GUVL')) {
      if (Number(this.base64Decode(localStorage.getItem('GUVL'))) > 1) {
        this.gotoBack();
        this.headerService.setViewRestriction(true);
      }
    }
  }

}
