import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import disableScroll from 'disable-scroll';
@Component({
  selector: 'app-resend-modal',
  templateUrl: './resend-modal.component.html',
  styleUrls: ['./resend-modal.component.scss']
})
export class ResendModalComponent implements OnInit {

  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute) { }
  ngOnInit() {
  }
  onClose() {
    disableScroll.off();
    if (this.route.snapshot['_routerState'].url === '/sign-in') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

  onClick(value: string) {
    if (value === 'forget') {
      this.userService.displayPopup('forget');
    } else if (value === 'resent') {
      this.userService.displayPopup('resent');
    } else if (value === 'resentlink') {
      this.userService.displayPopup('resentlink');
    } else {
      this.userService.displayPopup('sign-up');
    }

  }
}
